import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const HygieneIcon = (props: SvgProps) => (
  <Svg
    width={60}
    height={60}
    fill="none"
    {...props}
  >
    <Path
      d="M35.625 15c1.025 0 1.875.85 1.875 1.875s-.85 1.875-1.875 1.875a1.889 1.889 0 0 1-1.875-1.875c0-1.025.85-1.875 1.875-1.875Zm0-3.75A5.633 5.633 0 0 0 30 16.875c0 3.1 2.525 5.625 5.625 5.625s5.625-2.525 5.625-5.625-2.525-5.625-5.625-5.625ZM50 13.75c.7 0 1.25.55 1.25 1.25s-.55 1.25-1.25 1.25-1.25-.55-1.25-1.25.55-1.25 1.25-1.25ZM50 10c-2.75 0-5 2.25-5 5s2.25 5 5 5 5-2.25 5-5-2.25-5-5-5Zm-8.75-7.5a3.745 3.745 0 0 0-3.75 3.75A3.745 3.745 0 0 0 41.25 10 3.745 3.745 0 0 0 45 6.25a3.745 3.745 0 0 0-3.75-3.75ZM51.875 40a3.125 3.125 0 1 0 0-6.25H30v-2.5h16.875c1.725 0 3.125-1.4 3.125-3.125 0-1.675-1.325-3-2.95-3.1L22.175 25l3.7-6.5c.225-.425.35-.85.35-1.35 0-.65-.225-1.25-.65-1.75L22.8 12.5l-17.95 17a7.434 7.434 0 0 0-2.35 5.425V50c0 4.15 3.35 7.5 7.5 7.5h34.375a3.125 3.125 0 1 0 0-6.25H30v-2.5h19.375a3.125 3.125 0 1 0 0-6.25H30V40h21.875ZM25 52.5H10A2.507 2.507 0 0 1 7.5 50V35c0-.975.575-1.6.9-1.875l9.1-8.45V30H25v22.5Z"
      fill="#003A70"
    />
  </Svg>
);

export default HygieneIcon;
