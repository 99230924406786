import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const BarChartIcon = (props: SvgProps) => (
    <Svg
        width={24}
        height={24}
        fill="none"
        {...props}
    >
        <Path
            d="M18 20V10M12 20V4M6 20v-6"
            stroke={props.color ?? '#D6006D'}
            strokeWidth={2.5}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </Svg>
);

export default BarChartIcon;
