import * as React from 'react';
import Svg, { SvgProps, Circle, Path } from 'react-native-svg';
import Colors from '../constants/Colors';

interface Props extends SvgProps {
  toggled?: boolean;
}

const ResumeIcon = (props: Props) => (
  <Svg width={40} height={40} fill="none" {...props}>
    <Circle
      cx={20}
      cy={20}
      r={19}
      fill={props.toggled ? Colors.light.hereworksLightBlue : '#fff'}
      stroke={props.toggled ? Colors.light.hereworksLightBlue : '#6892C0'}
      strokeWidth={2}
    />
    <Path
      d="m27 20-9-6v12l9-6Z"
      fill={props.toggled ? '#fff' : '#6892C0'}
      stroke={props.toggled ? '#fff' : '#6892C0'}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default ResumeIcon;
