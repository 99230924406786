/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';

import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  CartesianAxis,
  ResponsiveContainer,
} from 'recharts';
import { View } from 'react-native';
import SelectTimeFrame from './SelectTimeFrame';
import { GetMultipleMetric } from '../requests/GetMultipleMetric';

// import { getHappyScoreGroupCounts } from "../requests/GetHappyScoreGroupCounts";

const HappyGroupsGraph = () => {
  // const { height, width } = useWindowDimensions();
  // const [index, setIndex] = useState('humidity');

  // const [avg, setAvg] = useState(30);
  const [dataFormat, setDataFormat] = useState([]);
  const [dataFormat2, setDataFormat2] = useState([]);
  const [groupData, setGroupData] = useState([]);
  const [groupData2, setGroupData2] = useState([]);
  const [groupData3, setGroupData3] = useState([]);
  const [groupData4, setGroupData4] = useState([]);

  const [selectedTimeFrame, setTimeFrame] = useState('7d');

  const interval = '24h';
  // let interval = "6h";
  const externalData = [];
  const itData = [];
  const energyData = [];
  const satisfactionData = [];

  const internalData = [];

  const formatted = [];
  const timeLabels = [];
  const happyscore = [];

  // const [option, setOption] = useState(null);
  const buttondata = [
    { value: 'Day', timeString: '1d' },
    { value: 'Week', timeString: '7d' },
    { value: 'Month', timeString: '30d' },
    { value: '3 Month', timeString: '90d' },
    { value: '6 Month', timeString: '180d' },
    { value: 'Year', timeString: '365d' },
  ];

  function handleClick(value, timeString) {
    // setOption(value);
    setTimeFrame(timeString);
    // console.log(value, timeString);
  }
  useEffect(() => {
    const getData = async () => {
      try {
        fetch('https://hqzse54ic7.execute-api.eu-west-1.amazonaws.com/firstDeploy/', {
          headers: {
            'Content-Type': 'application/json',
          },
          method: 'POST',
          mode: 'cors',
          body: JSON.stringify({
            size: 0,
            index: 'happyscore_flat_permin',
            query: {
              bool: {
                must: {
                  range: {
                    timestamp: {
                      gte: `now-${selectedTimeFrame}`,
                      lte: 'now',
                    },
                  },
                },
              },
            },
            aggs: {
              byhour: {
                date_histogram: {
                  field: 'timestamp',
                  interval,
                  min_doc_count: 0,
                },
                aggs: { happyscore: { avg: { field: 'happyscore' } } },
              },
            },
          }),
        })
          .then((response) => response.json())
          .then((data) => {
            // console.log("data: " + JSON.stringify(data));

            data.aggregations.byhour.buckets.map((e) => {
              happyscore.push(e.happyscore.value);
              let hourLabel = e.key_as_string;
              hourLabel = new Date(hourLabel).toLocaleDateString().slice(0, 5).replace(/\//g, '.');
              timeLabels.push(hourLabel);
              const point = {
                name: hourLabel,
                happyscore: Math.floor(e.happyscore.value * 10) / 10,
              };
              formatted.push(point);
            });

            // console.log(".then: " + JSON.stringify(formatted));
            // console.log(formatted.length < 1);
            // if (formatted.length < 1) {
            //   formatted = test_formatted;
            // } else {
            //   formatted.map(function (e, i) {
            //     e["happyscore"] = test_formatted[i]["happyscore"];
            //     e["name"] = test_formatted[i]["name"];
            //   });
            // }
            setDataFormat(formatted);

            // const arrAvg = (arr) => arr.reduce((a, b) => a + b, 0) / arr.length;
            // setAvg(Number(arrAvg(happyscore).toFixed(0)));
          });
      } catch (error) {
        // console.error(error);
      }
    };
    getData();
  }, [selectedTimeFrame, interval]);
  // ===============================================================================================

  useEffect(() => {
    const getData2 = async () => {
      GetMultipleMetric(selectedTimeFrame).then((data) => {
        data[0].aggregations.byhour.buckets.map((e) => {
          let hourLabel = e.key_as_string;
          hourLabel = new Date(hourLabel).toLocaleDateString();
          hourLabel = hourLabel.slice(0, 5).replace(/\//g, '.');
          const point = {
            name: hourLabel,
            Internal: Math.floor(e.score.value * 10) / 10,
          };
          internalData.push(point);
        });
        data[1].aggregations.byhour.buckets.map((e) => {
          let hourLabel = e.key_as_string;
          hourLabel = new Date(hourLabel).toLocaleDateString();
          hourLabel = hourLabel.slice(0, 5).replace(/\//g, '.');
          const point = {
            name: hourLabel,
            External: Math.floor(e.score.value * 10) / 10,
          };
          externalData.push(point);
        });
        data[2].aggregations.byhour.buckets.map((e) => {
          let hourLabel = e.key_as_string;
          hourLabel = new Date(hourLabel).toLocaleDateString();
          hourLabel = hourLabel.slice(0, 5).replace(/\//g, '.');
          const point = {
            name: hourLabel,
            IT: Math.floor(e.score.value * 10) / 10,
          };
          itData.push(point);
        });
        data[3].aggregations.byhour.buckets.map((e) => {
          let hourLabel = e.key_as_string;
          hourLabel = new Date(hourLabel).toLocaleDateString();
          hourLabel = hourLabel.slice(0, 5).replace(/\//g, '.');
          const point = {
            name: hourLabel,
            Energy: Math.floor(e.score.value * 10) / 10,
          };
          energyData.push(point);
        });
        data[4].aggregations.byhour.buckets.map((e) => {
          let hourLabel = e.key_as_string;
          hourLabel = new Date(hourLabel).toLocaleDateString();
          hourLabel = hourLabel.slice(0, 5).replace(/\//g, '.');
          const point = {
            name: hourLabel,
            Satisfaction: Math.floor(e.score.value * 10) / 10,
          };
          satisfactionData.push(point);
        });
        // });
        // console.log("inner: " + JSON.stringify(internalData));
        setDataFormat2(internalData);
        setGroupData(externalData);
        setGroupData2(itData);
        setGroupData3(energyData);
        setGroupData4(satisfactionData);

        //= ===========================================================
      });
    };
    getData2();
  }, [selectedTimeFrame]);

  return (
    <View
    // style={{
    //   // flex: 1,
    //   // justifyContent: "center",
    //   // alignItems: "center",
    //   width: { width },
    // }}
    >
      {/* <Card containerStyle={{ borderRadius: 24, width: 900, height: 400 }}> */}

      <View
        style={{
          alignSelf: 'center',
          paddingTop: 20,
        }}
      >
        {/* //======================================================================== */}
        <SelectTimeFrame
          data={buttondata}
          onSelect={(value, timeString) => handleClick(value, timeString)}
        />
      </View>
      <ResponsiveContainer width="99%" height={320}>
        <LineChart
          margin={{
            top: 30,
            right: 30,
            left: 0,
            bottom: 0,
          }}
          data={dataFormat}
        >
          <Line
            type="monotone"
            data={dataFormat2}
            dataKey={'Internal'}
            yAxisId="main"
            xAxisId="other"
            stroke="#2173C4"
            strokeWidth={2}
            dot={{
              opacity: 1,
              stroke: '#2173C4',
              strokeWidth: 2,
              r: 1.2,
            }}
            // activeDot={{ stroke: "#2173C4", r: 6 }}
          />
          <Line
            type="monotone"
            data={dataFormat}
            dataKey={'happyscore'}
            yAxisId="main"
            xAxisId="main"
            stroke="#003A70"
            strokeWidth={2}
            dot={{
              opacity: 1,
              stroke: '#003A70',
              strokeWidth: 2,
              r: 1.2,
            }}
            // activeDot={{ stroke: "#003A70", r: 6 }}
          />
          <Line
            type="monotone"
            data={groupData}
            yAxisId="main"
            xAxisId="group"
            dataKey={'External'}
            stroke="#F5B335"
            strokeWidth={2}
            dot={{
              opacity: 1,
              stroke: '#F5B335',
              strokeWidth: 2,
              r: 1.2,
            }}
            // dot={<SvgComponent />}
            // activeDot={<SvgComponent />}
          />
          <Line
            type="monotone"
            data={groupData2}
            dataKey={'IT'}
            yAxisId="main"
            xAxisId="externalData"
            stroke="#D6006D"
            strokeWidth={2}
            dot={{
              opacity: 1,
              stroke: '#D6006D',
              strokeWidth: 2,
              r: 1.2,
            }}
            // activeDot={{ stroke: "#D6006D", r: 6 }}
          />
          <Line
            type="monotone"
            data={groupData3}
            dataKey={'Energy'}
            yAxisId="main"
            xAxisId="itData"
            stroke="#6BCBB8"
            strokeWidth={2}
            dot={{
              opacity: 1,
              stroke: '#6BCBB8',
              strokeWidth: 2,
              r: 1.2,
            }}
            // activeDot={{ stroke: "#6BCBB8", r: 6 }}
          />
          <Line
            type="monotone"
            data={groupData4}
            yAxisId="main"
            xAxisId="energyData"
            dataKey={'Satisfaction'}
            stroke="#4D7F71"
            strokeWidth={2}
            dot={{
              opacity: 1,
              stroke: '#4D7F71',
              strokeWidth: 2,
              r: 1.2,
            }}
            // dot={<SvgComponent />}
            // activeDot={<SvgComponent />}
          />

          <CartesianAxis axisLine={false} />
          <XAxis
            xAxisId="main"
            dataKey="name"
            padding={{ left: 30, right: 30 }}
            axisLine={false}
            tickLine={false}
            fontFamily={'Arial'}
            fontSize={12}
          />

          <XAxis
            hide={true}
            xAxisId="other"
            // data={dataFormat}
            // dataKey="Internal environment"
            padding={{ left: 30, right: 30 }}
          />
          <XAxis
            hide={true}
            xAxisId="group"
            // data={dataFormat}
            // dataKey="Internal environment"
            padding={{ left: 30, right: 30 }}
          />
          <XAxis
            hide={true}
            xAxisId="externalData"
            // data={dataFormat}
            // dataKey="Internal environment"
            padding={{ left: 30, right: 30 }}
          />
          <XAxis
            hide={true}
            xAxisId="itData"
            // data={dataFormat}
            // dataKey="Internal environment"
            padding={{ left: 30, right: 30 }}
          />
          <XAxis
            hide={true}
            xAxisId="energyData"
            // data={dataFormat}
            // dataKey="Internal environment"
            padding={{ left: 30, right: 30 }}
          />
          <YAxis
            yAxisId="main"
            // tickCount={10}
            ticks={[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
            axisLine={false}
            tickLine={false}
            fontFamily={'Arial'}
            fontSize={12}
            domain={[0, 100]}
            interval={0}
          />
          <CartesianGrid
            opacity={0.5}
            strokeDasharray="5 10 "
            vertical={false}
            // horizontalFill="grey"
            // fillOpacity={0.3}
            // fillRule="even"
          />

          <Tooltip
            wrapperStyle={{ fontSize: '14px', fontFamily: 'Arial' }}
            // labelFormatter={() => "Info"}
            label={{
              stroke: '#696D70',
              fontFamily: 'Arial',
              fontSize: 10,
            }}
          />
        </LineChart>
      </ResponsiveContainer>
      {/* </Card> */}
    </View>
  );
};

// const styles = StyleSheet.create({
//   greyText: {
//     color: '#696D70',
//     alignSelf: 'center',
//     fontFamily: 'Poppins_400Regular',
//     fontSize: 12,
//   },
//   container: {
//     alignItems: 'center',
//     justifyContent: 'center',
//   },
//   textInput: {
//     width: '80%',
//     height: 50,
//     borderColor: 'black',
//     borderWidth: 2,
//   },
// });
export default HappyGroupsGraph;
