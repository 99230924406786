import React, { useRef } from 'react';
import { TouchableOpacity } from 'react-native';

import { useHover } from 'react-native-web-hooks';
import Colors from '../constants/Colors';

export default function ReportListItem({
  children,
  style,
  onPress,
  isSelected,
}: {
  children: any;
  style?: any;
  onPress: any;
  isSelected: boolean;
}) {
  const ref = useRef(null);
  const isHovered = useHover(ref);

  return (
    <TouchableOpacity
      accessibilityRole="link"
      ref={ref}
      style={[
        isHovered ? { backgroundColor: Colors.light.hereworksHover } : { backgroundColor: '#fff' },
        isSelected && { backgroundColor: Colors.light.hereworksSelected },
        style,
      ]}
      onPress={onPress}
    >
      {children}
    </TouchableOpacity>
  );
}
