import * as React from 'react';
import Svg, {
  G, Rect, Defs, LinearGradient, Stop,
} from 'react-native-svg';
import Style from '../constants/Style';

const HappyScoreBar = () => (
    <Svg
        height={20}
        fill="none"
        // @ts-ignore
        style={[Style.barShadow, {
          position: 'absolute', top: 26, left: 6, width: '100%',
        }]}
    >
        <G>
            <Rect
                x={0}
                y={0}
                width={'100%'}
                height={20}
                rx={6}
                fill="url(#bg)"
            />
        </G>
        <Defs>
            <LinearGradient
                id="bg"
                x1={0}
                y1={15}
                x2={0}
                y2={0}
                gradientUnits="userSpaceOnUse"
            >
                <Stop stopColor={'white'} />
                <Stop offset={1} stopColor={'rgba(192, 192, 192, 1)'} stopOpacity={0.2} />
            </LinearGradient>
        </Defs>
    </Svg>
);

export default HappyScoreBar;
