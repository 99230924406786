import * as React from 'react';
import Svg, { SvgProps, Rect, Path } from 'react-native-svg';

const FilterIcon = (props: SvgProps) => (
  <Svg
    width={40}
    height={40}
    fill="none"
    {...props}
  >
    <Rect width={40} height={40} rx={10} fill="#003A70" />
    <Path
      d="M10 13h20M10 18h20M10 23h20M10 28h20"
      stroke="#fff"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default FilterIcon;
