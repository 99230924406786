/* eslint-disable array-callback-return */
import React, { useEffect, useState, useRef } from 'react';

// import { Card } from 'react-native-elements';
// eslint-disable-next-line object-curly-newline
import { Text, TouchableOpacity, View } from 'react-native';
import { Defs, LinearGradient, Stop } from 'react-native-svg';
import {
  VictoryAxis,
  VictoryChart,
  VictoryScatter,
  VictoryLine,
  VictoryTooltip,
} from 'victory-native';
import DropdownMetricSelect from './DropdownMetricSelect';

import { GetMultipleMetric } from '../requests/GetMultipleMetric';
import SmallTimeSelector from './SmallTimeSelector';

const HappyDetailsGraph = (props) => {
  const DropdownButton = useRef();

  const [internalGraphData, setInternalGraphData] = useState([]);
  const [externalGraphData, setExternalGraphData] = useState([]);
  const [ITGraphData, setITGraphData] = useState([]);
  const [energyGraphData, setEnergyGraphData] = useState([]);
  const [occupancyGraphData, setOccupancyGraphData] = useState([]);
  const [satisfactionGraphData, setSatisfactionGraphData] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [interval, setInterval] = useState(props.interval);
  // eslint-disable-next-line no-unused-vars
  const [selectedTimeFrame, setTimeFrame] = useState('7d');

  const [visibleLines, setVisibleLines] = useState([
    'internal',
    'external',
    'occupancy',
    'energy',
    'people',
    'IT',
  ]);

  const [hidden, setHidden] = useState(true);

  let point = {};

  const externalData = [];
  const itData = [];
  const energyData = [];
  const satisfactionData = [];
  const occupancyData = [];

  const internalData = [];

  // eslint-disable-next-line no-unused-vars
  const buttondata = [
    { value: '1D', timeString: '1d' },
    { value: '1W', timeString: '7d' },
    { value: '1M', timeString: '30d' },
    { value: '3M', timeString: '90d' },
    { value: '6M', timeString: '180d' },
    { value: 'Y', timeString: '365d' },
  ];

  function handleSelect(listSelected) {
    setVisibleLines(listSelected);
  }
  function handleClick(value, timeString) {
    setTimeFrame(timeString);
    // console.log(value, timeString);
  }

  function toggleList() {
    setHidden(!hidden);
  }
  // ==================================================================================
  useEffect(() => {
    const getData2 = async () => {
      GetMultipleMetric(selectedTimeFrame).then((data) => {
        data[0]?.aggregations.byhour.buckets.map((e) => {
          let hourLabel = e.key_as_string;
          hourLabel = new Date(hourLabel).toLocaleDateString();
          hourLabel = `${new Date(hourLabel)
            .toLocaleDateString()
            .slice(0, 5)
            .replace(/\//g, '.')}.`;
          point = {
            name: hourLabel,
            Internal: Math.floor(e.score.value * 10) / 10,
          };
          internalData.push(point);
        });
        data[1]?.aggregations.byhour.buckets.map((e) => {
          let hourLabel = e.key_as_string;
          hourLabel = new Date(hourLabel).toLocaleDateString();
          hourLabel = `${new Date(hourLabel)
            .toLocaleDateString()
            .slice(0, 5)
            .replace(/\//g, '.')}.`;
          point = {
            name: hourLabel,
            External: Math.floor(e.score.value * 10) / 10,
          };
          externalData.push(point);
        });
        data[2]?.aggregations.byhour.buckets.map((e) => {
          let hourLabel = e.key_as_string;
          hourLabel = new Date(hourLabel).toLocaleDateString();
          hourLabel = `${new Date(hourLabel)
            .toLocaleDateString()
            .slice(0, 5)
            .replace(/\//g, '.')}.`;
          point = {
            name: hourLabel,
            IT: Math.floor(e.score.value * 10) / 10,
          };
          itData.push(point);
        });
        data[3]?.aggregations.byhour.buckets.map((e) => {
          let hourLabel = e.key_as_string;
          hourLabel = new Date(hourLabel).toLocaleDateString();
          hourLabel = `${new Date(hourLabel)
            .toLocaleDateString()
            .slice(0, 5)
            .replace(/\//g, '.')}.`;
          point = {
            name: hourLabel,
            Energy: Math.floor(e.score.value * 10) / 10,
          };
          energyData.push(point);
        });
        data[4]?.aggregations.byhour.buckets.map((e) => {
          let hourLabel = e.key_as_string;
          hourLabel = new Date(hourLabel).toLocaleDateString();
          hourLabel = `${new Date(hourLabel)
            .toLocaleDateString()
            .slice(0, 5)
            .replace(/\//g, '.')}.`;
          point = {
            name: hourLabel,
            Satisfaction: Math.floor(e.score.value * 10) / 10,
          };
          satisfactionData.push(point);
        });
        data[5]?.aggregations.byhour.buckets.map((e) => {
          let hourLabel = e.key_as_string;
          hourLabel = new Date(hourLabel).toLocaleDateString();
          hourLabel = `${new Date(hourLabel)
            .toLocaleDateString()
            .slice(0, 5)
            .replace(/\//g, '.')}.`;
          point = {
            name: hourLabel,
            Occupancy: Math.floor(e.score.value * 10) / 10,
          };
          occupancyData.push(point);
        });
        // });
        // console.log("inner: " + JSON.stringify(internalData));
        setInternalGraphData(internalData);
        setExternalGraphData(externalData);
        setITGraphData(itData);
        setEnergyGraphData(energyData);
        setSatisfactionGraphData(satisfactionData);
        setOccupancyGraphData(occupancyData);
        // console.log('====================================================');
        // console.log(externalData);
        // console.log('====================================================');
        // console.log(internalData);
        // console.log('====================================================');

        //= ===========================================================
      });
    };
    getData2();
  }, [selectedTimeFrame]);

  const chartTheme = {
    axis: {
      stroke: 'none',
      style: {
        // fixLabelOverlap: true,
        stroke: '#696D70',
        tickLabels: {
          fill: '#696D70',
        },
        grid: { stroke: 'none' },
      },
    },
  };
  return (
    <View>
      {/* <Card containerStyle={{ borderRadius: 24, width: 900 }}> */}
      <View
        style={{
          alignSelf: 'center',
          alignContent: 'center',
          flexDirection: 'row',
        }}
      >
        {/* //======================================================================== */}
      </View>
      <View
        style={{
          alignSelf: 'center',
        }}
      >
        <SmallTimeSelector
          data={buttondata}
          onSelect={(value, timeString) => handleClick(value, timeString)}
        />
      </View>
      <TouchableOpacity
        style={{
          backgroundColor: '#fff',
          borderColor: '#C9DBE3',
          borderWidth: 2,
          padding: 3,
          borderRadius: 13,
          width: 55,
          marginLeft: 30,
          justifyContent: 'center',
          height: 40,
          alignItems: 'center',
        }}
        ref={DropdownButton}
        onPress={toggleList}
      >
        <Text style={{ color: '#323E48' }}>{visibleLines.length}</Text>
      </TouchableOpacity>
      <View
        style={{
          zIndex: 100,
          position: 'absolute',
          top: 100,
          left: 5,
          width: '50%',
        }}
      >
        {hidden ? null : (
          <DropdownMetricSelect
            onSelect={(listSelected) => handleSelect(listSelected)}
            selected={visibleLines}
          />
        )}
      </View>

      <VictoryChart
        theme={chartTheme}
        height={350}
        domainPadding={{ x: [20, -20], y: 20 }}
      >
        <Defs>
          <LinearGradient id="myGradient" x1="0%" y1="0%" x2="0%" y2="100%">
            <Stop offset="0%" stopColor="#003A70" stopOpacity={0.2} />

            <Stop offset="100%" stopColor="#003A70" stopOpacity={0} />
          </LinearGradient>
        </Defs>

        <VictoryLine
          style={{
            data: { stroke: '#7E57C5' },
          }}
          data={visibleLines.includes('external') ? externalGraphData : []}
          x="name"
          y="External"
          interpolation="catmullRom"
          labelComponent={
            <VictoryTooltip
              // data={visibleLines.includes('external') ? externalGraphData : []}
              active={true}
            />
          }
        />
        <VictoryScatter
          symbol={'triangleUp'}
          data={visibleLines.includes('external') ? externalGraphData : []}
          x="name"
          y="External"
          style={{
            data: { fill: '#7E57C5' },
          }}
          size={({ active }) => (active ? 8 : 3)}
          labelComponent={
            <VictoryTooltip
              // data={visibleLines.includes('external') ? externalGraphData : []}
              active={true}
            />
          }
        />
        {/* =========================================================================== */}

        <VictoryLine
          style={{
            data: { stroke: '#2173C4' },
          }}
          key="internal"
          data={visibleLines.includes('internal') ? internalGraphData : []}
          x="name"
          y="Internal"
          interpolation="catmullRom"
          // events={[
          //   {
          //     target: 'data',
          //     eventHandlers: {
          //       onClick: () => {
          //         return [
          //           {
          //             target: 'data',
          //             mutation: (props) => {
          //               return props.text === 'clicked'
          //                 ? null
          //                 : { text: 'clicked' };
          //             },
          //           },
          //         ];
          //       },
          //     },
          //   },
          // ]}
        />
        <VictoryScatter
          symbol={'diamond'}
          data={visibleLines.includes('internal') ? internalGraphData : []}
          x="name"
          y="Internal"
          style={{
            data: { fill: '#2173C4' },
          }}
          size={({ active }) => (active ? 8 : 3)}
        />
        {/* =========================================================================== */}
        <VictoryLine
          style={{
            data: { stroke: '#4D7F71' },
          }}
          data={visibleLines.includes('people') ? satisfactionGraphData : []}
          x="name"
          y="Satisfaction"
          interpolation="catmullRom"
        />
        <VictoryScatter
          symbol={'plus'}
          data={visibleLines.includes('people') ? satisfactionGraphData : []}
          x="name"
          y="Satisfaction"
          style={{
            data: { fill: '#4D7F71' },
          }}
          size={({ active }) => (active ? 8 : 3)}
        />
        {/* =========================================================================== */}
        <VictoryLine
          style={{
            data: { stroke: '#D6006D' },
          }}
          data={visibleLines.includes('energy') ? energyGraphData : []}
          x="name"
          y="Energy"
          interpolation="catmullRom"
        />
        <VictoryScatter
          symbol={'square'}
          data={visibleLines.includes('energy') ? energyGraphData : []}
          x="name"
          y="Energy"
          style={{
            data: { fill: '#D6006D' },
          }}
          size={({ active }) => (active ? 8 : 3)}
        />
        {/* =========================================================================== */}
        <VictoryLine
          style={{
            data: { stroke: '#F5B335' },
          }}
          data={visibleLines.includes('IT') ? ITGraphData : []}
          x="name"
          y="IT"
          interpolation="catmullRom"
        />
        <VictoryScatter
          symbol={'triangleDown'}
          data={visibleLines.includes('IT') ? ITGraphData : []}
          x="name"
          y="IT"
          style={{
            data: { fill: '#F5B335' },
          }}
          size={({ active }) => (active ? 8 : 3)}
        />
        {/* =========================================================================== */}
        <VictoryLine
          style={{
            data: { stroke: '#003A70' },
          }}
          data={visibleLines.includes('occupancy') ? occupancyGraphData : []}
          x="name"
          y="Occupancy"
          interpolation="catmullRom"
        />
        <VictoryScatter
          // symbol={'triangleDown'}
          data={visibleLines.includes('occupancy') ? occupancyGraphData : []}
          x="name"
          y="Occupancy"
          style={{
            data: { fill: '#003A70' },
          }}
          size={({ active }) => (active ? 8 : 3)}
        />
        {/* =========================================================================== */}

        <VictoryAxis
          fixLabelOverlap={true}
          style={{
            axis: { stroke: 'none' },
            tickLabels: { fontSize: 12, padding: 5 },
          }}
        />
        <VictoryAxis
          dependentAxis
          fixLabelOverlap={true}
          // x="name"
          // y="Score"
          style={{
            axis: { stroke: 'none' },
            tickLabels: { fontSize: 12, padding: 0 },
          }}
        />
      </VictoryChart>
      {/* </Card> */}
    </View>
  );
};
// const styles = StyleSheet.create({
//   buttonBlue: {
//     backgroundColor: '#003A70',
//     padding: 3,
//     borderRadius: 150,
//     width: 150,
//     marginLeft: 30,
//     justifyContent: 'center',
//     height: 27,
//     alignSelf: 'center',
//   },
//   buttonBlueText: {
//     color: 'white',
//     alignSelf: 'center',
//     fontSize: 13,
//     marginLeft: 5,
//   },
// });
export default HappyDetailsGraph;
