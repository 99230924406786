import * as React from 'react';
import Svg, { SvgProps, Path, Circle } from 'react-native-svg';

const BlueLineIcon = (props: SvgProps) => (
  <Svg width={50} height={10} fill="none" {...props}>
    <Path
      d="M1 5h48"
      stroke="#003A70"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Circle cx={25} cy={5} r={5} fill="#003A70" />
  </Svg>
);

export default BlueLineIcon;
