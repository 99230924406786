import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const ButtonsIcon = (props: SvgProps) => (
  <Svg
    width={25}
    height={24}
    fill="none"
    {...props}
  >
    <Path
      d="M5 9.75A.75.75 0 0 0 5.75 9V5.25H9.5a.75.75 0 1 0 0-1.5H5a.75.75 0 0 0-.75.75V9a.75.75 0 0 0 .75.75ZM15.5 5.25h3.75V9a.75.75 0 1 0 1.5 0V4.5a.75.75 0 0 0-.75-.75h-4.5a.75.75 0 1 0 0 1.5ZM9.5 18.75H5.75V15a.75.75 0 1 0-1.5 0v4.5a.75.75 0 0 0 .75.75h4.5a.75.75 0 1 0 0-1.5ZM20 14.25a.75.75 0 0 0-.75.75v3.75H15.5a.75.75 0 1 0 0 1.5H20a.75.75 0 0 0 .75-.75V15a.75.75 0 0 0-.75-.75ZM23 11.25H2a.75.75 0 1 0 0 1.5h21a.75.75 0 1 0 0-1.5Z"
      fill={props.color ?? '#89A7CB'}
    />
  </Svg>
);

export default ButtonsIcon;
