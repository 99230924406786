import React, { useState } from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import Colors from '../constants/Colors';

export default function Switch({ isToggled, onToggled }:
  { isToggled: boolean, onToggled: () => void}) {
  const [toggled, setToggled] = useState(isToggled ?? false);

  return (
    <TouchableOpacity
      style={{ position: 'absolute', right: 17 }}
      onPress={() => {
        setToggled(!toggled);
        onToggled();
      }}>
      <View style={[styles.switchContainer, toggled ? { backgroundColor: '#D6E7C3' } : { backgroundColor: Colors.light.hereworksPaleBlue800 }]}>
        <View style={[styles.switchSelection, toggled ? { alignSelf: 'flex-end', backgroundColor: '#A1D683' } : { backgroundColor: '#6892C0' }]} />
      </View>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  switchContainer: {
    height: 26,
    width: 46,
    borderRadius: 8,
  },
  switchSelection: {
    height: 26,
    width: 26,
    borderRadius: 8,
  },
});
