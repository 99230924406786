import * as React from 'react';
import Svg, { SvgProps, Circle, Path } from 'react-native-svg';

const CompleteIcon = (props: SvgProps) => (
  <Svg
    width={16}
    height={16}
    fill="none"
    {...props}
  >
    <Circle cx={8} cy={8} r={7.5} stroke="#323E48" />
    <Path d="m5.334 8.285 1.8 1.8 3.8-3.8" stroke="#323E48" />
  </Svg>
);

export default CompleteIcon;
