import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const GreenLineIcon = (props: SvgProps) => (
  <Svg width={50} height={12} fill="none" {...props}>
    <Path
      d="M1 6h48"
      stroke="#4D7F71"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M21 6h8M25 2v8"
      stroke="#4D7F71"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default GreenLineIcon;
