import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const ChairIconDesktop = (props: SvgProps) => (
    <Svg
        width={24}
        height={24}
        fill="none"
        {...props}
    >
        <Path
            d="M7.875 23H12m0 0h4.125M12 23v-5.042m0 0h6.75a1.5 1.5 0 0 0 1.5-1.5v-2.625M12 17.958H5.25a1.5 1.5 0 0 1-1.5-1.5v-2.625m0 0h16.5m-16.5 0v-1.25a1.5 1.5 0 0 0-1.5-1.5H1m19.25 2.75v-1.25a1.5 1.5 0 0 1 1.5-1.5H23m-15 0h8a1.5 1.5 0 0 0 1.5-1.5V2.5A1.5 1.5 0 0 0 16 1H8a1.5 1.5 0 0 0-1.5 1.5v7.083a1.5 1.5 0 0 0 1.5 1.5Z"
            stroke={props.color ?? '#89A7CB'}
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </Svg>
);

export default ChairIconDesktop;
