import React from 'react';
import { StyleSheet } from 'react-native';

import { View } from '../components/Themed';
import { RootTabScreenProps } from '../types';
import Colors from '../constants/Colors';
import ScoreCard from '../components/ScoreCard';
import SingleLineGraph from '../components/SingleLineGraph';
import DualAxisGraph from '../components/DualAxisGraph';

// eslint-disable-next-line no-unused-vars
export default function InternalEnvironmentScreen({
  // eslint-disable-next-line no-unused-vars
  navigation,
}: RootTabScreenProps<'InternalEnvironment'>) {
  return (
    <View>
      <View style={styles.container}>
        <ScoreCard category={'internal-environment'} />
        <View style={styles.graphCard}>
          <SingleLineGraph
            metricName="happy_group_internal_environment_local_happyscore_percent"
            title="Internal Env."
            interval="24h"
          />
        </View>
      </View>
      <View style={styles.container}>
        <View style={styles.lowergraphCard}>
          <DualAxisGraph />
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 3,
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    backgroundColor: Colors.light.hereworksBackground,
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
    color: 'black',
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: '80%',
  },
  desktopLinearGradient: {
    width: '100%',
    height: 360,
    borderRadius: 24,
    overflow: 'hidden',
  },
  graphCard: {
    marginVertical: 40,
    marginRight: 40,
    borderRadius: 24,
    backgroundColor: 'white',
    flex: 2,
    height: 408,
  },
  lowergraphCard: {
    marginTop: 20,
    marginRight: 40,
    marginLeft: 40,
    borderRadius: 24,
    backgroundColor: 'white',
    flex: 2,
    height: 408,
  },
});
