import { DrawerHeaderProps } from '@react-navigation/drawer';
import React, { useState } from 'react';
import {
  Text, StyleSheet, View, Platform, NativeEventEmitter,
} from 'react-native';
import { TextInput, TouchableOpacity } from 'react-native-gesture-handler';
import Colors from '../constants/Colors';
import Style from '../constants/Style';
import BellIcon from './BellIcon';
import NotificationsCard from './NotificationsCard';
import SearchIcon from './SearchIcon';
import getIssues from '../requests/GetIssues';

const CustomHeader = (props: DrawerHeaderProps) => {
  const title = props.options.title ?? props.route.name;

  const [modalVisible, setModalVisible] = useState(false);
  const [notificationsCount, setNotificationsCount] = useState(0);
  const [refreshCount, setRefreshCount] = useState(0);
  const [counter, setCounter] = useState(0);
  const [initialLoaded, setInitialLoaded] = useState(false);

  React.useEffect(() => {
    getIssues().then((response) => {
      if (response) {
        setNotificationsCount(
          response.issues.filter((issue: any) => issue.assignee === null).length,
        );
      }
    });
  }, [refreshCount]);

  React.useEffect(() => {
    const emitter = new NativeEventEmitter();
    emitter.addListener('event.updatenotifications', () => {
      setRefreshCount(refreshCount + 1);
    });
  }, []);

  React.useEffect(() => {
    const oldCount = notificationsCount;
    getIssues().then((response) => {
      if (response) {
        const newCount = response.issues.filter((issue: any) => issue.assignee === null).length;
        setNotificationsCount(newCount);
        if (initialLoaded && newCount !== oldCount) {
          const emitter = new NativeEventEmitter();
          emitter.emit('event.newupdates', {});
        }
        setInitialLoaded(true);
      }
    });
    const interval = setInterval(() => {
      setCounter(counter + 1);
    }, 15000);

    return () => clearInterval(interval);
  }, [counter]);

  return (
    <View style={{ flex: 1, backgroundColor: Colors.light.hereworksBackground }}>
      <View style={[styles.containerStyle, Style.boxShadow]}>
        <Text style={styles.headerText}>{title}</Text>
        <View style={styles.searchBox}>
          <SearchIcon style={{ marginLeft: 14 }} />
          <TextInput
            placeholder="Search"
            style={[
              styles.searchText,
              // @ts-ignore
              Platform.OS === 'web' ? { outlineStyle: 'none' } : {},
            ]}
          />
        </View>
        <TouchableOpacity
          style={styles.notificationMenu}
          onPress={() => setModalVisible(!modalVisible)}
        >
          <BellIcon color={Colors.light.hereworksDarkBlue} />
          {notificationsCount > 0 && (
            <View
              style={[
                styles.notificationCount,
                notificationsCount > 99 ? { width: 26, right: -10 } : {},
              ]}
            >
              <Text style={styles.notificationCountText}>{notificationsCount}</Text>
            </View>
          )}
        </TouchableOpacity>
      </View>
      {modalVisible && (
        <View style={[styles.notificationsModal, Style.boxShadow]}>
          <NotificationsCard />
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  containerStyle: {
    backgroundColor: '#FFFFFF',
    marginTop: 12,
    marginLeft: 20,
    marginRight: 20,
    height: 76,
    borderRadius: 10,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  headerText: {
    height: 33,
    fontFamily: 'Poppins_400Regular',
    fontSize: 22,
    marginLeft: 28,
    marginTop: 22,
    marginBottom: 22,
    flex: 1,
  },
  searchBox: {
    height: 48,
    width: 240,
    borderRadius: 24,
    backgroundColor: Colors.light.hereworksBackground,
    marginRight: 12,
    borderColor: Colors.light.hereworksPaleBlue900,
    borderWidth: 1.5,
    flexDirection: 'row',
    alignItems: 'center',
  },
  searchText: {
    color: Colors.light.hereworksPrimaryGrey,
    marginLeft: 9,
    borderWidth: 0,
  },
  notificationMenu: {
    height: 48,
    width: 48,
    justifyContent: 'center',
    alignItems: 'center',
    borderColor: Colors.light.hereworksPaleBlue900,
    backgroundColor: Colors.light.hereworksBackground,
    borderWidth: 1.5,
    borderRadius: 24,
    marginRight: 14,
  },
  notificationsModal: {
    position: 'absolute',
    backgroundColor: '#fff',
    top: 110,
    right: 20,
    width: 400,
    height: 640,
    borderRadius: 10,
  },
  notificationCount: {
    position: 'absolute',
    width: 20,
    height: 20,
    top: -2.5,
    right: -3.5,
    borderRadius: 10,
    justifyContent: 'center',
    backgroundColor: Colors.light.hereworksUrgentRed,
  },
  notificationCountText: {
    textAlign: 'center',
    fontSize: 12,
    color: '#fff',
    fontFamily: 'Roboto_700Bold',
  },
});

export default CustomHeader;
