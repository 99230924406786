import { FontAwesome } from '@expo/vector-icons';
import React, { useState } from 'react';
import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  Image,
  Platform,
  TouchableOpacity,
  Dimensions,
  ActivityIndicator,
  TextInput,
  NativeEventEmitter,
  Alert,
  RefreshControl,
  Keyboard,
  KeyboardAvoidingView,
} from 'react-native';
import { PanGestureHandler, PanGestureHandlerGestureEvent } from 'react-native-gesture-handler';
import Animated, { useAnimatedGestureHandler, useSharedValue } from 'react-native-reanimated';
import Moment from 'moment';
import * as Analytics from 'expo-firebase-analytics';
import { DatePickerModal } from 'react-native-paper-dates';
import Modal from 'react-native-modal';
import * as Animatable from 'react-native-animatable';
import CheckmarkIcon from '../components/CheckmarkIcon';
import CloseFilterIcon from '../components/CloseFilterIcon';
import FilterBox from '../components/FilterBox';
import FilterIcon from '../components/FilterIcon';
import LocationIcon from '../components/LocationIcon';
import NoTaskIcon from '../components/NoTaskIcon';
import NoteIcon from '../components/NoteIcon';
import PriorityIcon from '../components/PriorityIcon';
import ProblemArrowIcon from '../components/ProblemArrowIcon';
import ReportTimeIcon from '../components/ReportTimeIcon';
import ResumeIcon from '../components/ResumeIcon';
import SearchIcon from '../components/SearchIcon';
import Colors from '../constants/Colors';
import { RootTabScreenProps } from '../types';
import LeftArrow from '../components/LeftArrow';
import getIssues from '../requests/GetIssues';
import Style from '../constants/Style';
import EllipsisIcon from '../components/EllipsisIcon';
import ResumeTriangleIcon from '../components/ResumeTriangleIcon';
import updateIssue from '../requests/UpdateIssue';
import getName from '../requests/GetName';
import PauseIcon from '../components/PauseIcon';
import ClockIcon from '../components/ClockIcon';
import CompleteIcon from '../components/CompleteIcon';
import BinIcon from '../components/BinIcon';
import AvatarIcon from '../components/AvatarIcon';
import deleteIssue from '../requests/DeleteIssue';
import ReportListItem from '../components/ReportListItem';
import updateNote from '../requests/UpdateNote';

// eslint-disable-next-line no-unused-vars
export default function ReportsScreen({ route, navigation }: RootTabScreenProps<'Reports'>) {
  const [problems, setProblems] = useState([]);
  const [allProblems, setAllProblems] = useState([]);
  const [selectedProblem, setSelectedProblem] = useState(undefined);

  const [contentOffset, setContentOffset] = React.useState({ x: 0, y: 0 });
  const [contentSize, setContentSize] = React.useState(0);
  const [scrollViewHeight, setScrollViewHeight] = React.useState(0);
  const [filterOpen, setFilterOpen] = React.useState(false);
  const [assigneeDropdown, setAssigneeDropdown] = React.useState(false);

  const [lowPriorityFilter, setLowPriorityFilter] = React.useState(false);
  const [medPriorityFilter, setMedPriorityFilter] = React.useState(false);
  const [highPriorityFilter, setHighPriorityFilter] = React.useState(false);
  const [criticalPriorityFilter, setCriticalPriorityFilter] = React.useState(false);

  const [doneFilter, setDoneFilter] = React.useState(false);
  const [inProgressFilter, setInProgressFilter] = React.useState(false);
  const [withoutStatusFilter, setWithoutStatusFilter] = React.useState(false);
  const [selectedAssignee, setSelectedAssignee] = React.useState('');
  const [modalVisible, setModalVisible] = useState(false);
  const [moreOptionsVisible, setMoreOptionsVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showSpinner, setShowSpinner] = useState(false);
  const [startDateVisible, setStartDateVisible] = useState(false);
  const [startDate, setStartDate] = useState(Moment().format('yyyy-MM-DD'));
  const [endDateVisible, setEndDateVisible] = useState(false);
  const [endDate, setEndDate] = useState(Moment().format('yyyy-MM-DD'));
  const [newReports, setNewReports] = useState(false);
  const [savingNote, setSavingNote] = useState(false);
  const [noteValue, setNoteValue] = useState('');
  const [refreshing, setRefreshing] = useState(false);

  const [range, setRange] = useState<{
    start: Date | undefined;
    end: Date | undefined;
  }>({ start: new Date(startDate), end: new Date(endDate) });

  const onConfirm = React.useCallback(
    (start, end) => {
      setRange({ start, end });
    },
    [setRange],
  );

  const window = Dimensions.get('window');
  const [dimensions] = React.useState({ window });

  const [name, updateName] = useState('');
  const [counter, setCounter] = useState(0);

  React.useEffect(() => {
    const emitter = Platform.OS === 'web' ? new NativeEventEmitter() : null;
    emitter?.addListener('event.newupdates', () => {
      setNewReports(true);
    });
  }, []);

  React.useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    getName().then((n) => {
      updateName(n);
    }) ?? 'name';
  }, []);

  React.useEffect(() => {
    // @ts-ignore
    const reportId = route.params?.reportId;
    const problemMissing = problems.filter((problem) => problem.id === reportId)[0] === undefined;
    if (problems.length === 0) {
      setLoading(true);
    } else if (!loading && problemMissing && reportId !== undefined) {
      setSelectedProblem(undefined);
      setLoading(true);
    }

    if (!loading && reportId && reportId !== '' && !problemMissing) {
      // @ts-ignore
      route.params = undefined;
      const selected = problems.filter((problem) => problem.id === reportId)[0];
      if (selected) {
        setSelectedProblem(problems.filter((problem) => problem.id === reportId)[0]);
        setModalVisible(true);
      }
    }
  }, [route, loading]);

  let scrollPosPercent: number;
  const scrollElementHeightPercent = (100 / contentSize) * scrollViewHeight;
  const scrollElementHeightPercentStr = `${scrollElementHeightPercent}%`;

  if (contentOffset.y > 0) {
    scrollPosPercent = Math.min(
      (contentOffset.y / (contentSize - scrollViewHeight)) * 90,
      100 - scrollElementHeightPercent,
    );
  }

  React.useEffect(() => {
    const interval = setInterval(() => {
      if (selectedProblem?.status !== 'paused') {
        setCounter(counter + 1);
      }
    }, 500);

    return () => clearInterval(interval);
  }, [counter]);

  type ContextType = {
    translateX: number;
    translateY: number;
  };

  const scrollView = React.useRef<null | ScrollView>(null);

  const translateX = useSharedValue(0);
  const translateY = useSharedValue(0);

  const emitter = Platform.OS === 'web' ? new NativeEventEmitter() : null;

  // const renderLeftActions = () => <View style={{ width: 20, height: 20 }}></View>;

  React.useEffect(() => {
    getIssues().then((response) => {
      if (response) {
        setProblems(response.issues);
        setAllProblems(response.issues);
        if (selectedProblem !== undefined) {
          // @ts-ignore
          const reportId = route.params?.reportId;
          const setProblem = problems.filter((problem) => problem.id === selectedProblem.id)[0];
          if (setProblem !== undefined && reportId === undefined) {
            setSelectedProblem(setProblem);
            setCounter(counter + 1);
          }
        }
        setLoading(false);
        setShowSpinner(false);
      }
    });
  }, [loading]);

  function resetFilters() {
    setLowPriorityFilter(false);
    setMedPriorityFilter(false);
    setHighPriorityFilter(false);
    setCriticalPriorityFilter(false);
    setDoneFilter(false);
    setInProgressFilter(false);
    setWithoutStatusFilter(false);
  }

  const panGesture = useAnimatedGestureHandler<PanGestureHandlerGestureEvent, ContextType>({
    onStart: (event, context) => {
      context.translateX = translateX.value;
      context.translateY = translateY.value;
    },
    onActive: (event, context) => {
      translateX.value = event.translationX + context.translateX;
      translateY.value = event.translationY + context.translateY;
      scrollView.current?.scrollTo({ y: event.translationY });
    },
    onEnd: (event, context) => {
      setContentOffset({ x: 0, y: context.translateY });
    },
  });

  function getPriorityText(priority: string) {
    switch (priority) {
      case 'low':
        return 'P4 - Low';
      case 'medium':
        return 'P3 - Moderate';
      case 'high':
        return 'P2 - High';
      case 'critical':
        return 'P1 - Critical';
      default:
        return 'P4 - Low';
    }
  }

  function getPriorityColor(priority: string) {
    switch (priority) {
      case 'low':
        return 'transparent';
      case 'medium':
        return Colors.light.hereworksLightBlue;
      case 'high':
        return Colors.light.hereworksMediumYellow;
      case 'critical':
        return Colors.light.hereworksUrgentRed;
      default:
        return 'transparent';
    }
  }

  const renderStatusButtons = () => (
    <>
      {selectedProblem.status === 'reported' && (
        <>
          <TouchableOpacity
            style={styles.resumeButtonMobile}
            onPress={() => {
              Analytics.logEvent('button_press', {
                screen: 'Reports',
                name: 'Start Resolving - Mobile',
              });
              setShowSpinner(true);
              updateIssue(selectedProblem.id, name).then((response) => {
                if (response?.issue?.status === 'inprogress') {
                  problems.filter((problem) => problem.id === selectedProblem.id)[0].status = 'inprogress';
                  // eslint-disable-next-line max-len
                  problems.filter((problem) => problem.id === selectedProblem.id)[0].assignee = response?.issue?.assignee;
                  // eslint-disable-next-line max-len
                  problems.filter((problem) => problem.id === selectedProblem.id)[0].startedon = Moment().toString();
                  selectedProblem.startedon = Moment().toString();
                }
                setShowSpinner(false);
                emitter?.emit('event.updatenotifications', {});
              });
            }}
          >
            <ResumeTriangleIcon />
            <Text style={styles.resumeButtonText}>Start resolving</Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => {
              Analytics.logEvent('button_press', {
                screen: 'Reports',
                name: 'Checkmark - Mobile',
              });
              setShowSpinner(true);
              updateIssue(selectedProblem.id, name, true).then(() => {
                setLoading(true);
                emitter?.emit('event.updatenotifications', {});
              });
            }}
          >
            <CheckmarkIcon
              stroke={Colors.light.hereworksCheckGreen}
              checkcolor={Colors.light.hereworksCheckGreen}
              style={{ marginRight: 12 }}
            />
          </TouchableOpacity>
        </>
      )}
      {selectedProblem.status === 'inprogress' && (
        <>
          <TouchableOpacity
            style={styles.resumeButtonMobile}
            onPress={() => {
              Analytics.logEvent('button_press', {
                screen: 'Reports',
                name: 'Pause - Mobile',
              });
              setShowSpinner(true);
              updateIssue(selectedProblem.id, name, false, true).then((response) => {
                if (response?.issue?.status === 'paused') {
                  problems.filter((problem) => problem.id === selectedProblem.id)[0].status = 'paused';
                  // eslint-disable-next-line max-len
                  problems.filter((problem) => problem.id === selectedProblem.id)[0].pausedon = Moment().toString();
                  selectedProblem.pausedon = Moment().toString();
                }
                setShowSpinner(false);
                emitter?.emit('event.updatenotifications', {});
              });
            }}
          >
            <PauseIcon />
            <Text style={styles.resumeButtonText}>
              {Moment.duration(Moment().diff(Moment(selectedProblem.startedon))).asHours() < 24
                ? Moment.utc(Moment().diff(Moment(selectedProblem.startedon))).format('HH:mm:ss')
                : `${Moment().diff(Moment(selectedProblem.startedon), 'days')} days`}
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => {
              Analytics.logEvent('button_press', {
                screen: 'Reports',
                name: 'In Progress Checkmark - Mobile',
              });
              updateIssue(selectedProblem.id, name, true).then(() => {
                setLoading(true);
                emitter?.emit('event.updatenotifications', {});
              });
            }}
          >
            <CheckmarkIcon
              stroke={Colors.light.hereworksCheckGreen}
              checkcolor={Colors.light.hereworksCheckGreen}
              style={{ marginRight: 12 }}
            />
          </TouchableOpacity>
        </>
      )}
      {selectedProblem.status === 'paused' && (
        <>
          <TouchableOpacity
            style={styles.pauseButtonMobile}
            onPress={() => {
              Analytics.logEvent('button_press', {
                screen: 'Reports',
                name: 'Resume - Mobile',
              });
              setShowSpinner(true);
              updateIssue(selectedProblem.id, name, false, false, true).then((response) => {
                if (response?.issue?.status === 'inprogress') {
                  problems.filter((problem) => problem.id === selectedProblem.id)[0].status = 'inprogress';
                  // eslint-disable-next-line max-len
                  problems.filter((problem) => problem.id === selectedProblem.id)[0].startedon = Moment(response?.issue?.startedon).toString();
                  selectedProblem.startedon = Moment(response?.issue?.startedon).toString();
                }
                setShowSpinner(false);
                emitter?.emit('event.updatenotifications', {});
              });
            }}
          >
            <ResumeTriangleIcon color={Colors.light.hereworksPrimaryGrey} />
            <Text style={styles.pauseButtonText}>
              {Moment.duration(
                Moment(selectedProblem.pausedon).diff(Moment(selectedProblem.startedon)),
              ).asHours() < 24
                ? Moment.utc(
                  Moment(selectedProblem.pausedon).diff(Moment(selectedProblem.startedon)),
                ).format('HH:mm:ss')
                : `${Moment(selectedProblem.pausedon).diff(
                  Moment(selectedProblem.startedon),
                  'days',
                )} days`}
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => {
              Analytics.logEvent('button_press', {
                screen: 'Reports',
                name: 'Paused Checkmark - Mobile',
              });
              updateIssue(selectedProblem.id, name, true).then(() => {
                setLoading(true);
                emitter?.emit('event.updatenotifications', {});
              });
            }}
          >
            <CheckmarkIcon
              stroke={Colors.light.hereworksCheckGreen}
              checkcolor={Colors.light.hereworksCheckGreen}
              style={{ marginRight: 12 }}
            />
          </TouchableOpacity>
        </>
      )}
      {selectedProblem.status === 'completed' && (
        <>
          <TouchableOpacity
            onPress={() => {
              Analytics.logEvent('button_press', {
                screen: 'Reports',
                name: 'Completed - Mobile',
              });
            }}
          >
            <CheckmarkIcon
              fill={Colors.light.hereworksCheckGreen}
              stroke={Colors.light.hereworksCheckGreen}
              checkcolor={'#fff'}
              style={{ marginLeft: 15 }}
            />
          </TouchableOpacity>
          <Text style={styles.resolveText}>Resolved</Text>
        </>
      )}
    </>
  );

  const renderMobile = (): boolean => Platform.OS !== 'web' || dimensions.window?.width < 500 || dimensions.window?.height < 500;

  const renderSelectedProblemWeb = () => (selectedProblem !== undefined ? (
      <Animatable.View
        style={[styles.shadow, styles.selectedProblemContainer, { margin: 30 }]}
        animation={'fadeIn'}
      >
        {selectedProblem?.name !== '' && (
          <View style={{ flex: 3 }}>
            <View style={styles.problemHeaderContainer}>
              <View style={{ flex: 2 }}>
                <Text style={styles.fullProblemTitle}>
                  {selectedProblem.fullTitle === '' || selectedProblem.fullTitle === undefined
                    ? selectedProblem.name
                    : selectedProblem.fullTitle}
                </Text>
                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                  {!selectedProblem.reporterImage && <AvatarIcon />}
                  {selectedProblem.reporterImage && (
                    <Image
                      style={styles.reporterAvatar}
                      source={{ uri: selectedProblem.reporterImage }}
                    />
                  )}
                  {selectedProblem.reporter && (
                    <Text style={styles.reporterText}>
                      Reported by: {selectedProblem.reporter?.split(/[^\w\sá]/)[0]}
                    </Text>
                  )}
                  {!selectedProblem.reporter && (
                    <Text style={styles.reporterText}>Reported anonymously</Text>
                  )}
                  <View style={styles.problemButtons}>{renderStatusButtons()}</View>
                </View>
                <TouchableOpacity
                  onPress={() => setMoreOptionsVisible(!moreOptionsVisible)}
                  style={{ position: 'absolute', right: 20, top: 30 }}
                  hitSlop={{
                    top: 40,
                    bottom: 40,
                    left: 50,
                    right: 50,
                  }}
                >
                  <EllipsisIcon />
                </TouchableOpacity>
                {moreOptionsVisible && (
                  <View
                    style={{
                      position: 'absolute',
                      top: 0,
                      right: 0,
                      zIndex: 2,
                    }}
                  >
                    {renderMoreOptions()}
                  </View>
                )}
              </View>
            </View>
            <View style={styles.problemDivider} />
            <View style={styles.problemSubGroup}>
              <View style={styles.problemSingleGroup}>
                <View style={styles.problemSubHeadingContainer}>
                  <LocationIcon />
                  <Text style={styles.problemSubHeading}>Location</Text>
                </View>
                <Text style={styles.problemDetail}>{`${selectedProblem['location-floor'] ?? ''}, ${
                  selectedProblem['location-room'] ?? ''
                }`}</Text>
              </View>
              <View style={styles.problemSingleGroup}>
                <View style={styles.problemSubHeadingContainer}>
                  <PriorityIcon />
                  <Text style={styles.problemSubHeading}>Priority</Text>
                </View>
                <Text style={styles.problemDetail}>
                  {getPriorityText(selectedProblem.priority)}
                </Text>
              </View>
            </View>
            <View style={styles.problemSubGroup}>
              <View style={styles.problemSingleGroup}>
                <View style={styles.problemSubHeadingContainer}>
                  <ReportTimeIcon />
                  <Text style={styles.problemSubHeading}>Report time</Text>
                </View>
                <Text style={styles.problemDetail}>
                  {Moment(selectedProblem.createdon).format('llll')}
                </Text>
              </View>
              <View style={styles.problemSingleGroup}>
                <View style={styles.problemSubHeadingContainer}>
                  <View style={styles.assigneeAvatarContainer}>
                    {!selectedProblem.assigneeImage && <AvatarIcon />}
                    {selectedProblem.assigneeImage && (
                      <Image
                        style={styles.assigneeAvatar}
                        source={{ uri: selectedProblem.assigneeImage }}
                      />
                    )}
                  </View>
                  <Text style={styles.problemSubHeading}>Assignee</Text>
                </View>
                <Text style={styles.problemDetail}>{selectedProblem.assignee}</Text>
              </View>
            </View>
            <View style={styles.problemSubGroup}>
              <View style={{ flex: 1 }}>
                <View style={styles.problemSubHeadingContainer}>
                  <NoteIcon />
                  <Text style={styles.problemSubHeading}>Note</Text>
                </View>
                <TextInput
                  placeholder="Type your note here..."
                  value={noteValue ?? ''}
                  onChangeText={setNoteValue}
                  // @ts-ignore
                  style={[styles.noteText, !renderMobile() ? { outlineStyle: 'none' } : {}]}
                  onBlur={(text) => {
                    Keyboard.dismiss();
                    setSavingNote(true);
                    const newNote = text.nativeEvent.text;
                    updateNote(selectedProblem.id, newNote).then(() => {
                      setSavingNote(false);
                      // eslint-disable-next-line max-len
                      problems.filter((problem) => problem.id === selectedProblem.id)[0].notes = newNote;
                    });
                  }}
                />
              </View>
              {savingNote && (
                <View
                  style={{
                    left: 0,
                    right: 0,
                    top: 0,
                    bottom: 0,
                    borderRadius: 8,
                    position: 'absolute',
                    justifyContent: 'center',
                    backgroundColor: '#00000020',
                  }}
                >
                  <ActivityIndicator color={Colors.light.hereworksLoading} size={'large'} />
                </View>
              )}
            </View>
          </View>
        )}
      </Animatable.View>
  ) : (
      <View
        style={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          margin: 30,
        }}
      >
        <NoTaskIcon />
        <Text style={styles.nothingHereTitle}>There’s nothing here.</Text>
        <Text style={styles.nothingHereDescription}>
          Click on the report on the left-hand side to view details.
        </Text>
      </View>
  ));

  const renderSelectedProblemMobile = () => selectedProblem !== undefined && (
      <KeyboardAvoidingView
        style={styles.selectedProblemContainerMobile}
        behavior={Platform.OS === 'ios' ? 'position' : 'height'}
      >
        <ScrollView style={{ height: '100%' }}>
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              marginLeft: 20,
              marginBottom: 8,
              marginTop: 14,
            }}
          >
            {!selectedProblem.reporterImage && <AvatarIcon />}
            {selectedProblem.reporterImage && (
              <Image
                style={styles.reporterAvatar}
                source={{ uri: selectedProblem.reporterImage }}
              />
            )}
            {selectedProblem.reporter && (
              <Text style={styles.reporterText}>
                Reported by: {selectedProblem.reporter?.split(/[^\w\sá]/)[0]}
              </Text>
            )}
            {!selectedProblem.reporter && (
              <Text style={styles.reporterText}>Reported anonymously</Text>
            )}
          </View>
          <View style={styles.problemSubGroupMobile}>
            <View style={{ flex: 2, flexDirection: 'row', alignItems: 'center' }}>
              <View style={{ flex: 1 }}>
                <View style={styles.problemSubHeadingContainer}>
                  <LocationIcon />
                  <Text style={styles.problemSubHeading}>Location</Text>
                </View>
              </View>
              <View style={{ flex: 1 }}>
                <Text style={styles.problemDetail}>{`${selectedProblem['location-floor'] ?? ''}, ${
                  selectedProblem['location-room'] ?? ''
                }`}</Text>
              </View>
            </View>
          </View>
          <View style={styles.problemSubGroupMobile}>
            <View style={{ flex: 2, flexDirection: 'row', alignItems: 'center' }}>
              <View style={{ flex: 1 }}>
                <View style={styles.problemSubHeadingContainer}>
                  <PriorityIcon />
                  <Text style={styles.problemSubHeading}>Priority</Text>
                </View>
              </View>
              <View style={{ flex: 1 }}>
                <Text style={styles.problemDetailMobile}>
                  {getPriorityText(selectedProblem.priority)}
                </Text>
              </View>
            </View>
          </View>
          <View style={styles.problemSubGroupMobile}>
            <View style={{ flex: 2, flexDirection: 'row', alignItems: 'center' }}>
              <View style={{ flex: 1 }}>
                <View style={styles.problemSubHeadingContainer}>
                  <ReportTimeIcon />
                  <Text style={styles.problemSubHeading}>Report time</Text>
                </View>
              </View>
              <View style={{ flex: 1 }}>
                <Text style={styles.problemDetailMobile}>
                  {Moment(selectedProblem.createdon).format('llll')}
                </Text>
              </View>
            </View>
          </View>
          <View style={styles.problemSubGroupMobile}>
            <View style={{ flex: 2, flexDirection: 'row', alignItems: 'center' }}>
              <View style={{ flex: 1 }}>
                <View style={styles.problemSubHeadingContainer}>
                  <View style={styles.assigneeAvatarContainer}>
                    {!selectedProblem.assigneeAvatar && <AvatarIcon />}
                    {selectedProblem.assigneeAvatar && (
                      <Image
                        style={styles.assigneeAvatar}
                        source={{ uri: selectedProblem.assigneeImage }}
                      />
                    )}
                  </View>
                  <View style={{ flex: 1 }}>
                    <Text style={styles.problemSubHeading}>Assignee</Text>
                  </View>
                </View>
              </View>
              <View style={{ flex: 1 }}>
                <Text style={styles.problemDetailMobile}>{selectedProblem.assignee}</Text>
              </View>
            </View>
          </View>
          <View style={styles.problemSubGroupMobile}>
            <View style={{ flex: 2, flexDirection: 'row', alignItems: 'center' }}>
              <View style={{ flex: 1 }}>
                <View style={styles.problemSubHeadingContainer}>
                  <NoteIcon />
                  <Text style={styles.problemSubHeading}>Note</Text>
                </View>
              </View>
              <View style={{ flex: 2 }}>
                <TextInput
                  placeholder="Type your note here..."
                  value={noteValue ?? ''}
                  onChangeText={setNoteValue}
                  // @ts-ignore
                  style={[styles.noteText, !renderMobile() ? { outlineStyle: 'none' } : {}]}
                  multiline={false}
                  onSubmitEditing={(text) => {
                    Keyboard.dismiss();
                    setSavingNote(true);
                    const newNote = text.nativeEvent.text;
                    updateNote(selectedProblem.id, newNote).then(() => {
                      setSavingNote(false);
                      // eslint-disable-next-line max-len
                      problems.filter((problem) => problem.id === selectedProblem.id)[0].notes = newNote;
                    });
                  }}
                  onBlur={(text) => {
                    Keyboard.dismiss();
                    setSavingNote(true);
                    const newNote = text.nativeEvent.text;
                    updateNote(selectedProblem.id, newNote).then(() => {
                      setSavingNote(false);
                      // eslint-disable-next-line max-len
                      problems.filter((problem) => problem.id === selectedProblem.id)[0].notes = newNote;
                    });
                  }}
                />
              </View>
            </View>
            {savingNote && (
              <View
                style={{
                  left: 0,
                  right: 0,
                  top: 0,
                  bottom: 0,
                  borderRadius: 8,
                  position: 'absolute',
                  justifyContent: 'center',
                  backgroundColor: '#00000020',
                }}
              >
                <ActivityIndicator color={Colors.light.hereworksLoading} size={'large'} />
              </View>
            )}
          </View>
          <View style={[Style.boxShadow, styles.problemButtonsMobile]}>
            {renderStatusButtons()}
          </View>
        </ScrollView>
      </KeyboardAvoidingView>
  );

  const renderList = () => (
    <View style={{ zIndex: -1, flex: 1 }}>
      {newReports && (
        <TouchableOpacity
          style={{
            backgroundColor: Colors.light.hereworksPaleBlue800,
            height: 80,
            marginLeft: 30,
            marginRight: 53,
            borderRadius: 8,
            alignItems: 'center',
            justifyContent: 'center',
          }}
          onPress={() => {
            setLoading(true);
            setNewReports(false);
          }}
        >
          <Text style={{ color: '#000', fontFamily: 'Roboto_400Regular', fontSize: 16 }}>
            There are new reports. Click here to load them.
          </Text>
        </TouchableOpacity>
      )}
      <ScrollView
        style={{ marginRight: !renderMobile() ? 13 : 0, zIndex: -1 }}
        contentContainerStyle={{ marginTop: 4 }}
        showsVerticalScrollIndicator={false}
        onScroll={(e) => {
          setContentOffset(e.nativeEvent.contentOffset);
        }}
        onContentSizeChange={(_, height) => {
          setContentSize(height);
        }}
        onLayout={(e) => {
          setScrollViewHeight(e.nativeEvent.layout.height);
        }}
        scrollEventThrottle={16}
        ref={(ref) => {
          scrollView.current = ref;
        }}
        refreshControl={
          <RefreshControl
            refreshing={loading}
            onRefresh={() => {
              setRefreshing(true);
              setLoading(true);
            }}
          />
        }
      >
        {problems.map((problem: any, i: number) => (
          <ReportListItem
            onPress={() => {
              Analytics.logEvent('button_press', {
                screen: 'Reports',
                name: 'Problem Selected',
              });
              setSelectedProblem(problem);
              setNoteValue(problem.notes);
              setModalVisible(!modalVisible);
              setCounter(counter + 1);
            }}
            key={i}
            isSelected={Platform.OS === 'web' && problem.id === selectedProblem?.id}
            style={[
              styles.shadow,
              styles.listProblemItem,
              { marginHorizontal: !renderMobile() ? 30 : 20 },
            ]}
          >
            <View
              style={[
                styles.priorityCircle,
                { backgroundColor: getPriorityColor(problem.priority) },
              ]}
            />
            {problem.status === 'inprogress' && (
              <ResumeIcon style={styles.checkmarkStyle} toggled={true} />
            )}
            {problem.status === 'paused' && (
              <View style={styles.pausedStyle}>
                <PauseIcon />
              </View>
            )}
            {problem.status !== 'inprogress' && problem.status !== 'paused' && (
              <CheckmarkIcon
                style={styles.checkmarkStyle}
                fill={problem.status === 'completed' ? Colors.light.hereworksCheckGreen : undefined}
                stroke={
                  problem.status === 'completed' ? Colors.light.hereworksCheckGreen : undefined
                }
                checkcolor={problem.status === 'completed' ? '#fff' : undefined}
              />
            )}
            <View style={{ flex: 1 }}>
              <Text numberOfLines={1} style={styles.problemTitle}>
                {problem.name}
              </Text>
              <View style={{ flexDirection: 'row' }}>
                <Text style={styles.problemArea}>
                  {`${problem['location-room'] ?? ''} | ${problem['location-floor'] ?? ''}`}
                </Text>
                {!renderMobile() && (
                  <Text style={styles.problemTime}>{Moment(problem.createdon).calendar()}</Text>
                )}
              </View>
            </View>
            <ProblemArrowIcon style={{ position: 'absolute', right: 20 }} />
          </ReportListItem>
        ))}
      </ScrollView>
    </View>
  );

  const datePicker = () => (
    <DatePickerModal
      locale="en"
      mode="range"
      visible={startDateVisible}
      startDate={range.start}
      endDate={range.end}
      uppercase={false}
      onDismiss={() => {
        setStartDateVisible(!startDateVisible);
      }}
      onConfirm={(date) => {
        setStartDate(Moment(date.startDate).toString());
        setEndDate(
          Moment(date.endDate ?? date.startDate)
            .endOf('day')
            .toString(),
        );
        onConfirm(date.startDate, date.endDate);
        setProblems(
          allProblems.filter(
            (e) => Moment(e.createdon) > Moment(date.startDate)
              && Moment(e.createdon) < Moment(date.endDate ?? date.startDate).endOf('day'),
          ),
        );
        setStartDateVisible(!startDateVisible);
      }}
    />
  );

  const renderFilter = () => (
    <Animatable.View
      style={[styles.filterShadow, styles.filterContainer]}
      animation={'fadeIn'}
      useNativeDriver={true}
    >
      <View style={styles.filterRow}>
        <View style={{ width: 91 }}>
          <Text style={styles.filterText}>priority</Text>
        </View>
        <View style={{ flexDirection: 'row', flex: 3 }}>
          <View style={{ flexDirection: 'row', flex: 1 }}>
            <TouchableOpacity
              onPress={() => {
                setLowPriorityFilter(!lowPriorityFilter);
                setMedPriorityFilter(false);
                setHighPriorityFilter(false);
                setCriticalPriorityFilter(false);
                setSelectedAssignee('');
                if (lowPriorityFilter !== true) {
                  setProblems(allProblems.filter((e) => e.priority === 'low'));
                } else {
                  setProblems(allProblems);
                }
              }}
            >
              <FilterBox style={{ marginRight: 6 }} toggled={lowPriorityFilter} />
            </TouchableOpacity>
            <Text style={styles.filterText}>low</Text>
          </View>
          <View style={{ flexDirection: 'row', flex: 1 }}>
            <TouchableOpacity
              onPress={() => {
                setMedPriorityFilter(!medPriorityFilter);
                setLowPriorityFilter(false);
                setHighPriorityFilter(false);
                setCriticalPriorityFilter(false);
                setSelectedAssignee('');
                if (medPriorityFilter !== true) {
                  setProblems(allProblems.filter((e) => e.priority === 'medium'));
                } else {
                  setProblems(allProblems);
                }
              }}
            >
              <FilterBox style={{ marginRight: 6 }} toggled={medPriorityFilter} />
            </TouchableOpacity>
            <Text style={styles.filterText}>med</Text>
          </View>
          <View style={{ flexDirection: 'row', flex: 1 }}>
            <TouchableOpacity
              onPress={() => {
                setHighPriorityFilter(!highPriorityFilter);
                setLowPriorityFilter(false);
                setMedPriorityFilter(false);
                setCriticalPriorityFilter(false);
                setSelectedAssignee('');
                if (highPriorityFilter !== true) {
                  setProblems(allProblems.filter((e) => e.priority === 'high'));
                } else {
                  setProblems(allProblems);
                }
              }}
            >
              <FilterBox style={{ marginRight: 6 }} toggled={highPriorityFilter} />
            </TouchableOpacity>
            <Text style={styles.filterText}>high</Text>
          </View>
        </View>
      </View>
      <View style={styles.filterRow}>
        <View style={{ width: 91 }} />
        <View style={{ flexDirection: 'row', flex: 2 }}>
          <TouchableOpacity
            onPress={() => {
              setCriticalPriorityFilter(!criticalPriorityFilter);
              setLowPriorityFilter(false);
              setMedPriorityFilter(false);
              setHighPriorityFilter(false);
              setSelectedAssignee('');
              if (criticalPriorityFilter !== true) {
                setProblems(allProblems.filter((e) => e.priority === 'critical'));
              } else {
                setProblems(allProblems);
              }
            }}
          >
            <FilterBox style={{ marginRight: 6 }} toggled={criticalPriorityFilter} />
          </TouchableOpacity>
          <Text style={styles.filterText}>critical</Text>
        </View>
      </View>
      <View style={styles.filterRow}>
        <View style={{ width: 91 }}>
          <Text style={styles.filterText}>status</Text>
        </View>
        <View style={{ flexDirection: 'row', flex: 3 }}>
          <View style={{ flexDirection: 'row', flex: 1 }}>
            <TouchableOpacity
              onPress={() => {
                setDoneFilter(!doneFilter);
                setWithoutStatusFilter(false);
                setInProgressFilter(false);
                setSelectedAssignee('');
                if (doneFilter !== true) {
                  setProblems(allProblems.filter((e) => e.status === 'completed'));
                } else {
                  setProblems(allProblems);
                }
              }}
            >
              <FilterBox style={{ marginRight: 6 }} toggled={doneFilter} />
            </TouchableOpacity>
            <Text style={styles.filterText}>done</Text>
          </View>
          <View style={{ flexDirection: 'row', flex: 2 }}>
            <TouchableOpacity
              onPress={() => {
                setInProgressFilter(!inProgressFilter);
                setWithoutStatusFilter(false);
                setDoneFilter(false);
                setSelectedAssignee('');
                if (inProgressFilter !== true) {
                  setProblems(allProblems.filter((e) => e.inProgress));
                } else {
                  setProblems(allProblems);
                }
              }}
            >
              <FilterBox style={{ marginRight: 6 }} toggled={inProgressFilter} />
            </TouchableOpacity>
            <Text style={styles.filterText}>in progress</Text>
          </View>
        </View>
      </View>
      <View style={styles.filterRow}>
        <View style={{ width: 91 }} />
        <View style={{ flexDirection: 'row', flex: 2 }}>
          <TouchableOpacity
            onPress={() => {
              setWithoutStatusFilter(!withoutStatusFilter);
              setInProgressFilter(false);
              setDoneFilter(false);
              setSelectedAssignee('');
              if (withoutStatusFilter !== true) {
                setProblems(
                  allProblems.filter((e) => e.inProgress === false && e.status === 'reported'),
                );
              } else {
                setProblems(allProblems);
              }
            }}
          >
            <FilterBox style={{ marginRight: 6 }} toggled={withoutStatusFilter} />
          </TouchableOpacity>
          <Text style={styles.filterText}>without status</Text>
        </View>
      </View>
      <View style={styles.filterRow}>
        <View style={{ width: 91, height: 36, justifyContent: 'center' }}>
          <Text style={styles.filterText}>assignee</Text>
        </View>
        <View style={{ flex: 3 }}>
          <TouchableOpacity
            style={styles.assigneeFilter}
            onPress={() => setAssigneeDropdown(!assigneeDropdown)}
          >
            <View style={{ flexDirection: 'row', flex: 2, alignItems: 'center' }}>
              {selectedAssignee === '' && (
                <Text style={styles.chooseAssigneeText}>Choose assignee</Text>
              )}
              {selectedAssignee !== '' && (
                <Text style={[styles.chooseAssigneeText, { color: 'black' }]}>
                  {selectedAssignee}
                </Text>
              )}
              <View style={{ flex: 1, alignItems: 'flex-end' }}>
                <FontAwesome
                  size={30}
                  style={{ color: Colors.light.hereworksPrimaryGrey800 }}
                  name={'angle-down'}
                />
              </View>
            </View>
          </TouchableOpacity>
          {assigneeDropdown && (
            <View style={{ width: '100%', backgroundColor: '#fff' }}>
              {allProblems
                .filter((value, i, arr) => {
                  const arrFind = arr.findIndex((t) => t.assignee === value.assignee);
                  return arrFind === i;
                })
                .map((problem: any, key) => (
                  <TouchableOpacity
                    style={{ marginLeft: 10, marginVertical: 10 }}
                    key={key}
                    onPress={() => {
                      resetFilters();
                      setProblems(allProblems.filter((e) => e.assignee === problem.assignee));
                      setSelectedAssignee(problem.assignee);
                      setAssigneeDropdown(false);
                    }}
                  >
                    <Text style={styles.filterText}>{problem.assignee}</Text>
                  </TouchableOpacity>
                ))}
            </View>
          )}
        </View>
      </View>
      <View>
        {!assigneeDropdown && (
          <View style={[styles.filterRow, { justifyContent: 'space-around' }]}>
            <TouchableOpacity
              style={styles.dateFilter}
              onPress={() => {
                setStartDateVisible(true);
              }}
            >
              {startDateVisible && datePicker()}
              {!startDateVisible && (
                <View
                  style={{
                    flexDirection: 'row',
                    flex: 2,
                    alignItems: 'center',
                  }}
                >
                  <Text style={styles.dateText}>{Moment(startDate).format('D/M/YYYY')}</Text>
                </View>
              )}
            </TouchableOpacity>
            <TouchableOpacity
              style={styles.dateFilter}
              onPress={() => {
                setEndDateVisible(true);
              }}
            >
              {endDateVisible && datePicker()}
              {!endDateVisible && (
                <View style={{ flexDirection: 'row', flex: 2, alignItems: 'center' }}>
                  <Text style={styles.dateText}>{Moment(endDate).format('D/M/YYYY')}</Text>
                </View>
              )}
            </TouchableOpacity>
          </View>
        )}
      </View>
    </Animatable.View>
  );

  const renderWebSearchAndFilter = () => (
    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
      <View style={styles.searchBox}>
        <SearchIcon style={{ marginLeft: 14 }} />
        <TextInput
          placeholder="Search tickets"
          // @ts-ignore
          style={[styles.searchText, !renderMobile() ? { outlineStyle: 'none' } : {}]}
          onChangeText={(text) => {
            setProblems(allProblems.filter((e) => e.name?.includes(text)));
          }}
        />
      </View>
      <View style={{ flex: 1, marginRight: 63, alignItems: 'flex-end' }}>
        <TouchableOpacity
          onPress={() => {
            setFilterOpen(!filterOpen);
          }}
        >
          {filterOpen ? <CloseFilterIcon /> : <FilterIcon />}
        </TouchableOpacity>
        {filterOpen && renderFilter()}
      </View>
    </View>
  );

  const renderMobileSearchAndFilter = () => (
    <View>
      <View style={{ flexDirection: 'row', alignItems: 'center', marginTop: 60 }}>
        <View style={{ flex: 1, flexDirection: 'row', marginRight: 20 }}>
          <Text style={styles.header}>Reports</Text>
          <View style={{ flex: 1, zIndex: 2 }}>
            <TouchableOpacity
              style={{ alignSelf: 'flex-end' }}
              onPress={() => {
                setFilterOpen(!filterOpen);
              }}
            >
              {filterOpen ? <CloseFilterIcon /> : <FilterIcon />}
            </TouchableOpacity>
          </View>
          {filterOpen && renderFilter()}
        </View>
      </View>
      <View style={styles.searchBoxMobile}>
        <SearchIcon style={{ marginLeft: 14 }} />
        <TextInput
          placeholder="Search tickets"
          placeholderTextColor={Colors.light.hereworksPrimaryGrey700}
          // @ts-ignore
          style={[styles.searchText, !renderMobile() ? { outlineStyle: 'none' } : {}]}
          onChangeText={(text) => {
            setProblems(allProblems.filter((e) => e.name?.includes(text)));
          }}
        />
      </View>
    </View>
  );

  const renderScrollBar = () => (
    <PanGestureHandler onGestureEvent={panGesture}>
      <View
        style={{
          position: 'absolute',
          right: 10,
          top: 115,
          marginBottom: 8,
          width: 3,
          bottom: 0,
          borderRadius: 1.5,
          backgroundColor: Colors.light.hereworksPaleBlue900,
        }}
      >
        <Animated.View
          style={{
            position: 'absolute',
            left: -1.5,
            top: `${Number(scrollPosPercent || 0).toFixed(0)}%`,
            marginBottom: 8,
            width: 6,
            height: scrollElementHeightPercentStr,
            borderRadius: 3,
            backgroundColor: Colors.light.hereworksDarkBlue,
          }}
        />
      </View>
    </PanGestureHandler>
  );

  const renderMoreOptions = () => (
    <View
      style={[
        styles.settingsModal,
        Platform.OS === 'android' ? { elevation: 50 } : {},
        renderMobile() ? { top: 105, right: 20 } : { top: 50, right: 20 },
      ]}
    >
      <TouchableOpacity
        style={styles.modalItem}
        onPress={() => {
          setMoreOptionsVisible(!moreOptionsVisible);
          Analytics.logEvent('button_press', {
            screen: 'Reports',
            name: 'Remind me in 1h',
          });
          Alert.alert('Remind feature coming soon!');
          // todo: remind me
        }}
      >
        <View style={{ width: 32, alignItems: 'center' }}>
          <ClockIcon />
        </View>
        <Text style={styles.modalText}>Remind me in 1h</Text>
      </TouchableOpacity>
      <TouchableOpacity
        style={styles.modalItem}
        onPress={() => {
          setMoreOptionsVisible(!moreOptionsVisible);
          updateIssue(selectedProblem.id, name, true).then((response) => {
            setLoading(true);
            if (response.errorText) {
              Alert.alert(response.errorText);
            }
            emitter?.emit('event.updatenotifications', {});
          });
        }}
      >
        <View style={{ width: 32, alignItems: 'center' }}>
          <CompleteIcon />
        </View>
        <Text style={styles.modalText}>Mark as complete</Text>
      </TouchableOpacity>
      <TouchableOpacity
        style={styles.modalItem}
        onPress={() => {
          setMoreOptionsVisible(!moreOptionsVisible);
          Analytics.logEvent('button_press', {
            screen: 'Reports',
            name: 'Delete report',
          });
          setShowSpinner(true);
          deleteIssue(selectedProblem.id).then((response) => {
            setLoading(true);
            setModalVisible(false);
            if (response.errorText) {
              Alert.alert(response.errorText);
            }
            emitter?.emit('event.updatenotifications', {});
          });
        }}
      >
        <View style={{ width: 32, alignItems: 'center' }}>
          <BinIcon />
        </View>
        <Text style={styles.modalText}>Delete report</Text>
      </TouchableOpacity>
    </View>
  );

  const renderModal = () => (
    <Modal
      animationIn={'slideInRight'}
      animationOut={'slideOutRight'}
      isVisible={modalVisible}
      swipeDirection={'right'}
      onSwipeComplete={() => setModalVisible(false)}
      statusBarTranslucent={false}
      // useNativeDriver={true}
      backdropColor={Colors.light.hereworksBackground}
      style={{ flex: 1, margin: 0 }}
    >
      <View style={{ flex: 1, backgroundColor: Colors.light.hereworksBackground }}>
        <Modal
          isVisible={loading || showSpinner}
          presentationStyle={'overFullScreen'}
          animationIn={'fadeIn'}
          animationOut={'fadeOut'}
          hasBackdrop={false}
          style={{ margin: 0 }}
        >
          <View style={{ flex: 1, justifyContent: 'center', backgroundColor: '#00000020' }}>
            <ActivityIndicator color={Colors.light.hereworksLoading} size={'large'} />
          </View>
        </Modal>
        <View style={styles.modalHeaderContainer}>
          <TouchableOpacity
            onPress={() => setModalVisible(!modalVisible)}
            style={{ marginRight: 10 }}
            hitSlop={{
              top: 30,
              bottom: 40,
              left: 50,
              right: 50,
            }}
          >
            <LeftArrow />
          </TouchableOpacity>
          <Text style={styles.modalProblemTitle} numberOfLines={1}>
            {selectedProblem?.name}
          </Text>
          <TouchableOpacity
            onPress={() => setMoreOptionsVisible(!moreOptionsVisible)}
            style={{ marginRight: 10 }}
            hitSlop={{
              top: 40,
              bottom: 40,
              left: 50,
              right: 50,
            }}
          >
            <EllipsisIcon />
          </TouchableOpacity>
          <Modal
            isVisible={moreOptionsVisible}
            style={{
              margin: 0,
            }}
            hasBackdrop={false}
            animationIn={'slideInRight'}
            animationOut={'slideOutRight'}
          >
            <TouchableOpacity
              style={{ backgroundColor: '#00000020', flex: 1 }}
              onPress={() => setMoreOptionsVisible(!moreOptionsVisible)}
            >
              {renderMoreOptions()}
            </TouchableOpacity>
          </Modal>
        </View>
        {renderMobile() ? renderSelectedProblemMobile() : renderSelectedProblemWeb()}
      </View>
    </Modal>
  );

  return (
    <View
      style={{
        flex: 2,
        backgroundColor: Colors.light.hereworksBackground,
        flexDirection: 'row',
      }}
    >
      {renderMobile() && renderModal()}
      <View style={{ flex: 1 }}>
        {!renderMobile() && renderWebSearchAndFilter()}
        {renderMobile() && renderMobileSearchAndFilter()}
        {contentSize > scrollViewHeight && !renderMobile() && renderScrollBar()}
        {renderList()}
      </View>
      {!renderMobile() && renderSelectedProblemWeb()}
      <Modal
        isVisible={(loading || showSpinner) && !refreshing}
        animationIn={'fadeIn'}
        animationOut={'fadeOut'}
        presentationStyle={'overFullScreen'}
        hasBackdrop={false}
        style={{ margin: 0 }}
      >
        <View style={{ flex: 1, justifyContent: 'center', backgroundColor: 'transparent' }}>
          <ActivityIndicator size={'large'} color={Colors.light.hereworksLoading} />
        </View>
      </Modal>
    </View>
  );
}

const styles = StyleSheet.create({
  searchBox: {
    margin: 30,
    height: 40,
    width: 240,
    borderRadius: 24,
    backgroundColor: Colors.light.hereworksBackground,
    marginRight: 12,
    borderColor: Colors.light.hereworksPaleBlue900,
    borderWidth: 1.5,
    flexDirection: 'row',
    alignItems: 'center',
  },
  searchBoxMobile: {
    marginHorizontal: 20,
    height: 40,
    borderRadius: 8,
    backgroundColor: Colors.light.hereworksBackground,
    marginTop: 13,
    borderColor: Colors.light.hereworksPaleBlue900,
    borderWidth: 1.5,
    flexDirection: 'row',
    alignItems: 'center',
    zIndex: -1,
  },
  searchText: {
    color: Colors.light.hereworksPrimaryGrey,
    marginLeft: 9,
  },
  noteText: {
    color: Colors.light.hereworksPrimaryGrey,
    fontFamily: 'Poppins_500Medium',
    fontSize: 16,
    flex: 1,
    backgroundColor: '#fff',
    borderRadius: 6,
    paddingLeft: 14,
    paddingTop: 12,
  },
  reporterText: {
    color: Colors.light.hereworksPrimaryGrey800,
    fontFamily: 'Poppins_500Medium',
    fontSize: 16,
    marginLeft: 6,
  },
  chooseAssigneeText: {
    color: Colors.light.hereworksPrimaryGrey800,
    fontFamily: 'Poppins_400Regular',
    fontSize: 14,
    marginLeft: 10,
  },
  dateText: {
    fontFamily: 'Poppins_400Regular',
    fontSize: 14,
    marginHorizontal: 30,
  },
  listProblemItem: {
    marginVertical: 6,
    height: 80,
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: 8,
  },
  problemTitle: {
    fontFamily: 'Poppins_600SemiBold',
    fontSize: 16,
    color: Colors.light.hereworksPrimaryGrey,
    marginLeft: 14,
    paddingRight: 38,
  },
  modalProblemTitle: {
    fontFamily: 'Roboto_500Medium',
    fontSize: 18,
    color: 'black',
    marginLeft: 14,
    paddingRight: 38,
    textAlign: 'center',
    flex: 1,
  },
  fullProblemTitle: {
    flex: 1,
    fontFamily: 'Poppins_600SemiBold',
    fontSize: 30,
    color: Colors.light.hereworksPrimaryGrey,
    marginTop: 30,
    marginBottom: 14,
  },
  problemArea: {
    fontFamily: 'Poppins_400Regular',
    fontSize: 14,
    color: Colors.light.hereworksPrimaryGrey,
    marginHorizontal: 14,
  },
  problemDetail: {
    fontFamily: 'Roboto_400Regular',
    fontSize: 16,
    color: Colors.light.hereworksPrimaryGrey900,
    marginTop: 10,
  },
  problemDetailMobile: {
    fontFamily: 'Roboto_400Regular',
    fontSize: 16,
    color: Colors.light.hereworksPrimaryGrey900,
  },
  problemTime: {
    fontFamily: 'Poppins_400Regular',
    fontSize: 14,
    color: Colors.light.hereworksPrimaryGrey,
    marginLeft: 10,
    opacity: 0.5,
  },
  checkmarkStyle: {
    marginLeft: 20,
  },
  pausedStyle: {
    marginLeft: 20,
    width: 40,
    height: 40,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 20,
    backgroundColor: Colors.light.hereworksPaleBlue,
  },
  selectedProblemContainer: {
    flex: 1,
    backgroundColor: '#fff',
    borderRadius: 8,
  },
  selectedProblemContainerMobile: {
    flex: 1,
    backgroundColor: Colors.light.hereworksBackground,
  },
  nothingHereTitle: {
    fontFamily: 'Poppins_600SemiBold',
    fontSize: 20,
    color: Colors.light.hereworksDarkBlue800,
  },
  nothingHereDescription: {
    fontFamily: 'Poppins_400Regular',
    fontSize: 16,
    color: Colors.light.hereworksDarkBlue800,
    textAlign: 'center',
    marginHorizontal: 150,
  },
  priorityCircle: {
    position: 'absolute',
    top: 9,
    left: 9,
    width: 14,
    height: 14,
    borderRadius: 7,
  },
  shadow: {
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.05,
    shadowRadius: 20,
    shadowColor: 'black',
    elevation: 5,
  },
  filterShadow: {
    shadowOffset: {
      width: 6,
      height: 6,
    },
    shadowOpacity: 0.2,
    shadowRadius: 20,
    shadowColor: 'black',
    elevation: 5,
  },
  problemSubHeading: {
    flex: 1,
    fontFamily: 'Poppins_600SemiBold',
    fontSize: 16,
    color: Colors.light.hereworksPrimaryGrey,
    marginLeft: 8,
  },
  problemSubHeadingContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  problemSubGroup: {
    flex: 2,
    flexDirection: 'row',
    marginHorizontal: 40,
  },
  problemSingleGroup: {
    flex: 1,
    alignItems: 'flex-start',
    marginRight: 20,
    marginBottom: 20,
    borderRadius: 8,
    padding: 16,
    backgroundColor: Colors.light.hereworksBackground,
  },
  problemSubGroupMobile: {
    height: 100,
    flexDirection: 'row',
    marginHorizontal: 20,
    marginVertical: 6,
    paddingLeft: 20,
    backgroundColor: 'white',
    alignItems: 'center',
    borderRadius: 8,
  },
  problemDivider: {
    height: 2,
    marginTop: 14,
    marginRight: 40,
    marginLeft: 40,
    marginBottom: 50,
    zIndex: -1,
    backgroundColor: Colors.light.hereworksPaleBlue900,
  },
  problemHeaderContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginHorizontal: 40,
    justifyContent: 'center',
  },
  problemButtons: {
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'center',
    flexDirection: 'row',
  },
  problemButtonsMobile: {
    alignSelf: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    height: 64,
    borderRadius: 32,
    backgroundColor: 'white',
    marginVertical: 10,
  },
  filterContainer: {
    position: 'absolute',
    top: 50,
    right: 0,
    width: 350,
    height: 263,
    backgroundColor: '#fff',
    borderRadius: 10,
    zIndex: 2,
  },
  filterText: {
    fontFamily: 'Poppins_400Regular',
    fontSize: 14,
  },
  filterRow: {
    marginTop: 13,
    marginHorizontal: 30,
    flexDirection: 'row',
  },
  assigneeFilter: {
    flexDirection: 'row',
    backgroundColor: Colors.light.hereworksBackground,
    height: 36,
    width: '100%',
    alignItems: 'center',
    borderRadius: 6,
  },
  dateFilter: {
    flexDirection: 'row',
    backgroundColor: Colors.light.hereworksBackground,
    height: 36,
    flex: 1,
    alignItems: 'center',
    borderRadius: 6,
  },
  assigneeAvatarContainer: {
    width: 30,
    height: 30,
    borderRadius: 15,
    overflow: 'hidden',
  },
  assigneeAvatar: {
    width: 30,
    height: 30,
    borderRadius: 15,
    overflow: 'hidden',
  },
  reporterAvatar: {
    width: 30,
    height: 30,
    borderRadius: 15,
    overflow: 'hidden',
  },
  header: {
    fontSize: 32,
    marginLeft: 20,
    fontFamily: 'Poppins_600SemiBold',
  },
  modalHeaderContainer: {
    flexDirection: 'row',
    height: 50,
    marginTop: 50,
    alignItems: 'center',
    marginHorizontal: 20,
    backgroundColor: Colors.light.hereworksBackground,
  },
  resumeButtonMobile: {
    marginRight: 16,
    marginLeft: 12,
    flexDirection: 'row',
    backgroundColor: Colors.light.hereworksPaleBlue800,
    height: 40,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 20,
    paddingLeft: 24,
    paddingRight: 29,
  },
  pauseButtonMobile: {
    marginRight: 16,
    marginLeft: 12,
    flexDirection: 'row',
    backgroundColor: Colors.light.hereworksYellow700,
    height: 40,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 20,
    paddingLeft: 24,
    paddingRight: 29,
  },
  resumeButtonText: {
    fontFamily: 'Roboto_500Medium',
    fontSize: 16,
    color: '#2173C4',
    marginLeft: 15,
  },
  pauseButtonText: {
    fontFamily: 'Roboto_500Medium',
    fontSize: 16,
    color: Colors.light.hereworksPrimaryGrey,
    marginLeft: 15,
  },
  resolveText: {
    fontFamily: 'Roboto_500Medium',
    fontSize: 16,
    color: Colors.light.hereworksPrimaryGrey,
    marginLeft: 10,
    marginRight: 15,
  },
  modalText: {
    fontFamily: 'Roboto_500Medium',
    fontSize: 10,
    color: Colors.light.hereworksPrimaryGrey800,
  },
  modalItem: {
    flex: 1,
    alignItems: 'center',
    flexDirection: 'row',
    zIndex: 1,
  },
  settingsModal: {
    height: 104,
    width: 120,
    zIndex: 1,
    backgroundColor: '#fff',
    borderRadius: 8,
    overflow: 'hidden',
    position: 'absolute',
  },
});
