import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const PrinterIcon = (props: SvgProps) => (
  <Svg
    width={60}
    height={60}
    fill="none"
    {...props}
  >
    <Path
      d="M48.047 25.547h-2.344a.47.47 0 0 0-.469.469v2.343c0 .258.211.47.47.47h2.343a.47.47 0 0 0 .469-.47v-2.343a.47.47 0 0 0-.47-.47Zm1.875-6.094H42.89V7.031a.47.47 0 0 0-.47-.468H17.579a.47.47 0 0 0-.469.468v12.422h-7.03a4.686 4.686 0 0 0-4.688 4.688v19.218c0 1.038.838 1.875 1.875 1.875h9.843v7.735c0 .258.211.468.47.468h24.843a.47.47 0 0 0 .469-.468v-7.735h9.843a1.873 1.873 0 0 0 1.875-1.875V24.141a4.686 4.686 0 0 0-4.687-4.688Zm-28.828-8.906h17.812v8.906H21.094v-8.906Zm17.812 38.906H21.094V33.281h17.812v16.172Zm11.719-8.203h-7.734V29.297H17.109V41.25H9.375V24.14c0-.386.316-.703.703-.703h39.844c.387 0 .703.317.703.704V41.25Z"
      fill="#003A70"
    />
  </Svg>
);

export default PrinterIcon;
