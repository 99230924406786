import React, { useState } from 'react';
import {
  Alert, Platform, StyleSheet, Text, TouchableOpacity, View,
} from 'react-native';

import { useNavigation } from '@react-navigation/native';
import Moment from 'moment';
import Colors from '../constants/Colors';
import Style from '../constants/Style';
import BellIcon from './BellIcon';
import BinIcon from './BinIcon';
import ClockIcon from './ClockIcon';
import CompleteIcon from './CompleteIcon';
import EllipsisIcon from './EllipsisIcon';

export default function Notification({
  isNew,
  title,
  priority,
  id,
  createdon,
  markComplete,
  deleteReport,
}: {
  isNew: boolean;
  title: string;
  priority: string;
  id: string;
  createdon: string;
  markComplete: () => void;
  deleteReport: () => void;
}) {
  const [modalVisible, setModalVisible] = useState(false);
  const [height, setHeight] = useState(90);

  const navigation = useNavigation();

  function getPriorityColor() {
    if (priority === 'low') {
      return Colors.light.hereworksLightBlue;
    }
    if (priority === 'medium') {
      return Colors.light.hereworksMediumYellow;
    }

    if (priority === 'high') {
      return Colors.light.hereworksUrgentRed;
    }

    return 'transparent';
  }

  return (
    <TouchableOpacity
      style={{
        height,
      }}
      // @ts-ignore
      onPress={() => navigation.navigate('Reports', { reportId: id })}
    >
      <View
        style={[
          styles.notificationCard,
          isNew ? { backgroundColor: Colors.light.hereworksPaleBlue } : { backgroundColor: '#fff' },
        ]}
      >
        <View style={styles.bellIconContainer}>
          <BellIcon color={Colors.light.hereworksDarkBlue} />
          <View style={[styles.priorityIndicator, { backgroundColor: getPriorityColor() }]} />
        </View>
        <View style={{ flex: 1 }}>
          <Text style={styles.descriptionText}>{title}</Text>
          <Text style={styles.timeText}>{Moment(createdon).fromNow()}</Text>
        </View>
        <View style={styles.ellipsis}>
          <TouchableOpacity
            hitSlop={{
              top: 20,
              bottom: 20,
              left: 50,
              right: 50,
            }}
            onPress={() => {
              setModalVisible(!modalVisible);
              setHeight(modalVisible ? 90 : 200);
            }}
          >
            <EllipsisIcon fill={Colors.light.hereworksDarkBlue700} />
          </TouchableOpacity>
        </View>
      </View>
      {modalVisible && (
        <View
          style={[
            {
              position: 'absolute',
              right: 20,
              top: 90,
              height: 104,
              width: 120,
              zIndex: 1,
            },
            Style.boxShadow,
          ]}
        >
          <View style={[styles.settingsModal, Platform.OS === 'android' ? { elevation: 50 } : {}]}>
            <TouchableOpacity
              style={styles.modalItem}
              onPress={() => {
                setModalVisible(!modalVisible);
                setHeight(modalVisible ? 90 : 200);
                Alert.alert('Remind feature coming soon!');
              }}
            >
              <View style={{ width: 32, alignItems: 'center' }}>
                <ClockIcon />
              </View>
              <Text style={styles.modalText}>Remind me in 1h</Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={styles.modalItem}
              onPress={() => {
                markComplete();
                setHeight(modalVisible ? 90 : 200);
              }}
            >
              <View style={{ width: 32, alignItems: 'center' }}>
                <CompleteIcon />
              </View>
              <Text style={styles.modalText}>Mark as complete</Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={styles.modalItem}
              onPress={() => {
                deleteReport();
                setHeight(modalVisible ? 90 : 200);
              }}
            >
              <View style={{ width: 32, alignItems: 'center' }}>
                <BinIcon />
              </View>
              <Text style={styles.modalText}>Delete report</Text>
            </TouchableOpacity>
          </View>
        </View>
      )}
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  notificationCard: {
    flexDirection: 'row',
    alignItems: 'center',
    height: 80,
    marginHorizontal: 20,
    marginVertical: 5,
    borderRadius: 8,
  },
  bellIconContainer: {
    marginLeft: 20,
    marginRight: 15,
    width: 40,
    height: 40,
    borderRadius: 20,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Colors.light.hereworksPaleBlue,
  },
  priorityIndicator: {
    position: 'absolute',
    top: 2,
    right: 2,
    width: 8,
    height: 8,
    borderRadius: 4,
  },
  descriptionText: {
    color: 'black',
    fontFamily: 'Roboto_400Regular',
    fontSize: 16,
  },
  timeText: {
    fontFamily: 'Roboto_400Regular',
    color: Colors.light.hereworksPrimaryGrey700,
    fontSize: 14,
  },
  ellipsis: {
    position: 'absolute',
    bottom: 12,
    right: 12,
    height: 5,
  },
  settingsModal: {
    height: 104,
    zIndex: 1,
    right: 0,
    width: '100%',
    backgroundColor: '#fff',
    borderRadius: 8,
    overflow: 'hidden',
  },
  modalText: {
    fontFamily: 'Roboto_500Medium',
    fontSize: 10,
    color: Colors.light.hereworksPrimaryGrey800,
  },
  modalItem: {
    flex: 1,
    alignItems: 'center',
    flexDirection: 'row',
    zIndex: 1,
  },
});
