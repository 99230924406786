export default {
  boxShadow: {
    shadowOffset: {
      width: 0,
      height: 12,
    },
    shadowOpacity: 0.1,
    shadowRadius: 40,
    shadowColor: 'black',
    elevation: 5,
  },
  circleShadow: {
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.6,
    shadowRadius: 16,
    elevation: 5,
  },
  barShadow: {
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.25,
    shadowRadius: 16,
    borderRadius: 8,
    shadowColor: 'rgba(0, 31, 49, 1)',
    elevation: 5,
  },
};
