/* eslint-disable operator-linebreak */
import React, { useState } from 'react';
import {
  StyleSheet,
  Image,
  Dimensions,
  Platform,
  Animated,
  TouchableOpacity,
  Easing,
  ScrollView,
  Alert,
} from 'react-native';

import {
  Circle, Defs, G, LinearGradient, Rect, Stop, Svg,
} from 'react-native-svg';
import { useCountUp } from 'use-count-up';
import { LinearGradient as ExpoLinearGradient } from 'expo-linear-gradient';
import * as Notifications from 'expo-notifications';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Auth } from 'aws-amplify';
import Modal from 'react-native-modal';
import { Text, View } from '../components/Themed';
import { RootTabScreenProps } from '../types';
import Colors from '../constants/Colors';
import Style from '../constants/Style';
import BoltIcon from '../components/BoltIcon';
import SunIcon from '../components/SunIcon';
import LeftArrow from '../components/LeftArrow';
import getHappyScore from '../requests/GetHappyScore';
import getName from '../requests/GetName';
import getHappyScoreGroupCounts from '../requests/GetHappyScoreGroupCounts';
import LeftArrowSmall from '../components/LeftArrowSmall';
import HappyScoreBar from '../components/HappyScoreBar';
import SmileIcon from '../components/SmileIcon';
import CoffeeIcon from '../components/CoffeeIcon';
import MonitorIcon from '../components/MonitorIcon';
import CheckCircle from '../components/CheckCircle';
import ChairIconDesktop from '../components/ChairIconDesktop';
import GridIcon from '../components/GridIcon';
import HappyGroupsGraph from '../components/HappyGroupsGraph';
import ImprovedBasicGraph from '../components/ImprovedBasicGraph';
import DownArrow from '../components/DownArrow';
import UpArrow from '../components/UpArrow';
import VictoryAreaChart from '../components/VictoryAreaChart';
import HappyDetailsGraph from '../components/HappyDetailsGraph';
import storePushToken from '../requests/StorePushToken';
import EllipsisIcon from '../components/EllipsisIcon';
import LogoutIcon from '../components/LogoutIcon';

const HappyBuilding = require('../assets/images/happy_building.png');
const MediumBuilding = require('../assets/images/medium_building.png');
const SadBuilding = require('../assets/images/sad_building.png');

// eslint-disable-next-line no-unused-vars
export default function HomeScreen({ navigation }: RootTabScreenProps<'Home'>) {
  // console.log('Home');

  const [happyScore, setHappyScore] = useState(0);
  const [name, setName] = useState('');

  const { value, reset } = useCountUp({
    isCounting: true,
    end: happyScore,
    duration: 6,
    easing: 'easeOutCubic',
  });

  React.useEffect(() => {
    getHappyScore().then((s) => {
      if (s?.current_happy_percentage) {
        const newScore = parseInt(s?.current_happy_percentage, 10);
        setHappyScore(newScore);
      } else {
        setHappyScore(85);
      }

      // console.log(`current_happy_percentage: ${s?.current_happy_percentage}`);
    });
  }, []);

  React.useEffect(() => {
    getHappyScoreGroupCounts().then((gc) => {
      setGroupCounts(gc);
    });
  }, []);

  React.useEffect(() => {
    getName().then((newName) => {
      // console.log(`name is: ${newName}`);
      const firstName = newName?.substring(0, newName.indexOf(' '));
      setName(firstName === '' ? newName : firstName);
      AsyncStorage.setItem('name', newName);
    });
  }, []);

  React.useEffect(() => {
    if (happyScore > 0) {
      resetAnimation();
    }
  }, [happyScore]);

  const window = Dimensions.get('window');
  const [dimensions] = React.useState({ window });

  const lowGradient = ['#BBC5CA', '#ECF0F2'];
  const mediumGradient = ['#EDDDBE', '#EBE9E4'];
  const highGradient = ['#BCE7F5', '#E6F3FA'];

  const lowDesktopGradient = [
    Colors.light.hereworksBackground,
    '#BBC5CA',
    Colors.light.hereworksBackground,
  ];
  const mediumDesktopGradient = [
    Colors.light.hereworksBackground,
    '#EDDDBE',
    Colors.light.hereworksBackground,
  ];
  const highDesktopGradient = [
    Colors.light.hereworksBackground,
    '#BCE7F5',
    Colors.light.hereworksBackground,
  ];

  const lowCircleGradient = ['#E84498', '#D6006D'];
  const mediumCircleGradient = ['#FFC85F', '#F4A000'];
  const highCircleGradient = ['#85E1CF', '#2BC3A5'];

  const [fillValue] = useState(new Animated.Value(2 * Math.PI * 66));
  const [rectValue] = useState(new Animated.Value(0));
  const [offsetValue] = useState(new Animated.Value(0));
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedMetric, setSelectedMetric] = useState('Electricity');
  const [selectedMetricScore, setSelectedMetricScore] = useState(0);
  const [yesterdayDiff, setYesterdayDiff] = useState(0);
  const [groupCounts, setGroupCounts] = useState([]);
  const [happyColor, setHappyColor] = useState(Colors.light.hereworksPink);
  const [gradient, setGradient] = useState(lowGradient);
  const [desktopGradient, setDesktopGradient] = useState(lowDesktopGradient);
  const [circleGradient, setCircleGradient] = useState(lowCircleGradient);
  const [buildingImage, setBuildingImage] = useState(SadBuilding);
  const [scrollViewWidth, setScrollViewWidth] = useState(0);
  const [logoutButtonVisible, setLogoutButtonVisible] = useState(false);
  const [description, setDescription] = useState('');

  const AnimatedCircle = Animated.createAnimatedComponent(Circle);
  const AnimatedBar = Animated.createAnimatedComponent(Rect);

  React.useEffect(() => {
    const currentValue = parseInt(value?.toString() ?? '', 10);
    // console.log('value: ' + currentValue);
    if (currentValue < 50) {
      setHappyColor(Colors.light.hereworksPink);
      setGradient(lowGradient);
      setCircleGradient(lowCircleGradient);
      setBuildingImage(SadBuilding);
      setDesktopGradient(lowDesktopGradient);
    } else if (currentValue < 80) {
      setHappyColor(Colors.light.hereworksOrange);
      setGradient(mediumGradient);
      setCircleGradient(mediumCircleGradient);
      setBuildingImage(MediumBuilding);
      setDesktopGradient(mediumDesktopGradient);
    } else {
      setHappyColor(Colors.light.hereworksExtraDarkTeal);
      setGradient(highGradient);
      setCircleGradient(highCircleGradient);
      setBuildingImage(HappyBuilding);
      setDesktopGradient(highDesktopGradient);
    }
  }, [value]);

  React.useEffect(() => {
    Animated.parallel([
      Animated.timing(offsetValue, {
        toValue: 2 * Math.PI * 62,
        duration: 6000,
        easing: Easing.bezier(0, 0, 0.2, 1),
        useNativeDriver: true,
      }),
      Animated.timing(fillValue, {
        toValue: 2 * Math.PI * 66 - (2 * Math.PI * 62 * happyScore) / 117,
        duration: 6000,
        useNativeDriver: true,
      }),
      Animated.timing(rectValue, {
        toValue: 8.55 * happyScore,
        duration: 6000,
        useNativeDriver: true,
      }),
    ]).start();
  }, []);

  async function getPushNotificationsToken() {
    if (Platform.OS !== 'web') {
      const { status: existingStatus } = await Notifications.getPermissionsAsync();
      let finalStatus = existingStatus;
      if (existingStatus !== 'granted') {
        const { status } = await Notifications.requestPermissionsAsync();
        finalStatus = status;
      }
      if (finalStatus !== 'granted') {
        Alert.alert('Failed to get push token for push notification!');
        return;
      }
      Notifications.setNotificationHandler({
        handleNotification: async () => ({
          shouldShowAlert: true,
          shouldPlaySound: false,
          shouldSetBadge: false,
        }),
        // handleError: async (id, error) => console.log(`error: ${JSON.stringify(error)}`),
      });
      Notifications.addNotificationReceivedListener(() => {
        // console.log(n);
      });
      Notifications.addNotificationResponseReceivedListener(() => {
        navigation.navigate('Notifications');
      });
      const token = (await Notifications.getExpoPushTokenAsync()).data;
      if (token) {
        const deviceId = token;
        storePushToken(token, deviceId);
        // console.log('Your Push Notification Token is:', token);
      } else {
        // console.log('Failed', 'No token received');
      }

      if (Platform.OS === 'android') {
        Notifications.setNotificationChannelAsync('default', {
          name: 'default',
          importance: Notifications.AndroidImportance.MAX,
          vibrationPattern: [0, 250, 250, 250],
          lightColor: '#FF231F7C',
        });
      }
    }
  }

  React.useEffect(() => {
    getPushNotificationsToken();
  }, []);

  const resetAnimation = () => {
    reset();
    Animated.parallel([
      Animated.sequence([
        Animated.timing(fillValue, {
          toValue: 2 * Math.PI * 66,
          duration: 200,
          useNativeDriver: true,
        }),
        Animated.timing(fillValue, {
          toValue: 2 * Math.PI * 66 - (2 * Math.PI * 62 * happyScore) / 117,
          duration: 5000,
          easing: Easing.bezier(0, 0, 0.2, 1),
          useNativeDriver: true,
        }),
      ]),
      Animated.timing(rectValue, {
        toValue: 8.55 * happyScore,
        duration: 6000,
        useNativeDriver: true,
      }),
    ]).start();
  };

  function getHappyColor(score: number) {
    if (score < 50) {
      return Colors.light.hereworksPink;
    }

    if (score < 80) {
      return Colors.light.hereworksOrange;
    }

    return Colors.light.hereworksExtraDarkTeal;
  }

  const scrollView = React.useRef<null | ScrollView>(null);

  const renderGroupScore = (
    title: string,
    score: number,
    diff: number,
    categoryDescription: string,
    icon: any,
  ) => (
    <TouchableOpacity
      style={[styles.happyItem, styles.boxShadow]}
      onPress={() => openModal(title, score, diff, categoryDescription)}
    >
      <Text style={styles.happyItemText} numberOfLines={1}>
        {title}
      </Text>
      <View style={styles.happyItemScore}>
        {icon}
        <Text style={[styles.happyItemScoreText, { color: getHappyColor(score) }]}>
          {score.toFixed()}
        </Text>
        {yesterdayDiff < 0 && (
          <View style={styles.arrow}>
            <DownArrow />
          </View>
        )}
        {yesterdayDiff > 0 && (
          <View style={styles.arrow}>
            <UpArrow />
          </View>
        )}
      </View>
    </TouchableOpacity>
  );

  const renderBar = (blur: boolean = false) => (
    <Svg
      // @ts-ignore
      onClick={() => resetAnimation()}
      onPress={() => resetAnimation()}
      height={58}
      fill="none"
      // @ts-ignore
      style={[
        {
          position: 'absolute',
          top: 7,
          alignSelf: 'flex-start',
          flex: 1,
          width: '100%',
        },
        // @ts-ignore
        blur ? { filter: 'blur(4px)' } : {},
      ]}
    >
      <G>
        <AnimatedBar x={8} y={21} width={`${value}%`} height={16} rx={6} fill="url(#b)" />
      </G>
      <Defs>
        <LinearGradient
          id="b"
          x1={-19.419}
          y1={38.418}
          x2={-19.419}
          y2={20.097}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor={circleGradient[0]} />
          <Stop offset={1} stopColor={circleGradient[1]} />
        </LinearGradient>
      </Defs>
    </Svg>
  );

  const renderCircle = (fill: Animated.Value, blur: boolean = false) => (
    <Svg
      // @ts-ignore
      onClick={() => resetAnimation()}
      onPress={() => resetAnimation()}
      style={[
        styles.innerCircle,
        Platform.OS === 'ios'
          ? [Style.circleShadow, { shadowColor: happyColor }]
          : [{ shadowColor: happyColor }],
        // @ts-ignore
        Platform.OS === 'web' && blur ? { filter: 'blur(4px)' } : {},
      ]}
    >
      <Defs>
        <LinearGradient id="grad-1" x1="5" y1="0" x2="135" y2="0" gradientUnits="userSpaceOnUse">
          <Stop offset="0" stopColor={circleGradient[1]} stopOpacity="1" />
          <Stop offset="1" stopColor={circleGradient[0]} />
        </LinearGradient>
      </Defs>
      <AnimatedCircle
        cx="30"
        cy="-120"
        r={58}
        stroke={'url(#grad-1)'}
        fill="transparent"
        strokeWidth="8"
        strokeDasharray={2 * Math.PI * 62}
        strokeDashoffset={fillValue}
        rotation={120}
        originX="0"
        originY="0"
        strokeLinecap="round"
      />
    </Svg>
  );

  const groupCountIcon = (iconName: string, score: number) => {
    switch (iconName) {
      case 'sun':
        return <SunIcon color={getHappyColor(score)} />;
      case 'thermometer':
        return <CoffeeIcon color={getHappyColor(score)} />;
      case 'bolt':
        return <BoltIcon color={getHappyColor(score)} />;
      case 'smile':
        return <SmileIcon color={getHappyColor(score)} />;
      case 'users':
        return <GridIcon color={getHappyColor(score)} />;
      case 'laptop':
        return <MonitorIcon color={getHappyColor(score)} />;
      case 'water':
        return <CheckCircle color={getHappyColor(score)} />;
      case 'building':
        return <ChairIconDesktop color={getHappyColor(score)} />;
      default:
        return null;
    }
  };

  const openModal = (title: string, score: number, diff: number, categoryDescription: string) => {
    setModalVisible(true);
    setSelectedMetric(title);
    setSelectedMetricScore(score);
    setYesterdayDiff(diff);
    setDescription(categoryDescription);
    // console.log('openModal');
  };

  if (Platform.OS === 'web' && dimensions.window?.width > 500) {
    const scrollToStart = () => {
      // console.log(value);
      scrollView.current?.scrollTo({ x: 0 });
    };

    const scrollToEnd = () => {
      // console.log(value);
      scrollView.current?.scrollToEnd();
    };

    return (
      <View
        style={{
          flex: 1,
          backgroundColor: Colors.light.hereworksBackground,
        }}
      >
        <View
          style={{
            borderRadius: 24,
            overflow: 'hidden',
            backgroundColor: 'transparent',
          }}
        >
          <View
            style={[
              styles.desktopModalContainer,
              Style.boxShadow,
              {
                height: modalVisible ? '100%' : 0,
                flex: modalVisible ? 1 : undefined,
              },
            ]}
          >
            <View style={styles.modalHeader}>
              <TouchableOpacity
                onPress={() => setModalVisible(!modalVisible)}
                style={{ alignSelf: 'flex-start' }}
              >
                <LeftArrow style={{ alignSelf: 'flex-start', margin: 20 }} />
              </TouchableOpacity>
              <Text style={styles.selectedMetric}>{selectedMetric}</Text>
            </View>
            <View
              style={{
                flex: 1,
                backgroundColor: Colors.light.hereworksBackground,
              }}
            >
              <View style={styles.modalScoreContainer}>
                <Text style={[styles.modalScore, { color: getHappyColor(selectedMetricScore) }]}>
                  {selectedMetricScore}{' '}
                </Text>
                <Text style={styles.modalScoreMax}>/100</Text>
              </View>
              <Text
                style={[
                  styles.modalScoreImprovement,
                  { color: getHappyColor(selectedMetricScore) },
                ]}
              >
                {yesterdayDiff >= 0 ? '+' : ''}
                {yesterdayDiff}%
              </Text>
              <View style={[styles.aboutContainer, Style.boxShadow]}>
                <Text style={styles.heading}>About</Text>
                <Text style={styles.body}>{description}</Text>
              </View>
              {/* <View style={[styles.requestsContainer, Style.boxShadow]}>
                <Text style={styles.heading}>Occupants requests (2)</Text>
                <Text style={styles.body}>
                  Lorem ipsum dolor sit amet, cte adipiscing elit. Aliquam vel dapibu.
                </Text>
              </View> */}
            </View>
          </View>
          <ExpoLinearGradient
            colors={desktopGradient}
            locations={[0, 0.1, 1]}
            // @ts-ignore
            style={[styles.desktopLinearGradient]}
          >
            <ScrollView
              horizontal={true}
              style={styles.desktopScrollView}
              showsHorizontalScrollIndicator={false}
              ref={(ref) => {
                scrollView.current = ref;
              }}
              onContentSizeChange={(width) => {
                setScrollViewWidth(width);
                // console.log(`scrollView${width}`);
              }}
            >
              {groupCounts.length > 0 &&
                groupCounts.map((groupCount: any) => (
                  <View style={{ backgroundColor: 'transparent' }} key={groupCount.id}>
                    {renderGroupScore(
                      groupCount.name,
                      groupCount.percentage,
                      groupCount.today_vs_yesterday_diff,
                      groupCount.description,
                      groupCountIcon(groupCount.icon, groupCount.percentage),
                    )}
                  </View>
                ))}
              {/* For Local Testing */}
              {/* {renderGroupScore('Electricity', 10, -1, <BoltIcon color={happyColor} />)}
              {renderGroupScore('External Environment', 12, 1, <SunIcon color={happyColor} />)}
              {renderGroupScore('Internal Environment', 12, 0, <SunIcon color={happyColor} />)} */}
            </ScrollView>
            {scrollViewWidth > (dimensions.window?.width ?? 0) - 330 && (
              <>
                <TouchableOpacity
                  style={[Style.barShadow, styles.scrollArrows, { alignSelf: 'flex-start' }]}
                  onPress={scrollToStart}
                >
                  <LeftArrowSmall style={{ alignSelf: 'center', transform: [{ scale: 1 }] }} />
                </TouchableOpacity>
                <TouchableOpacity
                  style={[
                    Style.barShadow,
                    styles.scrollArrows,
                    { alignSelf: 'flex-end', marginRight: 10 },
                  ]}
                  onPress={scrollToEnd}
                >
                  <LeftArrowSmall
                    style={{
                      alignSelf: 'center',
                      transform: [{ rotateZ: '180deg' }],
                    }}
                  />
                </TouchableOpacity>
              </>
            )}
            <View style={styles.happyScoreDesktopWrapper}>
              <Image source={buildingImage} style={styles.buildingImageDesktop} />
              <View
                style={{
                  marginLeft: 20,
                  backgroundColor: 'transparent',
                  flex: 1,
                  height: 120,
                  justifyContent: 'flex-end',
                }}
              >
                <View
                  style={{
                    flexDirection: 'row',
                    backgroundColor: 'transparent',
                  }}
                >
                  <View style={{ flex: 1, backgroundColor: 'transparent' }}>
                    <Text style={styles.nameDesktop}>Hi {name}!</Text>
                    <Text style={styles.descriptionDesktop}>We're in a happy place today</Text>
                  </View>
                  <Text style={[styles.happyScoreDesktop, { color: happyColor }]}>{value}</Text>
                </View>
                <View style={{ backgroundColor: 'transparent' }}>
                  <HappyScoreBar />
                  {renderBar(true)}
                  {renderBar()}
                </View>
              </View>
              <View
                style={{
                  marginLeft: 44,
                  marginRight: 7,
                  width: 450,
                  backgroundColor: 'transparent',
                }}
              >
                <ImprovedBasicGraph />
              </View>
            </View>
          </ExpoLinearGradient>
        </View>
        <View style={styles.containerGroupsGraph}>
          <View style={styles.lowergraphCard}>
            <HappyGroupsGraph />
          </View>
        </View>
      </View>
    );
  }

  return (
    <View style={styles.container}>
      <TouchableOpacity
        style={{
          position: 'absolute',
          right: 24,
          top: 60,
          zIndex: 5,
          alignItems: 'flex-end',
        }}
        hitSlop={{
          top: 20,
          bottom: 20,
          left: 50,
          right: 50,
        }}
        onPress={() => {
          setLogoutButtonVisible(!logoutButtonVisible);
        }}
      >
        <EllipsisIcon />
        {logoutButtonVisible && (
          <TouchableOpacity
            style={{
              backgroundColor: '#fff',
              width: 120,
              height: 36,
              marginTop: 7,
              borderRadius: 6,
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            onPress={() => {
              if (Platform.OS === 'web') {
                // eslint-disable-next-line no-alert, no-undef
                Auth.signOut();
              } else {
                Alert.alert(
                  'Sign Out',
                  'Are you sure you want to sign out?',
                  [
                    {
                      text: 'Cancel',
                      style: 'cancel',
                      onPress: () => setLogoutButtonVisible(false),
                    },
                    { text: 'OK', onPress: () => Auth.signOut() },
                  ],
                  {
                    cancelable: true,
                  },
                );
              }
            }}
          >
            <LogoutIcon />
            <Text
              style={{
                fontFamily: 'Poppins_400Regular',
                fontSize: 14,
                color: Colors.light.hereworksPrimaryGrey,
                marginLeft: 8,
              }}
            >
              Logout
            </Text>
          </TouchableOpacity>
        )}
      </TouchableOpacity>
      <Modal
        animationIn={'slideInRight'}
        animationOut={'slideOutRight'}
        isVisible={modalVisible}
        style={{ flex: 1, margin: 0 }}
        swipeDirection={'right'}
        propagateSwipe={true}
        onSwipeComplete={() => setModalVisible(false)}
      >
        <View style={[styles.modalContainer, { flex: 1 }]}>
          <View style={styles.modalHeader}>
            <TouchableOpacity
              onPress={() => setModalVisible(!modalVisible)}
              style={{ alignSelf: 'flex-start' }}
            >
              <LeftArrow style={{ alignSelf: 'flex-start', margin: 20 }} />
            </TouchableOpacity>
            <Text style={styles.selectedMetric}>{selectedMetric}</Text>
          </View>
          <ScrollView
            style={{
              flex: 1,
              backgroundColor: Colors.light.hereworksBackground,
            }}
          >
            <View style={styles.modalScoreContainer}>
              <Text style={[styles.modalScore, { color: getHappyColor(selectedMetricScore) }]}>
                {selectedMetricScore}{' '}
              </Text>
              <Text style={styles.modalScoreMax}>/100</Text>
            </View>
            <Text
              style={[styles.modalScoreImprovement, { color: getHappyColor(selectedMetricScore) }]}
            >
              {yesterdayDiff >= 0 ? '+' : ''}
              {yesterdayDiff}% from yesterday
            </Text>
            <HappyDetailsGraph
              metricName="happy_group_energy_usage_local_happyscore_percent"
              title="External Env."
              interval="24h"
            />
            <View style={[styles.aboutContainer, Style.boxShadow]}>
              <Text style={styles.heading}>About</Text>
              <Text style={styles.body}>{description}</Text>
            </View>
            {/* <View style={[styles.requestsContainer, Style.boxShadow]}>
              <Text style={styles.heading}>Occupants requests (2)</Text>
              <Text style={styles.body}>
                Lorem ipsum dolor sit amet, cte adipiscing elit. Aliquam vel dapibu.
              </Text>
            </View> */}
          </ScrollView>
        </View>
      </Modal>
      <Text style={styles.zero}>0</Text>
      <Text style={styles.hundred}>100</Text>
      <Text style={styles.name}>Hi {name}!</Text>
      <Text style={styles.description}>We're in a happy place today</Text>
      <View style={styles.linearGradientWrapper}>
        <ExpoLinearGradient colors={gradient} style={styles.linearGradient}>
          <Image source={buildingImage} style={styles.image} />
        </ExpoLinearGradient>
      </View>
      <ScrollView
        horizontal={true}
        style={styles.scrollView}
        showsHorizontalScrollIndicator={false}
      >
        {groupCounts.length > 0 &&
          groupCounts.map((groupCount: any) => (
            <View style={{ backgroundColor: 'transparent' }} key={groupCount.id}>
              {renderGroupScore(
                groupCount.name,
                groupCount.percentage,
                groupCount.today_vs_yesterday_diff,
                groupCount.description,
                groupCountIcon(groupCount.icon, groupCount.percentage),
              )}
            </View>
          ))}
        {/* For Local Testing */}
        {/* {renderGroupScore('Electricity', 10, -1, <BoltIcon color={happyColor} />)}
        {renderGroupScore('External Environment', 12, 1, <SunIcon color={happyColor} />)}
        {renderGroupScore('Internal Environment', 12, 0, <SunIcon color={happyColor} />)} */}
      </ScrollView>

      <View style={styles.happyScoreWrapper}>
        <Text style={[styles.happyScore, { color: happyColor }]}>{value}</Text>
      </View>
      <Svg
        // @ts-ignore
        onClick={() => resetAnimation()}
        onPress={() => resetAnimation()}
        style={styles.outerCircle}
      >
        <Circle
          cx="30"
          cy="-120"
          r={58}
          stroke={'white'}
          fill="transparent"
          strokeWidth="8"
          strokeDasharray={2 * Math.PI * 62}
          strokeDashoffset={2 * Math.PI * 66 - (2 * Math.PI * 62 * 85) / 100}
          rotation={120}
          originX="0"
          originY="0"
          strokeLinecap="round"
        />
      </Svg>
      {renderCircle(fillValue, true)}
      {renderCircle(fillValue)}
      <View style={styles.mobileGraphCard}>
        <VictoryAreaChart
          metricName="happy_group_energy_usage_local_happyscore_percent"
          title="External Env."
          interval="24h"
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  containerGroupsGraph: {
    flex: 3,
    marginTop: 40,
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    backgroundColor: Colors.light.hereworksBackground,
  },
  lowergraphCard: {
    // marginVertical: 20,
    marginTop: 60,
    marginRight: 400,
    marginLeft: 20,
    // paddingTop: 20,
    borderRadius: 24,
    backgroundColor: 'white',
    flex: 2,
    height: 408,
  },
  mobileGraphCard: {
    position: 'absolute',
    top: 300,
    left: 0,
    alignSelf: 'center',
    // width: 400,
    // height: 350,
    marginTop: 60,
    marginRight: 40,
    marginBottom: 50,
    borderRadius: 24,
    flex: 1,
    backgroundColor: 'transparent',
  },
  container: {
    alignItems: 'center',
    justifyContent: 'flex-start',
    backgroundColor: Colors.light.hereworksBackground,
    flex: 1,
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: '80%',
  },
  linearGradient: {
    paddingTop: 30,
    flex: 1,
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  desktopLinearGradient: {
    width: '100%',
    borderRadius: 24,
  },
  image: {
    alignSelf: 'flex-end',
    marginLeft: 140,
    marginBottom: 20,
    zIndex: 2,
    height: 180,
    width: 199,
  },
  buildingImageDesktop: {
    alignSelf: 'flex-start',
    marginLeft: 40,
    zIndex: 2,
    height: 180,
    width: 199,
  },
  linearGradientWrapper: {
    width: 1400,
    height: 1400,
    top: -1048,
    borderRadius: 700,
    overflow: 'hidden',
  },
  happyItem: {
    width: 104,
    height: 60,
    marginLeft: 10,
    marginTop: 10,
    marginBottom: 10,
    marginRight: 16,
    paddingHorizontal: 14,
    borderRadius: 10,
    backgroundColor: 'white',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  happyItemText: {
    color: Colors.light.hereworksPrimaryGrey800,
    alignSelf: 'flex-start',
    fontFamily: 'Poppins_400Regular',
    fontSize: 12,
  },

  happyItemScoreText: {
    alignSelf: 'center',
    fontFamily: 'Poppins_500Medium',
    fontSize: 20,
    marginLeft: 10,
  },
  happyScore: {
    fontFamily: 'Poppins_700Bold',
    fontSize: 46,
    justifyContent: 'center',
  },
  happyScoreDesktop: {
    fontFamily: 'Poppins_600SemiBold',
    fontSize: 60,
    justifyContent: 'center',
    textAlign: 'right',
  },
  happyScoreWrapper: {
    position: 'absolute',
    top: 200,
    left: 65,
    width: 80,
    alignItems: 'center',
    backgroundColor: 'transparent',
  },
  happyScoreDesktopWrapper: {
    marginRight: 0,
    backgroundColor: 'transparent',
    flexDirection: 'row',
  },
  happyItemScore: {
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    backgroundColor: 'white',
  },
  name: {
    color: Colors.light.hereworksDarkBlue,
    fontFamily: 'Poppins_600SemiBold',
    fontSize: 28,
    flex: 1,
    zIndex: 3,
    position: 'absolute',
    width: '100%',
    top: 60,
    left: 24,
  },
  nameDesktop: {
    color: Colors.light.hereworksPrimaryGrey,
    fontFamily: 'Poppins_600SemiBold',
    fontSize: 28,
    flex: 1,
    zIndex: 3,
    left: 0,
  },
  description: {
    color: Colors.light.hereworksDarkBlue,
    fontFamily: 'Poppins_500Medium',
    fontSize: 14,
    flex: 1,
    zIndex: 3,
    left: 24,
    position: 'absolute',
    top: 99,
  },
  descriptionDesktop: {
    color: Colors.light.hereworksPrimaryGrey,
    fontFamily: 'Poppins_500Medium',
    fontSize: 16,
    flex: 1,
    zIndex: 3,
    left: 0,
    right: 70,
  },
  selectedMetric: {
    color: 'black',
    fontFamily: 'Poppins_500Medium',
    fontSize: 16,
    flex: 1,
    textAlign: 'center',
    marginRight: 40,
  },
  modalContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Colors.light.background,
  },
  desktopModalContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Colors.light.background,
    left: '25%',
    top: 10,
    width: '50%',
    position: 'relative',
    overflow: 'hidden',
    zIndex: 4,
    borderRadius: 24,
  },
  modalHeader: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: 40,
    backgroundColor: 'white',
    justifyContent: 'center',
    width: '100%',
  },
  modalScoreContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'baseline',
    backgroundColor: Colors.light.hereworksBackground,
  },
  modalScore: {
    fontFamily: 'Poppins_600SemiBold',
    fontSize: 64,
    textAlign: 'right',
  },
  modalScoreMax: {
    color: Colors.light.hereworksPrimaryGrey800,
    fontFamily: 'Poppins_400Regular',
    fontSize: 24,
    textAlign: 'center',
  },
  modalScoreImprovement: {
    fontFamily: 'Poppins_400Regular',
    fontSize: 16,
    textAlign: 'center',
    marginBottom: 24,
  },
  scrollView: {
    position: 'absolute',
    top: 300,
    alignSelf: 'center',
    height: 80,
    width: '100%',
    paddingLeft: 16,
  },
  desktopScrollView: {
    marginTop: 14,
    alignSelf: 'center',
    height: 112,
    width: '100%',
    paddingLeft: 56,
    backgroundColor: 'transparent',
  },
  aboutContainer: {
    backgroundColor: 'white',
    borderRadius: 12,
    padding: 20,
    margin: 24,
  },
  requestsContainer: {
    backgroundColor: 'white',
    borderRadius: 12,
    padding: 20,
    marginHorizontal: 24,
  },
  heading: {
    fontFamily: 'Poppins_600SemiBold',
    fontSize: 20,
    color: Colors.light.hereworksPrimaryGrey,
  },
  body: {
    fontFamily: 'Poppins_400Regular',
    fontSize: 16,
    color: Colors.light.hereworksPrimaryGrey800,
  },
  outerCircle: {
    position: 'absolute',
    top: 142,
    left: 17,
    height: 160,
    width: 216,
  },
  innerCircle: {
    position: 'absolute',
    top: 142,
    left: 17,
    flex: 1,
    height: 145,
    width: 160,
    borderRadius: 58,
    zIndex: 2,
  },
  boxShadow: {
    shadowOffset: {
      width: 0,
      height: 10,
    },
    shadowOpacity: 0.1,
    shadowRadius: 20,
    shadowColor: '#001F31',
    elevation: 5,
  },
  zero: {
    position: 'absolute',
    top: 285,
    left: 75,
    color: 'black',
    zIndex: 2,
    fontFamily: 'Poppins_500Medium',
    fontSize: 12,
  },
  hundred: {
    position: 'absolute',
    top: 285,
    left: 120,
    color: 'black',
    zIndex: 2,
    fontFamily: 'Poppins_500Medium',
    fontSize: 12,
  },
  scrollArrows: {
    width: 36,
    height: 36,
    borderRadius: 18,
    backgroundColor: 'white',
    justifyContent: 'center',
    top: 40,
    position: 'absolute',
    marginLeft: 10,
  },
  arrow: {
    marginHorizontal: 6,
    backgroundColor: 'transparent',
  },
});
