import AsyncStorage from '@react-native-async-storage/async-storage';

const getHappyScore = async () => {
  try {
    const jwtToken = await AsyncStorage.getItem('token');
    const clientName = await AsyncStorage.getItem('clientName');

    // eslint-disable-next-line no-undef
    const requestHeaders: HeadersInit = new Headers();
    requestHeaders.set('auth-client-id', clientName ?? 'hereworks');
    requestHeaders.set('auth-jwt', jwtToken ?? '');
    requestHeaders.set('Authorization', jwtToken ?? '');
    requestHeaders.set('Content-Type', 'application/json');

    const response = await fetch(
      'https://api.dev.hereworks.tech/happy/get-happy-score',
      {
        method: 'GET',
        headers: requestHeaders,
      },
    );
    const json = await response.json();
    return json;
  } catch (error) {
    // console.error(error);
    return null;
  }
};

export default getHappyScore;
