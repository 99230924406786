import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const CheckCircle = (props: SvgProps) => (
    <Svg
        width={24}
        height={24}
        fill="none"
        {...props}
    >
        <Path
            d="M22 11.08V12a10 10 0 1 1-5.93-9.14"
            stroke={props.color ?? '#89A7CB'}
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <Path
            d="M22 4 12 14.01l-3-3"
            stroke={props.color ?? '#89A7CB'}
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </Svg>
);

export default CheckCircle;
