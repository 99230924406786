import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const EquipmentIcon = (props: SvgProps) => (
  <Svg
    width={60}
    height={60}
    fill="none"
    {...props}
  >
    <Path
      d="M16 38.133V17.667h24.667L43.3 15H13.333v23.133H16Z"
      fill="#003A70"
    />
    <Path
      d="M10 11.667h40v26.666h3.333v-27.5a2.5 2.5 0 0 0-2.5-2.5H9.167a2.5 2.5 0 0 0-2.5 2.5v27.5H10V11.667ZM1.667 41.667v5.666A4.334 4.334 0 0 0 6 51.667h47.9a4.334 4.334 0 0 0 4.333-4.334v-5.666H1.667ZM55 47.333a1 1 0 0 1-1 1H5.933a1 1 0 0 1-1-1v-3.116h16.584a2.734 2.734 0 0 0 2.5 1.666H35.9a2.733 2.733 0 0 0 2.5-1.666H55v3.116Z"
      fill="#003A70"
    />
  </Svg>
);

export default EquipmentIcon;
