import * as React from 'react';
import Svg, { SvgProps, Rect, Path } from 'react-native-svg';

const CloseFilterIcon = (props: SvgProps) => (
  <Svg
    width={40}
    height={40}
    fill="none"
    {...props}
  >
    <Rect width={40} height={40} rx={10} fill="#003A70" />
    <Path
      d="m13 27 14.142-14.142M13 13l14.142 14.142"
      stroke="#fff"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default CloseFilterIcon;
