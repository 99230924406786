import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const BoltIcon = (props: SvgProps) => (
    <Svg
        width={18}
        height={20}
        fill="none"
        {...props}
    >
        <Path
            d="M9.889 1 1 11.667h8l-.889 7.11L17 8.112H9L9.889 1Z"
            fill={props.color ?? '#D6006D'}
            stroke={props.color ?? '#D6006D'}
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </Svg>
);

export default BoltIcon;
