import { FontAwesome } from '@expo/vector-icons';
import React, { Component } from 'react';
import {
  View, StyleSheet, Text, TouchableOpacity,
} from 'react-native';
import Colors from '../constants/Colors';
import Link from './Link';

interface ItemProps {
    item: any;
    onClickFunction: any;
    navObj: any;
    updateCategory: any;
    selectedCategory: string;
  }

  interface ItemState {
    layoutHeight: number | undefined;
    selected: string;
  }

export default class ExpandableItemComponent extends Component<ItemProps, ItemState> {
  constructor(props: ItemProps) {
    super(props);
    this.state = {
      layoutHeight: 0,
      selected: '',
    };
  }

  componentWillReceiveProps(nextProps: ItemProps) {
    if (nextProps.item.isExpanded) {
      this.setState({ layoutHeight: undefined });
    } else {
      this.setState(() => ({
        layoutHeight: 0,
      }));
    }

    if (nextProps.selectedCategory !== this.props.item.category_name) {
      this.setState({ selected: '' });
    }
  }

  shouldComponentUpdate(nextProps: ItemProps, nextState: ItemState) {
    if (this.state.layoutHeight !== nextState.layoutHeight
        || this.state.selected !== nextState.selected) {
      return true;
    }
    return false;
  }

  render() {
    const context = this;

    // console.log(`navigator: ${JSON.stringify(context.props.navObj)}`);

    return (
        <View>
          <TouchableOpacity
            activeOpacity={0.8}
            onPress={this.props.item.subcategory.length > 0
              ? this.props.onClickFunction
              : () => context.props.navObj.navigate(this.props.item.category_name)}
            style={styles.header}>
            <Text style={styles.headerText}>{this.props.item.category_name}</Text>
            {(this.props.item.subcategory.length > 0 && this.props.item.category_name !== '')
              && <FontAwesome size={30} style={{ marginBottom: -3, color: 'white' }} name={this.props.item.isExpanded ? 'angle-down' : 'angle-up'} />}
          </TouchableOpacity>
          <View
            style={{
              height: this.props.item.category_name !== '' ? this.state.layoutHeight : undefined,
              overflow: 'hidden',
            }}>
            {this.props.item.subcategory.map((item: any, key: string) => (
              <Link key={key}>
                <TouchableOpacity
                  key={key}
                  style={styles.content}
                  onPress={() => {
                    context.props.navObj.navigate(item.val);
                    this.setState({ selected: item.val });
                    this.props.updateCategory(this.props.item.category_name);
                  }}>
                  <View style={item.val === this.state.selected
                    ? styles.selectedMenuItem : styles.menuItem}>
                    <View style={item.val === this.state.selected
                      ? styles.selectedMenuItemSide : styles.menuItemSide} />
                    {item.val === this.state.selected ? item.selectedIcon : item.icon}
                    <Text style={item.val === this.state.selected
                      ? styles.selectedText : styles.text}>
                      {item.title ?? item.val}
                    </Text>
                  </View>
                  <View style={styles.separator} />
                </TouchableOpacity>
              </Link>
            ))}
          </View>
        </View>
    );
  }
}

const styles = StyleSheet.create({
  menuItem: {
    flexDirection: 'row',
    flex: 1,
    alignItems: 'center',
  },
  selectedMenuItem: {
    flexDirection: 'row',
    flex: 1,
    backgroundColor: Colors.light.hereworksPaleBlue,
    alignItems: 'center',
  },
  menuItemSide: {
    height: 40,
    width: 4,
    marginRight: 20,
    backgroundColor: 'transparent',
  },
  selectedMenuItemSide: {
    height: 40,
    width: 4,
    marginRight: 20,
    borderRadius: 12,
    backgroundColor: Colors.light.hereworksLightBlue,
  },
  header: {
    paddingVertical: 16,
    color: Colors.light.hereworksPrimaryGrey,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  content: {
    flex: 2,
  },
  separator: {
    height: 0.5,
    width: '95%',
    marginLeft: 16,
    marginRight: 16,
  },
  headerText: {
    fontSize: 12,
    paddingLeft: 24,
    color: Colors.light.hereworksPrimaryGrey800,
    fontFamily: 'Poppins_400Regular',
  },
  text: {
    fontSize: 14,
    color: Colors.light.hereworksPrimaryGrey,
    padding: 10,
    fontFamily: 'Poppins_400Regular',
    justifyContent: 'center',
  },
  selectedText: {
    fontSize: 14,
    color: Colors.light.hereworksDarkBlue,
    backgroundColor: Colors.light.hereworksPaleBlue,
    padding: 10,
    fontFamily: 'Poppins_400Regular',
    flex: 1,
  },
});
