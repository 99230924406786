import AsyncStorage from '@react-native-async-storage/async-storage';

const getName = async () => {
  try {
    const jwtToken = await AsyncStorage.getItem('token');
    const email = await AsyncStorage.getItem('email');
    const clientName = await AsyncStorage.getItem('clientName');

    // eslint-disable-next-line no-undef
    const requestHeaders: HeadersInit = new Headers();
    requestHeaders.set('auth-client-id', clientName ?? 'hereworks');
    requestHeaders.set('auth-jwt', jwtToken ?? '');
    requestHeaders.set('Authorization', jwtToken ?? '');
    requestHeaders.set('Content-Type', 'application/json');

    const response = await fetch(
      `https://api.dev.hereworks.tech/happy/sw2helper-get-user-id-for-email?email=${email}`,
      {
        method: 'GET',
        headers: requestHeaders,
      },
    );
    const json = await response.json();
    const name = json.smartway2_name.split('(')[0];
    return name;
  } catch (error) {
    // console.error(error);
    return null;
  }
};

export default getName;
