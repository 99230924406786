import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const ClockIcon = (props: SvgProps) => (
  <Svg
    width={18}
    height={18}
    fill="none"
    {...props}
  >
    <Path
      d="M9 .667C4.405.667.667 4.405.667 9c0 4.595 3.738 8.333 8.333 8.333 4.595 0 8.333-3.738 8.333-8.333C17.333 4.405 13.595.667 9 .667Zm0 15A6.674 6.674 0 0 1 2.333 9 6.674 6.674 0 0 1 9 2.333 6.674 6.674 0 0 1 15.666 9 6.674 6.674 0 0 1 9 15.667Z"
      fill="#323E48"
      stroke="#E5ECF0"
      strokeWidth={0.5}
    />
    <Path
      d="M9.833 4.833H8.166v5h5V8.167H9.833V4.833Z"
      fill="#323E48"
      stroke="#E5ECF0"
      strokeWidth={0.5}
    />
  </Svg>
);

export default ClockIcon;
