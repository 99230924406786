import React, { useState } from 'react';
import {
  StyleSheet, Image, TouchableOpacity, Text, Dimensions, View,
} from 'react-native';

import { DrawerContentScrollView } from '@react-navigation/drawer';
import { Auth } from 'aws-amplify';
import { FontAwesome } from '@expo/vector-icons';
import { DefaultTheme } from '@react-navigation/native';
import Colors from '../constants/Colors';
import ExpandableDrawer from './ExpandableDrawer';
import Style from '../constants/Style';
import getName from '../requests/GetName';
import Link from './Link';
import BuildingIcon from './BuildingIcon';

const HereworksLogo = require('../assets/images/Hereworks-logo.png');
const UserIcon = require('../assets/images/user.png');

interface Props {
  state: any;
  navigation: any;
  descriptors: any;
  toggleDarkMode: any;
}

const CustomDrawer = (props: Props) => {
  const [name, updateName] = useState('name');

  React.useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    getName().then((n) => {
      updateName(n);
    }) ?? 'name';
  }, []);
  const window = Dimensions.get('window');

  const [isExpanded, setIsExpanded] = useState(false);
  const [selectedBuilding, setSelectedBuilding] = useState('Building 1');

  return (
    <View style={{ flex: 1, backgroundColor: Colors.light.hereworksBackground }}>
      <View style={[styles.mainSideMenu, Style.boxShadow]}>
        <DrawerContentScrollView
          style={{
            padding: 0,
            margin: 0,
            height: 0,
            width: '100%',
          }}
          {...props}
        >
          <Link style={{ borderRadius: 12, marginHorizontal: 12, marginVertical: 12 }}>
            <TouchableOpacity
              activeOpacity={0.8}
              style={{
                marginLeft: 15,
                paddingVertical: 11,
                borderRadius: 12,
                flexDirection: 'row',
                alignItems: 'center',
              }}
              onPress={() => {
                setIsExpanded(!isExpanded);
                setSelectedBuilding('Building 1');
              }}
            >
              <BuildingIcon
                color={Colors.light.hereworksLightBlue800}
                style={{ marginRight: 10 }}
              />
              <Text>{selectedBuilding}</Text>
              <View style={{ flex: 1, alignItems: 'flex-end', marginRight: 24 }}>
                <FontAwesome
                  size={30}
                  style={{ color: Colors.light.hereworksPrimaryGrey800 }}
                  name={isExpanded ? 'angle-down' : 'angle-up'}
                />
              </View>
            </TouchableOpacity>
          </Link>
          {isExpanded && (
            <>
              <Link style={{ borderRadius: 12, marginHorizontal: 12, marginVertical: 12 }}>
                <TouchableOpacity
                  activeOpacity={0.8}
                  style={{
                    marginLeft: 15,
                    paddingVertical: 11,
                    borderRadius: 12,
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                  onPress={() => {
                    setSelectedBuilding('Building 2');
                    setIsExpanded(!isExpanded);
                  }}
                >
                  <BuildingIcon
                    color={Colors.light.hereworksLightBlue800}
                    style={{ marginRight: 10 }}
                  />
                  <Text>Building 2</Text>
                  <View style={{ flex: 1, alignItems: 'flex-end', marginRight: 24 }}></View>
                </TouchableOpacity>
              </Link>
              <Link style={{ borderRadius: 12, marginHorizontal: 12, marginVertical: 12 }}>
                <TouchableOpacity
                  activeOpacity={0.8}
                  style={{
                    marginLeft: 15,
                    paddingVertical: 11,
                    borderRadius: 12,
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                  onPress={() => {
                    setSelectedBuilding('Building 3');
                    setIsExpanded(!isExpanded);
                  }}
                >
                  <BuildingIcon
                    color={Colors.light.hereworksLightBlue800}
                    style={{ marginRight: 10 }}
                  />
                  <Text>Building 3</Text>
                  <View style={{ flex: 1, alignItems: 'flex-end', marginRight: 24 }}></View>
                </TouchableOpacity>
              </Link>
            </>
          )}
          <ExpandableDrawer navObj={props.navigation} />
        </DrawerContentScrollView>
        <Image source={HereworksLogo} style={styles.sideMenuProfileIcon} />
      </View>
      <TouchableOpacity
        activeOpacity={0.8}
        style={[styles.profileSideMenu, Style.boxShadow]}
        onPress={() => Auth.signOut()}
      >
        <Image source={UserIcon} style={{ height: 20, width: 20, marginLeft: 32 }} />
        <Text style={styles.username}>{name}</Text>
      </TouchableOpacity>
      <TouchableOpacity
        activeOpacity={0.8}
        style={{
          position: 'absolute',
          marginLeft: window.width - 50,
          marginTop: window.height - 50,
          flexDirection: 'row',
        }}
        onPress={() => {
          props.toggleDarkMode(DefaultTheme);
        }}
      >
        <FontAwesome name="moon-o" size={25} color={'transparent'} style={{ marginRight: 15 }} />
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  sideMenuProfileIcon: {
    resizeMode: 'center',
    width: 200,
    height: 100,
    alignSelf: 'center',
  },
  iconStyle: {
    width: 15,
    height: 15,
    marginHorizontal: 5,
  },
  customItem: {
    padding: 16,
    flexDirection: 'row',
    alignItems: 'center',
  },
  username: {
    fontFamily: 'Poppins_400Regular',
    fontSize: 16,
    marginLeft: 10,
    color: Colors.light.hereworksPrimaryGrey,
  },
  profileSideMenu: {
    marginLeft: 27,
    marginTop: 27,
    marginBottom: 27,
    flexDirection: 'row',
    backgroundColor: '#FFFFFF',
    borderRadius: 10,
    alignItems: 'center',
    height: 72,
  },
  mainSideMenu: {
    marginLeft: 27,
    marginTop: 12,
    marginBottom: 27,
    backgroundColor: '#FFFFFF',
    borderRadius: 10,
    alignItems: 'center',
  },
});

export default CustomDrawer;
