import AsyncStorage from '@react-native-async-storage/async-storage';

const storePushToken = async (
  token,
  deviceId,
) => {
  try {
    const name = await AsyncStorage.getItem('name');
    const clientName = await AsyncStorage.getItem('clientName');
    const email = await AsyncStorage.getItem('email');

    // eslint-disable-next-line no-undef
    const requestHeaders: HeadersInit = new Headers();
    requestHeaders.set('clientid', clientName ?? 'hereworks');
    requestHeaders.set('accesskey', 'b7d3137e8e7cfb37e4607962fb7b153a' ?? '');
    requestHeaders.set('Content-Type', 'application/json');

    const response = await fetch(
      'https://api.dev.hereworks.tech/button/notifications/tokens',
      {
        method: 'POST',
        headers: requestHeaders,
        body: JSON.stringify({
          deviceid: deviceId,
          pnt: token,
          pnttype: 'expo',
          name,
          email,
        }),
      },
    );
    const json = await response.json();
    return json;
  } catch (error) {
    return null;
  }
};

export default storePushToken;
