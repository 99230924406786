import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const PinkLineIcon = (props: SvgProps) => (
  <Svg width={50} height={10} fill="none" {...props}>
    <Path
      d="M1 5h48"
      stroke="#D6006D"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path fill="#D6006D" d="M20 0h10v10H20z" />
  </Svg>
);

export default PinkLineIcon;
