import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const SunIcon = (props: SvgProps) => (
    <Svg
        width={24}
        height={24}
        fill="none"
        {...props}
    >
        <Path
            d="M12 17a5 5 0 1 0 0-10 5 5 0 0 0 0 10Z"
            fill={props.color ?? ''}
            stroke={props.color ?? '#89A7CB'}
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <Path
            d="M12 1v2M12 21v2M4.22 4.22l1.42 1.42M18.36 18.36l1.42 1.42M1 12h2M21 12h2M4.22 19.78l1.42-1.42M18.36 5.64l1.42-1.42"
            stroke={props.color ?? '#89A7CB'}
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </Svg>
);

export default SunIcon;
