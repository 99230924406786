import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const PurpleLineIcon = (props: SvgProps) => (
  <Svg width={50} height={12} fill="none" {...props}>
    <Path
      d="M1 5h48"
      stroke="#7E57C5"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path d="m25 0 5.196 9H19.804L25 0Z" fill="#7E57C5" />
  </Svg>
);

export default PurpleLineIcon;
