import React, { useState } from 'react';
// eslint-disable-next-line object-curly-newline
import { View, Text, StyleSheet, Pressable } from 'react-native';

export default function SelectMetric({ data, onSelect }) {
  const [userOption, setUserOption] = useState('CO2');
  const [prevOption, setPrevOption] = useState('People');
  const [nowMetric, setMetric] = useState('');
  // const [nowValue, setValue] = useState('');
  // const [prevMetric, setPrevMetric] = useState('');

  const selectHandler = (value, metric) => {
    onSelect(value, metric, nowMetric, userOption);
    setPrevOption(userOption);
    setUserOption(value);
    setMetric(metric);
    // console.log(userOption, value, userOption);
    // setValue(value)
    // setPrevMetric(nowMetric)
  };
  return (
    <View style={{ flexDirection: 'row' }}>
      {data.map((item) => (
        <Pressable
          style={
            item.value === userOption || item.value === prevOption
              ? styles.selected
              : styles.unselected
          }
          onPress={() => selectHandler(item.value, item.metric)}
          key={item.value}
        >
          <Text
            style={
              item.value === userOption || item.value === prevOption
                ? styles.selectedText
                : styles.unselectedText
            }
          >
            {' '}
            {item.value}
          </Text>
        </Pressable>
      ))}
    </View>
  );
}
const styles = StyleSheet.create({
  option: {
    fontSize: 20,
    color: 'white',
    textAlign: 'center',
  },
  unselected: {
    backgroundColor: '#EBECF0',
    padding: 10,
    borderRadius: 8,
    width: '88px',
    alignItems: 'center',
    justifyContent: 'center',
  },
  selected: {
    backgroundColor: '#003A70',
    padding: 10,
    borderRadius: 8,
    width: '88px',
    alignItems: 'center',
    justifyContent: 'center',
  },
  selectedText: {
    color: 'white',
    alignSelf: 'center',
    fontSize: 15,
  },
  unselectedText: {
    color: '#696D70',
    alignSelf: 'center',
    fontSize: 15,
  },
});
