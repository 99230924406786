/* eslint-disable import/prefer-default-export */
export const GetSingleMetric = async (
  timeString,
  metric,
  index = 'happyscore_flat_permin',
) => {
  const res = await fetch(
    'https://hqzse54ic7.execute-api.eu-west-1.amazonaws.com/firstDeploy/',
    {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
      mode: 'cors',
      body: JSON.stringify({
        size: 0,
        index,
        query: {
          bool: {
            must: {
              range: {
                timestamp: {
                  gte: `now-${timeString}`,
                  lte: 'now',
                },
              },
            },
          },
        },
        aggs: {
          byhour: {
            date_histogram: {
              field: 'timestamp',
              interval: '24h',
              min_doc_count: 0,
            },
            aggs: { score: { avg: { field: metric } } },
          },
        },
      }),
    },
  );
  return res;
};
