import React, { useState } from 'react';
import {
  ActivityIndicator,
  Dimensions,
  Modal,
  Platform,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from 'react-native';
import * as Analytics from 'expo-firebase-analytics';
import CloseModalIcon from '../components/CloseModalIcon';
import CustomIcon from '../components/CustomIcon';
import DeskIcon from '../components/DeskIcon';
import EllipsisIconVertical from '../components/EllipsisIconVertical';
import EquipmentIcon from '../components/EquipmentIcon';
import ExcellenceIcon from '../components/ExcellenceIcon';
import HygieneIcon from '../components/HygieneIcon';
import InternetIcon from '../components/InternetIcon';
import PrinterIcon from '../components/PrinterIcon';
import SearchIcon from '../components/SearchIcon';
import Colors from '../constants/Colors';
import getButtonInstances from '../requests/getButtonInstances';
import getButtonPrototypes from '../requests/GetButtonPrototypes';
import { RootTabScreenProps } from '../types';
import BackArrow from '../components/BackIcon';
import Style from '../constants/Style';
import ScanIcon from '../components/ScanIcon';

// eslint-disable-next-line no-unused-vars
export default function ButtonsScreen({ navigation }: RootTabScreenProps<'Buttons'>) {
  // todo - hook in setLoading
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(true);
  const [instancesLoading, setInstancesLoading] = useState(false);
  const [newButtonCategory, setNewButtonCategory] = useState('');

  const [modalVisible, setModalVisible] = useState(false);
  const [buttonCreated, setButtonCreated] = useState(false);

  const [buttonSelected, setButtonSelected] = useState(false);
  const [selectedTitle, setSelectedTitle] = useState('');
  const [selectedId, setSelectedId] = useState('');
  const [buttonPrototypes, setButtonPrototypes] = useState(null);
  const [floors, setFloors] = useState(null);

  const [preset, setPreset] = useState(0);

  const [tab, setTab] = useState(1);

  const showTabs = false;

  const window = Dimensions.get('window');
  const [dimensions] = React.useState({ window });

  const renderMobile = (): boolean => Platform.OS !== 'web' || dimensions.window?.width < 500 || dimensions.window?.height < 500;

  // eslint-disable-next-line func-names
  const groupBy = function <TItem> (xs: TItem[], key: string): { [key: string]: TItem[] } {
    return xs?.reduce((rv, x) => {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };

  React.useEffect(() => {
    getButtonPrototypes().then((response) => {
      if (response) {
        const groups = groupBy(response.buttonPrototypes, 'GroupId');
        setButtonPrototypes(groups);
        setLoading(false);
      }
    });
  }, [loading]);

  React.useEffect(() => {
    getButtonInstances(selectedId).then((response) => {
      if (response) {
        const groupFloors = groupBy(response.instances, 'LocationFloor');
        setFloors(groupFloors);
        setInstancesLoading(false);
      }
    });
  }, [instancesLoading]);

  const buttonPress = (name) => {
    Analytics.logEvent('button_press', {
      screen: 'Buttons',
      name,
    });
  };

  if (loading || instancesLoading) {
    return (
      <View style={{ flex: 1 }}>
        <View style={{ flex: 1, justifyContent: 'center' }}>
          <ActivityIndicator size={'large'} color={Colors.light.hereworksLoading} />
        </View>
      </View>
    );
  }

  const prototypeView = (value, title, id) => (
    <TouchableOpacity
      style={[styles.qrCodeView, Style.boxShadow]}
      onPress={() => {
        buttonPress(`Prototype: ${id}`);
        setButtonSelected(!buttonSelected);
        setSelectedTitle(title);
        setInstancesLoading(true);
        setSelectedId(id);
      }}
    >
      <ScanIcon />
      <Text style={styles.qrCodeTitle}>{title}</Text>
    </TouchableOpacity>
  );

  const rowDivider = () => (
    <View
      style={{
        backgroundColor: Colors.light.hereworksPrimaryGrey600,
        width: 2,
        height: 30,
      }}
    />
  );

  function getFloor(floor: string) {
    if (floor === 'Ground Floor') {
      return '0';
    }
    if (floor === 'First Floor') {
      return '1';
    }

    return 0;
  }

  const renderRow = (instance, key) => (
    <View
      style={{
        backgroundColor: '#fff',
        marginVertical: 6,
        borderRadius: 10,
        height: 60,
        alignItems: 'center',
        flexDirection: 'row',
      }}
      key={key}
    >
      <View style={{ width: 45, alignItems: 'center' }}>
        <Text style={styles.rowText}>{key + 1}</Text>
      </View>
      {rowDivider()}
      <View style={{ width: 200, alignItems: 'flex-start', marginLeft: 15 }}>
        <Text style={styles.rowText}>{instance.Name}</Text>
      </View>
      {rowDivider()}
      <View style={{ width: 57, alignItems: 'center' }}>
        <Text style={styles.rowText}>{getFloor(instance.LocationFloor)}</Text>
        <Text style={styles.rowSubText}>FLOOR</Text>
      </View>
      {rowDivider()}
      <View style={{ marginLeft: 11, alignItems: 'center' }}>
        <Text style={styles.rowText}>{instance.LocationRoom}</Text>
        <Text style={styles.rowSubText}>ROOM</Text>
      </View>
      <TouchableOpacity style={{ position: 'absolute', right: 20, top: 19 }}>
        <EllipsisIconVertical />
      </TouchableOpacity>
    </View>
  );

  const renderFloor = (floorName) => (
    <View style={{ marginRight: 40, marginTop: 24 }}>
      <Text>{floorName}</Text>
      <View style={styles.divider} />
      {Object.keys(floors).map((floor) =>
        // eslint-disable-next-line implicit-arrow-linebreak
        floors[floor].map((instance, key) => {
          if (instance.LocationFloor === floorName) {
            return <View key={key}>{renderRow(instance, key)}</View>;
          }
          return null;
        }))}
    </View>
  );

  const renderGroup = (buttonPrototype: any) => (
    <>
      <Text style={styles.sectionTitle}>{buttonPrototype[0]?.GroupName}</Text>
      <View style={styles.divider} />
      <View style={{ flexDirection: 'row' }}>
        {buttonPrototype.map((prototype, key) => (
          <View key={key}>{prototypeView(prototype.Title, prototype.Title, prototype.Id)}</View>
        ))}
      </View>
    </>
  );

  return (
    <View
      style={{
        paddingHorizontal: 40,
        backgroundColor: Colors.light.hereworksBackground,
        flex: 1,
      }}
    >
      <Modal
        transparent={true}
        visible={modalVisible}
        style={{ flex: 1, backgroundColor: '#00000005' }}
      >
        {newButtonCategory === '' && (
          <View style={styles.modalContainer}>
            <View style={styles.modalView}>
              <TouchableOpacity
                onPress={() => {
                  Analytics.logEvent('button_press', {
                    screen: 'Buttons',
                    name: 'Close Modal',
                  });
                  setModalVisible(!modalVisible);
                }}
                style={styles.closeButton}
              >
                <CloseModalIcon />
              </TouchableOpacity>
              <Text style={styles.createHeader}>Create new Button</Text>
              <View style={{ flexDirection: 'row', flex: 1, marginHorizontal: 45 }}>
                <TouchableOpacity
                  style={styles.createCard}
                  onPress={() => {
                    buttonPress('Equipment Category');
                    setNewButtonCategory('Equipment');
                  }}
                >
                  <EquipmentIcon style={{ marginTop: 40 }} />
                  <Text style={styles.newButtonTitle}>Equipment</Text>
                </TouchableOpacity>
                <TouchableOpacity
                  style={styles.createCard}
                  onPress={() => {
                    buttonPress('Desk Category');
                    setNewButtonCategory('Desk');
                  }}
                >
                  <DeskIcon style={{ marginTop: 40 }} />
                  <Text style={styles.newButtonTitle}>Desk</Text>
                </TouchableOpacity>
                <TouchableOpacity
                  style={styles.createCard}
                  onPress={() => {
                    buttonPress('Hygiene Category');
                    setNewButtonCategory('Hygiene');
                  }}
                >
                  <HygieneIcon style={{ marginTop: 40 }} />
                  <Text style={styles.newButtonTitle}>Hygiene</Text>
                </TouchableOpacity>
              </View>
              <View
                style={{
                  flexDirection: 'row',
                  flex: 1,
                  marginHorizontal: 45,
                  marginBottom: 76,
                }}
              >
                <TouchableOpacity
                  style={styles.createCard}
                  onPress={() => {
                    buttonPress('Printer Category');
                    setNewButtonCategory('Printer');
                  }}
                >
                  <PrinterIcon style={{ marginTop: 40 }} />
                  <Text style={styles.newButtonTitle}>Printer</Text>
                </TouchableOpacity>
                <TouchableOpacity
                  style={styles.createCard}
                  onPress={() => {
                    buttonPress('Internet Category');
                    setNewButtonCategory('Internet');
                  }}
                >
                  <InternetIcon style={{ marginTop: 40 }} />
                  <Text style={styles.newButtonTitle}>Internet</Text>
                </TouchableOpacity>
                <TouchableOpacity
                  style={styles.createCard}
                  onPress={() => {
                    buttonPress('Custom Category');
                    setNewButtonCategory('Custom');
                  }}
                >
                  <CustomIcon style={{ marginTop: 52 }} />
                  <Text style={styles.newButtonTitle}>Custom</Text>
                </TouchableOpacity>
              </View>
            </View>
          </View>
        )}
        {newButtonCategory !== '' && !buttonCreated && (
          <View style={styles.modalContainer}>
            <View style={styles.modalView}>
              <TouchableOpacity
                onPress={() => {
                  buttonPress('Close Modal');
                  setModalVisible(!modalVisible);
                  setNewButtonCategory('');
                }}
                style={styles.closeButton}
              >
                <CloseModalIcon />
              </TouchableOpacity>
              <Text style={styles.createHeader}>Create new Button</Text>
              <View style={styles.selectRow}>
                <TouchableOpacity
                  onPress={() => {
                    buttonPress('Preset 1');
                    setPreset(1);
                  }}
                  style={styles.radioButton}
                  key={1}
                >
                  <View style={preset === 1 ? styles.selected : {}} />
                </TouchableOpacity>
                <Text style={styles.selectRowText}>Monitor won’t connect</Text>
              </View>
              <View style={styles.selectRow}>
                <TouchableOpacity
                  onPress={() => {
                    buttonPress('Preset 2');
                    setPreset(2);
                  }}
                  style={styles.radioButton}
                  key={1}
                >
                  <View style={preset === 2 ? styles.selected : {}} />
                </TouchableOpacity>
                <Text style={styles.selectRowText}>Desk lamp is broken</Text>
              </View>
              <View style={styles.selectRow}>
                <TouchableOpacity
                  onPress={() => {
                    buttonPress('Preset 3');
                    setPreset(3);
                  }}
                  style={styles.radioButton}
                  key={1}
                >
                  <View style={preset === 3 ? styles.selected : {}} />
                </TouchableOpacity>
                <Text style={styles.selectRowText}>Missing mouse/keyboard</Text>
              </View>
              <View style={styles.selectRow}>
                <TouchableOpacity
                  onPress={() => {
                    buttonPress('Preset 4');
                    setPreset(4);
                  }}
                  style={styles.radioButton}
                  key={1}
                >
                  <View style={preset === 4 ? styles.selected : {}} />
                </TouchableOpacity>
                <Text style={styles.selectRowText}>Other</Text>
              </View>
              <View style={styles.selectPresetButtons}>
                <TouchableOpacity
                  style={styles.usePresetButton}
                  onPress={() => setButtonCreated(!buttonCreated)}
                >
                  <Text numberOfLines={1} style={styles.usePresetButtonTitle}>
                    Use this preset
                  </Text>
                </TouchableOpacity>
                <TouchableOpacity
                  style={styles.customizeButton}
                  onPress={() => setButtonCreated(!buttonCreated)}
                >
                  <Text style={styles.usePresetButtonTitle}>Customize</Text>
                </TouchableOpacity>
              </View>
            </View>
          </View>
        )}
        {buttonCreated && (
          <View style={styles.modalContainer}>
            <View style={styles.modalView}>
              <TouchableOpacity
                onPress={() => {
                  setModalVisible(!modalVisible);
                  setNewButtonCategory('');
                  setButtonCreated(false);
                }}
                style={styles.closeButton}
              >
                <CloseModalIcon />
              </TouchableOpacity>
              <View>
                <ExcellenceIcon style={{ marginTop: 120, flex: 1 }} />
              </View>
              <Text style={styles.successHeader}>Success!</Text>
              <Text style={styles.descriptionText}>You succsesfully created new button.</Text>
              <Text style={[styles.descriptionText, { marginBottom: 60 }]}>
                You can view it in the management panel now.
              </Text>
              <View
                style={{ flexDirection: 'row', justifyContent: 'space-evenly', marginBottom: 80 }}
              >
                <TouchableOpacity
                  style={styles.usePresetButton}
                  onPress={() => {
                    setModalVisible(!modalVisible);
                    setNewButtonCategory('');
                    setButtonCreated(false);
                  }}
                >
                  <Text numberOfLines={1} style={styles.usePresetButtonTitle}>
                    Link QR code
                  </Text>
                </TouchableOpacity>
                <TouchableOpacity
                  style={styles.linkTagButton}
                  onPress={() => {
                    setModalVisible(!modalVisible);
                    setNewButtonCategory('');
                    setButtonCreated(false);
                  }}
                >
                  <Text numberOfLines={1} style={styles.usePresetButtonTitle}>
                    Link NFC tag
                  </Text>
                </TouchableOpacity>
              </View>
            </View>
          </View>
        )}
      </Modal>
      <View style={{ marginTop: 30, flexDirection: 'row', justifyContent: 'space-between' }}>
        <View style={styles.searchBox}>
          <SearchIcon style={{ marginLeft: 14 }} />
          <TextInput
            placeholder="Search buttons"
            // @ts-ignore
            style={[styles.searchText, !renderMobile() ? { outlineStyle: 'none' } : {}]}
            onChangeText={() => {
              // todo
            }}
          />
        </View>
        <TouchableOpacity
          style={styles.createButton}
          onPress={() => {
            buttonPress('Create new');
            setModalVisible(!modalVisible);
          }}
        >
          <Text style={styles.createButtonTitle}>Create new</Text>
        </TouchableOpacity>
      </View>
      {!buttonSelected && (
        <View>
          {Object.keys(buttonPrototypes).map((buttonPrototype: any, key: number) => (
            <View key={key}>{renderGroup(buttonPrototypes[buttonPrototype])}</View>
          ))}
        </View>
      )}
      {buttonSelected && (
        <View style={{ flex: 1 }}>
          <View style={{ flexDirection: 'row' }}>
            <TouchableOpacity
              onPress={() => setButtonSelected(!buttonSelected)}
              hitSlop={{
                top: 20,
                bottom: 20,
                left: 50,
                right: 50,
              }}
              style={styles.backArrow}
            >
              <BackArrow />
            </TouchableOpacity>
            <Text style={styles.sectionTitle}>{selectedTitle}</Text>
          </View>
          <View style={styles.divider} />
          <View style={{ flex: 1, justifyContent: 'flex-start', width: '100%' }}>
            <View style={{ width: '100%', flexDirection: 'row', flex: 1 }}>
              <View style={{ width: 772 }}>
                {showTabs && (
                  <View style={styles.tabs}>
                    <TouchableOpacity
                      onPress={() => {
                        buttonPress('Tab 1');
                        setTab(1);
                      }}
                      style={{ marginRight: 16 }}
                    >
                      <Text style={styles.tabTitle}>Variants</Text>
                      {tab === 1 && (
                        <View
                          style={{
                            width: '100%',
                            height: 2,
                            backgroundColor: Colors.light.hereworksLightBlue,
                          }}
                        />
                      )}
                    </TouchableOpacity>
                    <TouchableOpacity
                      onPress={() => {
                        buttonPress('Tab 2');
                        setTab(2);
                      }}
                      style={{ marginRight: 16 }}
                    >
                      <Text style={styles.tabTitle}>Report tracker</Text>
                      {tab === 2 && (
                        <View
                          style={{
                            width: '100%',
                            height: 2,
                            backgroundColor: Colors.light.hereworksLightBlue,
                          }}
                        />
                      )}
                    </TouchableOpacity>
                    <TouchableOpacity
                      onPress={() => {
                        buttonPress('Tab 3');
                        setTab(3);
                      }}
                      style={{ marginRight: 16 }}
                    >
                      <Text style={styles.tabTitle}>Notifications</Text>
                      {tab === 3 && (
                        <View
                          style={{
                            width: '100%',
                            height: 2,
                            backgroundColor: Colors.light.hereworksLightBlue,
                          }}
                        />
                      )}
                    </TouchableOpacity>
                    <TouchableOpacity
                      onPress={() => {
                        buttonPress('Tab 4');
                        setTab(4);
                      }}
                      style={{ marginRight: 16 }}
                    >
                      <Text style={styles.tabTitle}>Settings</Text>
                      {tab === 4 && (
                        <View
                          style={{
                            width: '100%',
                            height: 2,
                            backgroundColor: Colors.light.hereworksLightBlue,
                          }}
                        />
                      )}
                    </TouchableOpacity>
                  </View>
                )}
                {Object.keys(floors).map((floor, key) => (
                  <View key={key}>{renderFloor(floor)}</View>
                ))}
              </View>
              <View
                style={{
                  marginTop: 32,
                  borderRadius: 10,
                  width: 338,
                  height: '100%',
                  backgroundColor: Colors.light.hereworksPaleBlue,
                }}
              >
                <Text style={styles.parentTitle}>Parent analytics</Text>
                <View style={{ flexDirection: 'row', marginHorizontal: 24 }}>
                  <View style={styles.parentTile}>
                    <Text style={styles.parentTileTitle}>ID NUMBER</Text>
                    <Text style={[{ marginLeft: 14 }, styles.rowText]}>42956381</Text>
                  </View>
                  <View style={styles.parentTile}>
                    <Text style={styles.parentTileTitle}>CREATED ON</Text>
                    <Text style={[{ marginLeft: 14 }, styles.rowText]}>June 16, 2022</Text>
                  </View>
                </View>
                <View style={{ flexDirection: 'row', marginHorizontal: 24 }}>
                  <View style={styles.parentTile}>
                    <Text style={styles.parentTileTitle}>CREATED BY</Text>
                    <Text style={[{ marginLeft: 14 }, styles.rowText]}>Michael Bell</Text>
                  </View>
                  <View style={styles.parentTile}>
                    <Text style={styles.parentTileTitle}>TOTAL REPORTS</Text>
                    <Text style={[{ marginLeft: 14 }, styles.rowText]}>3139</Text>
                  </View>
                </View>
                <View style={{ flexDirection: 'row', marginHorizontal: 24 }}>
                  <View style={styles.parentTile}>
                    <Text style={styles.parentTileTitle}>TOTAL DESKS</Text>
                    <Text style={[{ marginLeft: 14 }, styles.rowText]}>600</Text>
                  </View>
                  <View style={styles.parentTile}>
                    <Text style={styles.parentTileTitle}>TOTAL FLOORS</Text>
                    <Text style={[{ marginLeft: 14 }, styles.rowText]}>3</Text>
                  </View>
                </View>
                <View style={{ flexDirection: 'row', marginHorizontal: 24 }}>
                  <View style={styles.parentTile}>
                    <Text style={styles.parentTileTitle}>INSTANCES</Text>
                    <Text style={[{ marginLeft: 14 }, styles.rowText]}>600</Text>
                  </View>
                  <View style={styles.parentTile}>
                    <Text style={styles.parentTileTitle}>TOTAL ROOMS</Text>
                    <Text style={[{ marginLeft: 14 }, styles.rowText]}>37</Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  qrCodeView: {
    paddingHorizontal: 18,
    paddingTop: 18,
    paddingBottom: 8,
    borderRadius: 10,
    width: 160,
    marginTop: 20,
    marginRight: 20,
    backgroundColor: '#fff',
    alignItems: 'center',
  },
  qrCodeTitle: {
    color: Colors.light.hereworksPrimaryGrey,
    fontFamily: 'Roboto_500Medium',
    textAlign: 'center',
    fontSize: 16,
    marginTop: 8,
  },
  sectionTitle: {
    color: '#000',
    fontFamily: 'Roboto_500Medium',
    fontSize: 24,
    marginTop: 40,
  },
  divider: {
    height: 2,
    backgroundColor: Colors.light.hereworksPaleBlue800,
    width: '100%',
    marginTop: 14,
  },
  createButton: {
    borderRadius: 10,
    backgroundColor: Colors.light.hereworksDarkBlue,
  },
  usePresetButton: {
    borderRadius: 10,
    backgroundColor: Colors.light.hereworksDarkGreen,
    flex: 1,
    marginRight: 15,
    marginLeft: 125,
    alignItems: 'center',
  },
  customizeButton: {
    borderRadius: 10,
    backgroundColor: Colors.light.hereworksExtraDarkTeal700,
    flex: 1,
    marginLeft: 15,
    marginRight: 125,
    alignItems: 'center',
  },
  linkTagButton: {
    borderRadius: 10,
    backgroundColor: Colors.light.hereworksDarkGreen,
    flex: 1,
    marginLeft: 15,
    marginRight: 125,
    alignItems: 'center',
  },
  createButtonTitle: {
    color: '#fff',
    fontFamily: 'Roboto_400Regular',
    fontSize: 14,
    marginHorizontal: 45,
    marginVertical: 12,
  },
  usePresetButtonTitle: {
    color: '#fff',
    fontFamily: 'Roboto_400Regular',
    fontSize: 14,
    marginHorizontal: 33,
    marginVertical: 12,
  },
  searchBox: {
    height: 40,
    width: 282,
    borderRadius: 24,
    backgroundColor: Colors.light.hereworksBackground,
    borderColor: Colors.light.hereworksPaleBlue900,
    borderWidth: 1.5,
    flexDirection: 'row',
    alignItems: 'center',
  },
  searchText: {
    color: Colors.light.hereworksPrimaryGrey,
    marginLeft: 9,
    fontFamily: 'Roboto_400Regular',
  },
  modalContainer: {
    flex: 1,
    backgroundColor: '#00000050',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalView: {
    backgroundColor: '#fff',
    borderRadius: 20,
    alignItems: 'center',
    justifyContent: 'center',
    width: 600,
    height: 600,
  },
  createHeader: {
    fontFamily: 'Roboto_500Medium',
    fontSize: 30,
    marginHorizontal: 176,
    marginVertical: 60,
  },
  successHeader: {
    fontFamily: 'Roboto_500Medium',
    fontSize: 30,
    marginHorizontal: 176,
    marginBottom: 20,
    marginTop: 50,
  },
  createCard: {
    flex: 1,
    backgroundColor: Colors.light.hereworksPaleBlue,
    borderRadius: 10,
    margin: 15,
    alignItems: 'center',
    width: 140,
    height: 160,
  },
  newButtonTitle: {
    fontFamily: 'Roboto_500Medium',
    fontSize: 16,
    color: '#000',
    marginBottom: 17,
    marginTop: 17,
  },
  closeButton: {
    width: 36,
    height: 36,
    borderRadius: 18,
    position: 'absolute',
    right: 24,
    top: 24,
    backgroundColor: Colors.light.hereworksPaleBlue800,
    justifyContent: 'center',
    alignItems: 'center',
  },
  backArrow: {
    marginRight: 7,
    marginTop: 43,
  },
  descriptionText: {
    fontFamily: 'Roboto_500Medium',
    fontSize: 18,
    lineHeight: 27,
    color: Colors.light.hereworksPrimaryGrey900,
  },
  radioButton: {
    backgroundColor: Colors.light.hereworksPaleBlue800,
    height: 40,
    width: 40,
    borderRadius: 20,
    marginVertical: 15,
    marginHorizontal: 10,
    alignItems: 'center',
    justifyContent: 'center',
  },
  selected: {
    backgroundColor: Colors.light.hereworksDarkBlue,
    height: 30,
    width: 30,
    borderRadius: 15,
  },
  selectRow: {
    backgroundColor: Colors.light.hereworksPrimaryGrey200,
    borderRadius: 12,
    flexDirection: 'row',
    alignItems: 'center',
    width: 327,
    marginBottom: 10,
  },
  selectRowText: {
    fontFamily: 'Poppins_500Medium',
    fontSize: 18,
    color: '#000',
  },
  selectPresetButtons: {
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    marginBottom: 50,
    marginTop: 30,
  },
  tabs: {
    flexDirection: 'row',
    marginTop: 17,
  },
  tabTitle: {
    fontFamily: 'Roboto_400Regular',
    fontSize: 16,
    color: '#000',
    marginHorizontal: 4,
    marginBottom: 4,
    lineHeight: 19,
    textAlign: 'center',
  },
  rowText: {
    fontFamily: 'Roboto_500Medium',
    fontSize: 16,
    color: Colors.light.hereworksPrimaryGrey,
  },
  rowSubText: {
    fontFamily: 'Roboto_500Medium',
    fontSize: 10,
    color: Colors.light.hereworksPrimaryGrey700,
  },
  parentTitle: {
    fontFamily: 'Roboto_500Medium',
    fontSize: 24,
    color: '#000',
    marginLeft: 24,
    marginTop: 24,
    marginBottom: 20,
  },
  parentTile: {
    width: 140,
    height: 60,
    marginBottom: 10,
    marginRight: 10,
    backgroundColor: '#fff',
    borderRadius: 10,
    justifyContent: 'center',
  },
  parentTileTitle: {
    marginLeft: 14,
    color: Colors.light.hereworksPrimaryGrey900,
    marginBottom: 4,
    fontSize: 11,
  },
});
