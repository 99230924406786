import {
  ActivityIndicator,
  NativeEventEmitter,
  Platform,
  RefreshControl,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import React, { useRef, useState } from 'react';
import { ScrollView } from 'react-native-gesture-handler';
import AsyncStorage from '@react-native-async-storage/async-storage';
import * as Notifications from 'expo-notifications';
import Colors from '../constants/Colors';
import NotificationsSettingsIcon from './NotificationsSettingsIcon';
import Notification from './Notification';
import LeftArrow from './LeftArrow';
import Switch from './Switch';
import getIssues from '../requests/GetIssues';
import updateIssue from '../requests/UpdateIssue';
import deleteIssue from '../requests/DeleteIssue';

export default function NotificationsCard() {
  const [settingsVisible, setSettingsVisible] = useState(false);
  const [loadMore, setLoadMore] = useState(false);
  const [issues, setIssues] = useState([]);
  const [loading, setLoading] = useState(true);
  const [notificationsAllowed, setNotificationsAllowed] = useState(true);
  const [lowAllowed, setLowAllowed] = useState(true);
  const [medAllowed, setMedAllowed] = useState(true);
  const [highAllowed, setHighAllowed] = useState(true);
  const [criticalAllowed, setCriticalAllowed] = useState(true);
  const [refresh, setRefresh] = useState(0);
  const [refreshing, setRefreshing] = useState(false);

  const notificationListener = useRef();

  AsyncStorage.getItem('allowNotifications').then((allowed) => setNotificationsAllowed(allowed !== '0'));
  AsyncStorage.getItem('lowAllowed').then((allowed) => setLowAllowed(allowed !== '0'));
  AsyncStorage.getItem('medAllowed').then((allowed) => {
    setMedAllowed(allowed !== '0');
  });
  AsyncStorage.getItem('highAllowed').then((allowed) => setHighAllowed(allowed !== '0'));

  const emitter = Platform.OS === 'web' ? new NativeEventEmitter() : null;

  React.useEffect(() => {
    getIssues().then((response) => {
      if (response) {
        setIssues(response.issues);
        setLoading(false);
        setRefreshing(false);
      }
    });

    // @ts-ignore
    notificationListener.current = Notifications.addNotificationReceivedListener(() => {
      setLoading(true);
      setRefresh(refresh + 1);
    });

    return () => {
      Notifications.removeNotificationSubscription(notificationListener.current);
    };
  }, [loadMore, refresh]);

  if (loading && !refreshing) {
    return (
      <View style={styles.cardContainer}>
        <View style={{ flex: 1, justifyContent: 'center' }}>
          <ActivityIndicator size={'large'} color={Colors.light.hereworksLoading} />
        </View>
      </View>
    );
  }

  if (settingsVisible) {
    return (
      <View style={styles.cardContainer}>
        <View style={{ flexDirection: 'row', alignItems: 'center', height: 50 }}>
          <TouchableOpacity
            onPress={() => setSettingsVisible(!settingsVisible)}
            hitSlop={{
              top: 20,
              bottom: 20,
              left: 50,
              right: 50,
            }}
            style={styles.backButton}
          >
            <LeftArrow style={{ marginLeft: 20 }} />
          </TouchableOpacity>
          <Text style={styles.preferencesHeader}>Notification Preferences</Text>
        </View>
        <View style={styles.preferenceRow}>
          <NotificationsSettingsIcon style={{ marginLeft: 15 }} />
          <Text style={styles.preferenceText}>Allow notifications</Text>
          <Switch
            isToggled={notificationsAllowed}
            onToggled={() => {
              setNotificationsAllowed(!notificationsAllowed);
              AsyncStorage.setItem('allowNotifications', notificationsAllowed ? '0' : '1');
            }}
          />
        </View>
        {notificationsAllowed && (
          <>
            <View style={styles.divider} />
            <View style={styles.preferenceRow}>
              <View
                style={[styles.priority, { backgroundColor: Colors.light.hereworksUrgentRed }]}
              />
              <Text style={styles.preferenceText}>P1 - Critical</Text>
              <Switch
                isToggled={criticalAllowed}
                onToggled={() => {
                  setCriticalAllowed(!criticalAllowed);
                  AsyncStorage.setItem('criticalAllowed', criticalAllowed ? '0' : '1');
                }}
              />
            </View>
            <View style={styles.preferenceRow}>
              <View
                style={[styles.priority, { backgroundColor: Colors.light.hereworksMediumYellow }]}
              />
              <Text style={styles.preferenceText}>P2 - High</Text>
              <Switch
                isToggled={highAllowed}
                onToggled={() => {
                  setHighAllowed(!highAllowed);
                  AsyncStorage.setItem('highAllowed', highAllowed ? '0' : '1');
                }}
              />
            </View>
            <View style={styles.preferenceRow}>
              <View
                style={[styles.priority, { backgroundColor: Colors.light.hereworksLightBlue }]}
              />
              <Text style={styles.preferenceText}>P3 - Moderate</Text>
              <Switch
                isToggled={medAllowed}
                onToggled={() => {
                  setMedAllowed(!medAllowed);
                  AsyncStorage.setItem('medAllowed', medAllowed ? '0' : '1');
                }}
              />
            </View>
            <View style={styles.preferenceRow}>
              <View
                style={[styles.priority, { backgroundColor: Colors.light.hereworksCheckGreen }]}
              />
              <Text style={styles.preferenceText}>P4 - Low</Text>
              <Switch
                isToggled={lowAllowed}
                onToggled={() => {
                  setLowAllowed(!lowAllowed);
                  AsyncStorage.setItem('lowAllowed', lowAllowed ? '0' : '1');
                }}
              />
            </View>
          </>
        )}
        <View style={{ marginTop: 100 }}>
          <View style={styles.preferenceRow}>
            <View
              style={[styles.priority, { backgroundColor: Colors.light.hereworksBackground }]}
            />
            <Text style={styles.preferenceText}>Enable color-blind mode</Text>
            <Switch
              isToggled={false}
              onToggled={() => {
                /* todo */
              }}
            />
          </View>
        </View>
      </View>
    );
  }

  return (
    <View style={styles.cardContainer}>
      {Platform.OS !== 'web' && (
        <TouchableOpacity
          style={styles.settingsButtonMobile}
          onPress={() => setSettingsVisible(!settingsVisible)}
        >
          <NotificationsSettingsIcon />
        </TouchableOpacity>
      )}
      {notificationsAllowed && (
        <ScrollView
          style={Platform.OS === 'web' ? { marginBottom: 60 } : {}}
          refreshControl={
            <RefreshControl
              refreshing={loading}
              onRefresh={() => {
                setRefresh(refresh + 1);
                setLoading(true);
                setRefreshing(true);
              }}
            />
          }
        >
          {issues
            .filter(
              (issue: any) => issue.assignee === null
                && (!lowAllowed ? issue.priority !== 'low' : {})
                && (!medAllowed ? issue.priority !== 'medium' : {})
                && (!highAllowed ? issue.priority !== 'high' : {}),
            )
            .map((issue: any, i: number) => (
              <Notification
                isNew={true}
                title={issue.name}
                priority={issue.priority}
                key={i}
                id={issue.id}
                createdon={issue.createdon}
                markComplete={() => {
                  setLoading(true);
                  updateIssue(issue.id, issue.assignee, true).then(() => {
                    setRefresh(refresh + 1);
                    emitter.emit('event.updatenotifications', {});
                  });
                }}
                deleteReport={() => {
                  setLoading(true);
                  deleteIssue(issue.id).then(() => {
                    setRefresh(refresh + 1);
                    emitter.emit('event.updatenotifications', {});
                  });
                }}
              />
            ))}
          {issues.filter((issue: any) => issue.assignee !== null).length > 0 && (
            <View style={styles.olderContainer}>
              <View style={styles.dividerStyle} />
              <Text style={styles.olderText} numberOfLines={1}>
                OLDER NOTIFICATIONS
              </Text>
              <View style={styles.dividerStyle} />
            </View>
          )}
          {issues
            .filter(
              (issue: any) => issue.assignee !== null
                && (!lowAllowed ? issue.priority !== 'low' : {})
                && (!medAllowed ? issue.priority !== 'medium' : {})
                && (!highAllowed ? issue.priority !== 'high' : {}),
            )
            .map((issue: any, i: number) => (
              <Notification
                isNew={false}
                title={issue.name}
                priority={issue.priority}
                key={i}
                id={issue.id}
                createdon={issue.createdon}
                markComplete={() => {
                  setLoading(true);
                  updateIssue(issue.id, issue.assignee, true).then(() => {
                    setRefresh(refresh + 1);
                    emitter.emit('event.updatenotifications', {});
                  });
                }}
                deleteReport={() => {
                  setLoading(true);
                  deleteIssue(issue.id).then(() => {
                    setRefresh(refresh + 1);
                    emitter.emit('event.updatenotifications', {});
                  });
                }}
              />
            ))}
          {!loadMore && <View style={{ height: 160, zIndex: 66 }}></View>}
          {loadMore && (
            <>
              <Notification
                isNew={false}
                priority={'low'}
                title={'Someone reported water leakage in the Studio space on the ground floor'}
                id={'1'}
                createdon={''}
                markComplete={null}
                deleteReport={null}
              />
              <Notification
                isNew={false}
                priority={'medium'}
                title={'Someone reported water leakage in the Studio space on the ground floor'}
                id={'2'}
                createdon={''}
                markComplete={null}
                deleteReport={null}
              />
              <Notification
                isNew={false}
                priority={'high'}
                title={'Someone reported water leakage in the Studio space on the ground floor'}
                id={'3'}
                createdon={''}
                markComplete={null}
                deleteReport={null}
              />
              <View style={{ height: 160 }}></View>
            </>
          )}
        </ScrollView>
      )}
      {Platform.OS === 'web' && (
        <View style={styles.buttonsContainer}>
          <TouchableOpacity style={styles.loadMoreButton} onPress={() => setLoadMore(true)}>
            <Text style={styles.loadMoreText}>LOAD MORE</Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={styles.settingsButton}
            onPress={() => setSettingsVisible(!settingsVisible)}
          >
            <NotificationsSettingsIcon />
          </TouchableOpacity>
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  cardContainer: {
    flex: 1,
    width: '100%',
    backgroundColor: 'transparent',
    marginTop: 15,
    zIndex: 10,
  },
  dividerStyle: {
    height: 1,
    backgroundColor: Colors.light.hereworksPrimaryGrey600,
    flex: 1,
    zIndex: 1,
  },
  olderText: {
    color: Colors.light.hereworksPrimaryGrey800,
    fontFamily: 'Roboto_400Regular',
    fontSize: 14,
    flex: 2,
    textAlign: 'center',
  },
  olderContainer: {
    flexDirection: 'row',
    marginHorizontal: 20,
    marginVertical: 16,
    alignItems: 'center',
    zIndex: 0,
  },
  loadMoreButton: {
    backgroundColor: Colors.light.hereworksPaleBlue,
    borderRadius: 8,
    height: 40,
    flex: 1,
    justifyContent: 'center',
  },
  settingsButton: {
    backgroundColor: Colors.light.hereworksPaleBlue,
    borderRadius: 8,
    height: 40,
    width: 40,
    marginLeft: 8,
    justifyContent: 'center',
  },
  settingsButtonMobile: {
    position: 'absolute',
    borderRadius: 8,
    height: 50,
    width: 50,
    marginLeft: 8,
    right: 15,
    zIndex: 2,
    top: -75,
    justifyContent: 'center',
  },
  loadMoreText: {
    color: Colors.light.hereworksLightBlue,
    fontFamily: 'Roboto_500Medium',
    fontSize: 16,
    textAlign: 'center',
  },
  buttonsContainer: {
    flexDirection: 'row',
    marginHorizontal: 20,
    flex: 1,
    position: 'absolute',
    bottom: 20,
    left: 0,
    right: 0,
  },
  preferencesHeader: {
    textAlign: 'center',
    flex: 1,
    position: 'absolute',
    width: '100%',
    fontFamily: 'Roboto_400Regular',
    fontSize: 18,
    zIndex: -1,
    color: Colors.light.hereworksPrimaryGrey,
  },
  backButton: {
    width: 50,
    height: 50,
    justifyContent: 'center',
    position: 'absolute',
  },
  preferenceRow: {
    height: 60,
    marginHorizontal: 20,
    backgroundColor: Colors.light.hereworksBackground,
    alignItems: 'center',
    flexDirection: 'row',
    marginBottom: 10,
    borderRadius: 8,
  },
  preferenceText: {
    fontFamily: 'Roboto_400Regular',
    fontSize: 18,
    color: Colors.light.hereworksPrimaryGrey,
  },
  priority: {
    marginLeft: 20,
    marginRight: 15,
    height: 20,
    width: 20,
    borderRadius: 10,
  },
  divider: {
    height: 1,
    backgroundColor: Colors.light.hereworksPrimaryGrey600,
    marginHorizontal: 20,
    marginVertical: 30,
  },
});
