import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const LightBlueLineIcon = (props: SvgProps) => (
  <Svg width={50} height={12} fill="none" {...props}>
    <Path
      d="M1 6h48"
      stroke="#2173C4"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path d="m19 6 6-6 6 6-6 6-6-6Z" fill="#2173C4" />
  </Svg>
);

export default LightBlueLineIcon;
