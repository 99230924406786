import React, { useState } from 'react';
// eslint-disable-next-line object-curly-newline
import { View, Text, StyleSheet, Pressable } from 'react-native';

export default function SelectTimeFrame({ data, onSelect }) {
  const [userOption, setUserOption] = useState('Week');
  const selectHandler = (value, timeString) => {
    onSelect(value, timeString);
    setUserOption(value);
  };
  return (
    <View style={{ flexDirection: 'row' }}>
      {data.map((item) => (
        <Pressable
          style={
            item.value === userOption ? styles.selected : styles.unselected
          }
          onPress={() => selectHandler(item.value, item.timeString)}
          key={item.value}
        >
          <Text
            style={
              item.value === userOption
                ? styles.selectedText
                : styles.unselectedText
            }
          >
            {' '}
            {item.value}
          </Text>
        </Pressable>
      ))}
    </View>
  );
}
const styles = StyleSheet.create({
  option: {
    fontSize: 20,
    color: 'white',
    textAlign: 'center',
  },
  unselected: {
    backgroundColor: '#F8FAFB',
    padding: 8,
    borderRadius: 8,
    width: '78px',
    alignItems: 'center',
  },
  selected: {
    backgroundColor: '#003A70',
    padding: 8,
    borderRadius: 8,
    width: '78px',
    alignItems: 'center',
  },
  selectedText: {
    color: 'white',
    alignSelf: 'center',
    fontSize: 15,
  },
  unselectedText: {
    color: '#696D70',
    alignSelf: 'center',
    fontSize: 15,
  },
});
