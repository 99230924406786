/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';

// import { getHappyScoreGroupCounts } from "../requests/GetHappyScoreGroupCounts";
// import { Card } from 'react-native-elements';
// eslint-disable-next-line object-curly-newline
import { View } from 'react-native';
import {
  VictoryLine,
  VictoryAxis,
  VictoryChart,
  VictoryScatter,
} from 'victory-native';

import VictorySmallTimeSelector from './VictorySmallTimeSelector';

const VictorySingleLineGraph = (props) => {
  // <VictorySingleLineGraph
  //   metricName="happy_group_energy_usage_local_happyscore_percent"
  //   title="External Env."
  //   interval="24h"
  // />;
  //  'happy_group_internal_environment_local_happyscore_percent'
  // 'happy_group_external_environment_local_happyscore_percent'
  // 'happy_group_it_local_happyscore_percent'
  // 'happy_group_energy_usage_local_happyscore_percent'
  // 'happy_group_occupant_satisfaction_local_happyscore_percent'
  // 'happy_metric_total_building_occupancy_local_happyscore_percent'

  // const [avg, setAvg] = useState(30);
  const [dataFormat, setDataFormat] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [interval, setInterval] = useState(props.interval);
  const [selectedTimeFrame, setTimeFrame] = useState('7d');

  // eslint-disable-next-line no-unused-vars
  const [option, setOption] = useState(null);
  // let interval = "6h";

  const formatted = [];
  let point = {};
  const timeLabels = [];
  const Score = [];

  // eslint-disable-next-line no-unused-vars
  const buttondata = [
    { value: '1D', timeString: '1d' },
    { value: '1W', timeString: '7d' },
    { value: '1M', timeString: '30d' },
    { value: '3M', timeString: '90d' },
    { value: '6M', timeString: '180d' },
    { value: 'Y', timeString: '365d' },
  ];

  function handleClick(value, timeString) {
    setOption(value);
    setTimeFrame(timeString);
    // console.log(value, timeString);
  }
  useEffect(() => {
    const getData = async () => {
      try {
        fetch(
          'https://hqzse54ic7.execute-api.eu-west-1.amazonaws.com/firstDeploy/',
          {
            headers: {
              'Content-Type': 'application/json',
            },
            method: 'POST',
            mode: 'cors',
            body: JSON.stringify({
              size: 0,
              index: props.index || 'happyscore_flat_permin',
              query: {
                bool: {
                  must: {
                    range: {
                      timestamp: {
                        gte: `now-${selectedTimeFrame}`,
                        lte: 'now',
                      },
                    },
                  },
                },
              },
              aggs: {
                byhour: {
                  date_histogram: {
                    field: 'timestamp',
                    interval: props.interval,
                    min_doc_count: 0,
                  },
                  aggs: {
                    Score: {
                      avg: { field: props.metricName },
                    },
                  },
                },
              },
            }),
          },
        )
          .then((response) => response.json())
          .then((data) => {
            // console.log(`data: ${JSON.stringify(data)}`);

            data.aggregations.byhour.buckets.map((e) => {
              Score.push(e.Score.value);
              let hourLabel = e.key_as_string;
              hourLabel = `${new Date(hourLabel)
                .toLocaleDateString()
                .slice(0, 5)
                .replace(/\//g, '.')}`;
              timeLabels.push(hourLabel);
              point = {
                name: hourLabel,
                Score: Math.floor(e.Score.value * 10) / 10,
              };
              formatted.push(point);
            });

            setDataFormat(formatted);
            // console.log(`formatted: ${JSON.stringify(timeLabels)}`);

            // const arrAvg = (arr) => arr.reduce((a, b) => a + b, 0) / arr.length;
            // setAvg(Number(arrAvg(Score).toFixed(0)));
          });
      } catch (error) {
        // console.error(error);
      }
    };
    getData();
  }, [selectedTimeFrame, interval]);
  // ==================================================================================

  const chartTheme = {
    axis: {
      stroke: 'none',
      style: {
        // fixLabelOverlap: true,
        stroke: '#696D70',
        tickLabels: {
          fill: '#696D70',
        },
        grid: { stroke: 'none' },
      },
    },
  };
  return (
    <View>
      {/* <Card containerStyle={{ borderRadius: 24, width: 900 }}> */}
      <View
        style={{
          alignSelf: 'center',
          // width: '99%',
          flexDirection: 'row',
        }}
      >
        {/* //======================================================================== */}

        <View
          style={{
            flex: 1,
            marginLeft: 20,
            // alignItems: 'left',
            justifyContent: 'center',
          }}
        >
          {/* <Text style={{ fontSize: 20, fontWeight: 'bold' }}>
            {' '}
            {props.title}
          </Text> */}
        </View>
        <View
          style={{
            flex: 1,
            paddingTop: 15,
            // alignSelf: "center",
            alignItems: 'center',
          }}
        >
          <VictorySmallTimeSelector
            data={buttondata}
            onSelect={(value, timeString) => handleClick(value, timeString)}
          />
        </View>
        <View
          style={{
            flex: 1,
          }}
        ></View>
      </View>
      <VictoryChart
        theme={chartTheme}
        domainPadding={{ x: [50, -50], y: 50 }}
        fixLabelOverlap={true}
      >
        {/* <VictoryGroup
          style={{ axis: { stroke: '#003A70' }, ticks: { stroke: 'red' } }}
        > */}
        <VictoryLine
          style={{
            data: { stroke: '#003A70' },
          }}
          data={dataFormat}
          x="name"
          y="Score"
          interpolation="catmullRom"
        />
        <VictoryScatter
          data={dataFormat}
          x="name"
          y="Score"
          style={{
            data: { fill: '#003A70' },
          }}
          size={({ active }) => (active ? 8 : 3)}
        />
        <VictoryAxis
          // dependentAxis
          label="date"
          fixLabelOverlap={true}
          // data={dataFormat}
          // x="name"
          // y="Score"
          // tickValues={timeLabels}
          // tickFormat={(timeLabels) => ''}
          style={{ axis: { stroke: 'none' }, ticks: { stroke: 'red' } }}
        />
        <VictoryAxis
          dependentAxis
          // label="score"
          fixLabelOverlap={true}
          // data={dataFormat}
          x="name"
          y="Score"
          // tickValues={dataFormat}
          style={{ axis: { stroke: 'none' }, ticks: { stroke: 'red' } }}
          // domain={{ x: [0, 100], y: [0, 1] }}
        />
        {/* </VictoryGroup> */}
      </VictoryChart>
      {/* </Card> */}
    </View>
  );
};

export default VictorySingleLineGraph;
