import * as React from 'react';
import { Component } from 'react';
import {
  LayoutAnimation, StyleSheet, View, ScrollView, UIManager, Platform,
} from 'react-native';
import Colors from '../constants/Colors';
// import BoltIconDesktop from './BoltIconDesktop';
// import ChairIconDesktop from './ChairIconDesktop';
// import CheckCircle from './CheckCircle';
// import CoffeeIcon from './CoffeeIcon';
// import GridIcon from './GridIcon';
import HomeIconDesktop from './HomeIconDesktop';
// import MapIcon from './MapIcon';
// import MonitorIcon from './MonitorIcon';
// import SmileIcon from './SmileIcon';
// import SunIcon from './SunIcon';
import ExpandableItemComponent from './ExpandableItemComponent';
import FlagIcon from './FlagIcon';
import ButtonsIcon from './ButtonsIcon';

interface Props {
  navObj: any;
}

interface State {
  listDataSource: any;
  selected: string;
}

export default class ExpandableDrawer extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    if (Platform.OS === 'android') {
      UIManager.setLayoutAnimationEnabledExperimental(true);
    }
    this.state = { listDataSource: CONTENT, selected: '' };
  }

  updateLayout = (index: number) => {
    LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
    const array = [...this.state.listDataSource];
    array[index].isExpanded = !array[index].isExpanded;
    this.setState(() => ({
      listDataSource: array,
    }));
  };

  updateCategory = (selected: string) => {
    this.setState(() => ({
      selected,
    }));
  };

  render() {
    return (
      <View style={styles.container}>
        <ScrollView style={{}}>
          {this.state.listDataSource.map((item: any, key: number) => (
            <ExpandableItemComponent
              key={key}
              onClickFunction={this.updateLayout.bind(this, key)}
              item={item}
              navObj={this.props.navObj}
              updateCategory={this.updateCategory.bind(this, item.category_name)}
              selectedCategory={this.state.selected}
            />
          ))}
        </ScrollView>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    paddingTop: 0,
    minHeight: 400,
  },
});

const CONTENT = [
  {
    isExpanded: true,
    category_name: '',
    subcategory: [
      {
        id: 3,
        val: 'Home',
        icon: <HomeIconDesktop />,
        selectedIcon: <HomeIconDesktop color={Colors.light.hereworksDarkBlue} />,
      },
      {
        id: 13,
        val: 'Reports',
        icon: <FlagIcon />,
        selectedIcon: <FlagIcon color={Colors.light.hereworksDarkBlue} />,
      },
      {
        id: 14,
        val: 'Buttons',
        icon: <ButtonsIcon />,
        selectedIcon: <ButtonsIcon color={Colors.light.hereworksDarkBlue} />,
      },
    ],
  },
  /* {
    isExpanded: true,
    category_name: 'PEOPLE',
    subcategory: [
      {
        id: 4, val: 'Satisfaction', icon: <SmileIcon />,
        selectedIcon: <SmileIcon color={Colors.light.hereworksDarkBlue} />,
      },
      {
        id: 5, val: 'Amenities', icon: <CheckCircle />,
        selectedIcon: <CheckCircle color={Colors.light.hereworksDarkBlue} />,
      },
      {
        id: 6, val: 'Ergonomics', icon: <ChairIconDesktop />,
        selectedIcon: <ChairIconDesktop color={Colors.light.hereworksDarkBlue} />,
      }],
  },
  {
    isExpanded: true,
    category_name: 'BUILDING',
    subcategory: [
      {
        id: 7,
        val: 'Map',
        icon: <MapIcon />,
        selectedIcon: <MapIcon color={Colors.light.hereworksDarkBlue} />,
      },
      {
        id: 8,
        val: 'Occupancy',
        icon: <GridIcon />,
        selectedIcon: <GridIcon color={Colors.light.hereworksDarkBlue} />,
      },
      {
        id: 9,
        val: 'Energy',
        icon: <BoltIconDesktop />,
        selectedIcon: <BoltIconDesktop color={Colors.light.hereworksDarkBlue} />,
      },
      {
        id: 10,
        val: 'IT',
        icon: <MonitorIcon />,
        selectedIcon: <MonitorIcon color={Colors.light.hereworksDarkBlue} />,
      },
      {
        id: 11,
        val: 'ExternalEnvironment',
        title: 'External environment',
        icon: <SunIcon />,
        selectedIcon: <SunIcon color={Colors.light.hereworksDarkBlue} />,
      },
      {
        id: 12,
        val: 'InternalEnvironment',
        title: 'Internal environment',
        icon: <CoffeeIcon />,
        selectedIcon: <CoffeeIcon color={Colors.light.hereworksDarkBlue} />,
      },
    ],
  }, */
];
