/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';

// import { getHappyScoreGroupCounts } from "../requests/GetHappyScoreGroupCounts";
// import { Card } from "react-native-elements";
// eslint-disable-next-line object-curly-newline
import { Text, View } from 'react-native';

import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  CartesianAxis,
  ResponsiveContainer,
} from 'recharts';
import SmallTimeSelector from './SmallTimeSelector';

const SingleLineGraph = (props) => {
  // const [avg, setAvg] = useState(30);
  const [dataFormat, setDataFormat] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [interval, setInterval] = useState(props.interval);
  const [selectedTimeFrame, setTimeFrame] = useState('7d');

  // let interval = "6h";

  const formatted = [];
  let point = {};
  const timeLabels = [];
  const Score = [];

  // eslint-disable-next-line no-unused-vars
  const [option, setOption] = useState(null);
  const buttondata = [
    { value: '1D', timeString: '1d' },
    { value: '1W', timeString: '7d' },
    { value: '1M', timeString: '30d' },
    { value: '3M', timeString: '90d' },
    { value: '6M', timeString: '180d' },
    { value: 'Y', timeString: '365d' },
  ];

  function handleClick(value, timeString) {
    setOption(value);
    setTimeFrame(timeString);
    // console.log(value, timeString);
  }
  useEffect(() => {
    const getData = async () => {
      try {
        fetch(
          'https://hqzse54ic7.execute-api.eu-west-1.amazonaws.com/firstDeploy/',
          {
            headers: {
              'Content-Type': 'application/json',
            },
            method: 'POST',
            mode: 'cors',
            body: JSON.stringify({
              size: 0,
              index: props.index || 'happyscore_flat_permin',
              query: {
                bool: {
                  must: {
                    range: {
                      timestamp: {
                        gte: `now-${selectedTimeFrame}`,
                        lte: 'now',
                      },
                    },
                  },
                },
              },
              aggs: {
                byhour: {
                  date_histogram: {
                    field: 'timestamp',
                    interval: props.interval,
                    min_doc_count: 0,
                  },
                  aggs: {
                    Score: {
                      avg: { field: props.metricName },
                    },
                  },
                },
              },
            }),
          },
        )
          .then((response) => response.json())
          .then((data) => {
            // console.log("data: " + JSON.stringify(data));

            data.aggregations.byhour.buckets.map((e) => {
              Score.push(e.Score.value);
              let hourLabel = e.key_as_string;
              hourLabel = `${new Date(hourLabel)
                .toLocaleDateString()
                .slice(0, 5)
                .replace(/\//g, '.')}.`;
              timeLabels.push(hourLabel);
              point = {
                name: hourLabel,
                Score: Math.floor(e.Score.value * 10) / 10,
              };
              formatted.push(point);
            });

            setDataFormat(formatted);

            // const arrAvg = (arr) => arr.reduce((a, b) => a + b, 0) / arr.length;
            // setAvg(Number(arrAvg(Score).toFixed(0)));
          });
      } catch (error) {
        // console.error(error);
      }
    };
    getData();
  }, [selectedTimeFrame, interval]);
  // ==================================================================================

  return (
    <View>
      {/* <Card containerStyle={{ borderRadius: 24, width: 900 }}> */}
      <View
        style={{
          alignSelf: 'center',
          width: '99%',
          flexDirection: 'row',
        }}
      >
        {/* //======================================================================== */}

        <View
          style={{
            flex: 1,
            marginLeft: 20,
            // alignItems: 'left',
            justifyContent: 'center',
          }}
        >
          <Text style={{ fontSize: 20, fontWeight: 'bold' }}>
            {' '}
            {props.title}
          </Text>
        </View>
        <View
          style={{
            flex: 1,
            paddingTop: 15,
            // alignSelf: "center",
            alignItems: 'center',
          }}
        >
          <SmallTimeSelector
            data={buttondata}
            onSelect={(value, timeString) => handleClick(value, timeString)}
          />
        </View>
        <View
          style={{
            flex: 1,
          }}
        ></View>
      </View>
      <ResponsiveContainer width="99%" height={320}>
        <LineChart
          margin={{
            top: 30,
            right: 30,
            left: 0,
            bottom: 0,
          }}
          data={dataFormat}
        >
          <Line
            type={'monotone'}
            yAxisId="main"
            xAxisId="main"
            dataKey={'Score'}
            data={dataFormat}
            stroke="#003A70"
            strokeWidth={2}
            dot={{
              opacity: 1,
              stroke: '#003A70',
              strokeWidth: 2,
              r: 1.2,
            }}
            activeDot={{ stroke: '#003A70', r: 4 }}
          />
          <CartesianAxis axisLine={false} />
          <XAxis
            xAxisId="main"
            dataKey="name"
            padding={{ left: 30, right: 30 }}
            axisLine={false}
            tickLine={false}
            fontFamily={'Arial'}
            fontSize={12}
          />
          <XAxis
            hide={true}
            xAxisId="other"
            data={dataFormat}
            dataKey="Internal environment"
            padding={{ left: 30, right: 30 }}
          />
          <YAxis
            yAxisId="main"
            axisLine={false}
            tickLine={false}
            fontFamily={'Arial'}
            fontSize={12}
          />

          <CartesianGrid
            opacity={0.5}
            strokeDasharray="5 10 "
            vertical={false}
          />

          <Tooltip
            wrapperStyle={{ fontSize: '14px', fontFamily: 'Arial' }}
            label={{
              stroke: '#696D70',
              fontFamily: 'Arial',
              fontSize: 10,
            }}
          />
        </LineChart>
      </ResponsiveContainer>
      {/* </Card> */}
    </View>
  );
};

// const styles = StyleSheet.create({
//   greyText: {
//     color: '#696D70',
//     alignSelf: 'center',
//     fontFamily: 'Poppins_400Regular',
//     fontSize: 12,
//   },
//   container: {
//     alignItems: 'center',
//     justifyContent: 'center',
//   },
//   textInput: {
//     width: '80%',
//     height: 50,
//     borderColor: 'black',
//     borderWidth: 2,
//   },
// });
export default SingleLineGraph;
