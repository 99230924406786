/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
// import { Card } from 'react-native-elements';
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  CartesianAxis,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import SelectMetric from './SelectMetric';
import SmallTimeSelector from './SmallTimeSelector';

const DualAxisGraph = () => {
  // const [option, setOption] = useState(null);
  const [selectedTimeFrame, setTimeFrame] = useState('7d');

  const buttondata = [
    { value: '1D', timeString: '1d' },
    { value: '1W', timeString: '7d' },
    { value: '1M', timeString: '30d' },
    { value: '3M', timeString: '90d' },
    { value: '6M', timeString: '180d' },
    { value: 'Y', timeString: '365d' },
  ];

  function handleClick(value, timeString) {
    // setOption(value);
    setTimeFrame(timeString);
    // console.log(value, timeString);
  }
  // ===================================================================
  const indexButtonData = [
    {
      value: 'People',
      metric: 'happy_metric_total_building_occupancy_raw_value',
    },
    { value: 'CO2', metric: 'happy_metric_internal_air_co2_raw_value' },
    { value: 'Humidity', metric: 'happy_metric_office_humidity_raw_value' },
    { value: 'Temp', metric: 'happy_metric_office_temp_raw_value' },
    { value: 'PM10', metric: 'data-pm10' },
    { value: 'PM2.5', metric: 'data-pm25' },
    { value: 'TVOC', metric: 'data-voc' },
  ];

  function handleIndexClick(value, metric, prevOption, prevValue) {
    // setOption(value);
    setMetric(metric);
    setMetric2(prevOption);
    setMetricName(value);
    setMetricName2(prevValue);
    // console.log(prevOption, prevValue);
    if (metric === 'data-pm10') {
      setIndex('hw-rawdata-pm10');
    } else if (metric === 'data-pm25') {
      setIndex('hw-rawdata-pm25');
    } else if (metric === 'data-voc') {
      setIndex('hw-rawdata-voc');
    } else {
      setIndex('happyscore_flat_permin');
    }

    if (prevOption === 'data-pm10') {
      setIndex2('hw-rawdata-pm10');
    } else if (prevOption === 'data-pm25') {
      setIndex2('hw-rawdata-pm25');
    } else if (prevOption === 'data-voc') {
      setIndex2('hw-rawdata-voc');
    } else {
      setIndex2('happyscore_flat_permin');
    }
    // console.log(value, metric);
  }
  // const { width, height } = Dimensions.get("window");

  const [index, setIndex] = useState('happyscore_flat_permin');
  const [index2, setIndex2] = useState('happyscore_flat_permin');

  const [metricString, setMetric] = useState(
    'happy_metric_internal_air_co2_raw_value',
  );
  const [metricString2, setMetric2] = useState(
    'happy_metric_total_building_occupancy_raw_value',
  );

  const [metricName, setMetricName] = useState('CO2');
  const [metricName2, setMetricName2] = useState('People');

  const [dataFormat, setDataFormat] = useState([]);
  const [dataFormat2, setDataFormat2] = useState([]);

  const interval = '2h';
  const humidGraphArray = [];
  let point = {};
  const peopleGraphArray = [];
  const timeLabels = [];
  const humidData = [];
  const peopleData = [];

  useEffect(() => {
    const getData = async () => {
      fetch(
        'https://hqzse54ic7.execute-api.eu-west-1.amazonaws.com/firstDeploy/',
        {
          headers: {
            'Content-Type': 'application/json',
          },
          method: 'POST',
          mode: 'cors',
          body: JSON.stringify({
            size: 0,
            index,
            query: {
              bool: {
                must: {
                  range: {
                    timestamp: { gte: `now-${selectedTimeFrame}`, lte: 'now' },
                  },
                },
              },
            },
            aggs: {
              byhour: {
                date_histogram: {
                  field: 'timestamp',
                  interval,
                  min_doc_count: 0,
                },
                aggs: { humidity: { avg: { field: metricString } } },
              },
            },
          }),
        },
      )
        .then((response) => response.json())
        .then((data) => {
          // console.log("data: " + JSON.stringify(data));

          data.aggregations.byhour.buckets.map((e) => {
            humidData.push(e.humidity.value);
            let hourLabel = e.key_as_string;
            // console.log(humidData);

            hourLabel = new Date(hourLabel)
              .toLocaleDateString()
              .slice(0, 5)
              .replace(/\//g, '.');
            timeLabels.push(hourLabel);
            point = {
              name: hourLabel,
              humidData: Math.floor(e.humidity.value * 10) / 10,
            };
            humidGraphArray.push(point);
          });

          // console.log(".then: " + JSON.stringify(humidGraphArray));
          setDataFormat(humidGraphArray);
        });
      fetch(
        'https://hqzse54ic7.execute-api.eu-west-1.amazonaws.com/firstDeploy/',
        {
          headers: {
            'Content-Type': 'application/json',
          },
          method: 'POST',
          mode: 'cors',
          body: JSON.stringify({
            size: 0,
            // index: "hw-rawdata-buildingoccupancy"
            index: index2,
            query: {
              bool: {
                must: {
                  range: {
                    timestamp: { gte: `now-${selectedTimeFrame}`, lte: 'now' },
                  },
                },
              },
            },
            aggs: {
              byhour: {
                date_histogram: {
                  field: 'timestamp',
                  interval,
                  min_doc_count: 0,
                },
                aggs: { humidity: { avg: { field: metricString2 } } },
              },
            },
          }),
        },
      )
        .then((response) => response.json())
        .then((data) => {
          // console.log("data: " + JSON.stringify(data));

          data.aggregations.byhour.buckets.map((e) => {
            peopleData.push(e.humidity.value);
            // let hourLabel = e.key_as_string;
            // hourLabel = new Date(hourLabel).toDateString();
            // hourLabel = hourLabel.slice(3, 10);
            // timeLabels.push(hourLabel);
            point = {
              peopleData: Math.floor(e.humidity.value * 10) / 10,
            };
            peopleGraphArray.push(point);
          });
          // console.log(".then: " + JSON.stringify(peopleGraphArray));
          setDataFormat2(peopleGraphArray);
        });
    };
    // console.log("calling getdata");

    getData();
  }, [index, selectedTimeFrame, metricString]);

  return (
    <View
      style={
        {
          // flex: 1,
          // justifyContent: "center",
          // alignItems: "center",
          // width: { width },
        }
      }
    >
      <View
        style={{
          alignSelf: 'center',
          paddingTop: 20,
        }}
      >
        {/* //================================================================================ */}
        <SmallTimeSelector
          data={buttondata}
          onSelect={(value, timeString) => handleClick(value, timeString)}
        />
      </View>
      {/* <Card containerStyle={{ borderRadius: 24, width: 900, height: 400 }}> */}
      <ResponsiveContainer width="99%" height={320}>
        <LineChart
          data={[dataFormat]}
          margin={{
            top: 30,
            right: 30,
            left: 0,
            bottom: 0,
          }}
          width={900}
          height={400}
        >
          <defs>
            <linearGradient id="colorhappyscore" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#D6006D" stopOpacity={1} />
              <stop offset="50%" stopColor="#F5B335" stopOpacity={1} />
              <stop offset="95%" stopColor="#025157" stopOpacity={1} />
            </linearGradient>
          </defs>
          {/* <CartesianGrid stroke="#ccc" strokeDasharray="0.5 2" /> */}
          <YAxis
            yAxisId="left"
            axisLine={false}
            tickLine={false}
            fontFamily={'Arial'}
            fontSize={12}
          />
          <YAxis
            yAxisId="right"
            orientation="right"
            axisLine={false}
            tickLine={false}
            fontFamily={'Arial'}
            fontSize={12}
          />
          <Line
            xAxisId="utility"
            yAxisId="left"
            name={metricName}
            type="monotone"
            data={dataFormat}
            dataKey={'humidData'}
            stroke="orange"
            activeDot={{ stroke: 'black', r: 0.5 }}
            strokeWidth={2.3}
            dot={{ stroke: 'blue', strokeWidth: 0, r: 0 }}
          />
          <Line
            xAxisId="people"
            yAxisId="right"
            name={metricName2}
            type="monotone"
            dataKey={'peopleData'}
            data={dataFormat2}
            // stroke="blue"
            stroke="#696D70"
            dot={{ stroke: 'blue', strokeWidth: 0, r: 0 }}
            strokeWidth={1}
            activeDot={{ stroke: 'black', r: 0.5 }}
          />
          {/* <Legend wrapperStyle={{top: 0, right: 0}}/> */}
          <Legend />
          <Tooltip labelFormatter={(name) => name} />
          <CartesianGrid
            opacity={0.5}
            strokeDasharray="5 10 "
            vertical={false}
          />
          <XAxis
            hide={true}
            xAxisId="people"
            data={dataFormat2}
            dataKey="name"
            padding={{ left: 30, right: 30 }}
          />
          <XAxis
            xAxisId="utility"
            data={dataFormat}
            dataKey="name"
            axisLine={false}
            tickLine={false}
            fontFamily={'Arial'}
            fontSize={12}
            padding={{ left: 30, right: 30 }}
          />
          <CartesianAxis axisLine={false} />
        </LineChart>
      </ResponsiveContainer>

      <View
        style={{
          alignSelf: 'center',
          paddingTop: 20,
          paddingBottom: 20,
        }}
      >
        <SelectMetric
          data={indexButtonData}
          onSelect={(
            value: any,
            metric: any,
            prevOption: any,
            prevValue: any,
          ) => handleIndexClick(value, metric, prevOption, prevValue)}
        />
      </View>
      {/* </Card> */}
    </View>
  );
};

// const styles = StyleSheet.create({
//   greyText: {
//     color: '#696D70',
//     alignSelf: 'center',
//     fontFamily: 'Poppins_400Regular',
//     fontSize: 12,
//   },
//   container: {
//     alignItems: 'center',
//     justifyContent: 'center',
//   },
//   textInput: {
//     width: '80%',
//     height: 50,
//     borderColor: 'black',
//     borderWidth: 2,
//   },
// });
export default DualAxisGraph;
