/* eslint-disable import/prefer-default-export */
import { GetSingleMetric } from './GetSingleMetric';

export const GetMultipleMetric = async (timeString) => {
  // let timestring="7d"
  try {
    const res = await Promise.all([
      GetSingleMetric(
        timeString,
        'happy_group_internal_environment_local_happyscore_percent',
      ),
      GetSingleMetric(
        timeString,
        'happy_group_external_environment_local_happyscore_percent',
      ),
      GetSingleMetric(timeString, 'happy_group_it_local_happyscore_percent'),
      GetSingleMetric(
        timeString,
        'happy_group_energy_usage_local_happyscore_percent',
      ),
      GetSingleMetric(
        timeString,
        'happy_group_occupant_satisfaction_local_happyscore_percent',
      ),
    ]);
    const data = await Promise.all(res.map((r) => r.json()));
    // console.log(data.flat());
    return data;
  } catch {
    throw Error('Promise failed');
  }
};
