import React, { useState } from 'react';
import {
  View, Image, StyleSheet, Text, Animated,
} from 'react-native';
import { useCountUp } from 'use-count-up';
import Colors from '../constants/Colors';
import Style from '../constants/Style';
import getHappyScoreGroupCounts from '../requests/GetHappyScoreGroupCounts';
import CloudIcon from './CloudIcon';

type Props = {
    category: string;
}

const HappyBuilding = require('../assets/images/happy_building.png');
const MediumBuilding = require('../assets/images/medium_building.png');
const SadBuilding = require('../assets/images/sad_building.png');

const ScoreCard = (props: Props) => {
  const [happyScore, setHappyScore] = useState(0);
  const [lastMonthAverage, setLastMonthAverage] = useState(0);
  const [lastThreeMonthAverage, setLastThreeMonthAverage] = useState(0);
  const [allTimeAverage, setAllTimeAverage] = useState(0);
  const [changeFromYesterday, setChangeFromYesterday] = useState(0);

  const { value } = useCountUp({
    isCounting: true,
    end: happyScore,
    duration: 6,
    easing: 'easeOutCubic',
  });

  const [happyColor, setHappyColor] = useState(Colors.light.hereworksPink);
  const [cardBackground, setCardBackground] = useState('#BBC5CA');
  const [buildingImage, setBuildingImage] = useState(SadBuilding);
  const [cloudOneInset] = useState(new Animated.Value(0));
  const [cloudTwoInset] = useState(new Animated.Value(0));
  const [cloudThreeInset] = useState(new Animated.Value(0));
  const [cloudFourInset] = useState(new Animated.Value(-150));

  React.useEffect(() => {
    getHappyScoreGroupCounts().then((gc) => {
      const { category } = props;

      if (gc?.iserr) {
        return;
      }

      const filteredGroups = gc?.filter((group: any) => group.id === category);

      const currentGroup = filteredGroups[0];
      if (currentGroup !== undefined) {
        const newScore = parseInt(currentGroup.percentage, 10);
        setHappyScore(newScore);
        const monthAverage = parseInt(currentGroup.last_month_average_percentage ?? 0, 10);
        setLastMonthAverage(monthAverage);
        const threeMonthAverage = parseInt(currentGroup.last_three_months_average_percentage
          ?? 0, 10);
        setLastThreeMonthAverage(threeMonthAverage);
        const allTimeAverageValue = parseInt(currentGroup.all_time_average_percentage ?? 0, 10);
        setAllTimeAverage(allTimeAverageValue);
        const yesterdayDiff = parseInt(currentGroup.today_vs_yesterday_diff ?? 0, 10);
        setChangeFromYesterday(yesterdayDiff);
      } else {
        setHappyScore(85);
      }
    });
  }, []);

  React.useEffect(() => {
    const currentValue = parseInt(value?.toString() ?? '', 10);

    if (currentValue < 25) {
      setHappyColor(Colors.light.hereworksPink);
      setBuildingImage(SadBuilding);
      setCardBackground('#BBC5CA');
    } else if (currentValue < 65) {
      setHappyColor(Colors.light.hereworksOrange);
      setBuildingImage(MediumBuilding);
      setCardBackground('#EDDDBE');
    } else {
      setHappyColor(Colors.light.hereworksExtraDarkTeal);
      setBuildingImage(HappyBuilding);
      setCardBackground('#D6F1FA');
    }
  }, [value]);

  React.useEffect(() => {
    Animated.parallel([
      Animated.timing(cloudOneInset, {
        toValue: 315,
        duration: 2000,
        useNativeDriver: true,
      }),
      Animated.timing(cloudTwoInset, {
        toValue: 285,
        duration: 4000,
        useNativeDriver: true,
      }),
      Animated.timing(cloudThreeInset, {
        toValue: 333,
        duration: 6000,
        useNativeDriver: true,
      }),
      Animated.timing(cloudFourInset, {
        toValue: -24,
        duration: 3000,
        useNativeDriver: true,
      }),
    ]).start();
  }, []);

  return (
        <View style={{ flexDirection: 'column' }}>
        <Text style={styles.overviewHeading}>Overview</Text>
            <View style={[styles.scoreCard, { backgroundColor: cardBackground }]}>
                <View style={styles.happyScore}>
                    <Text style={[styles.happyScoreValue, { color: happyColor }]}
                    numberOfLines={1}>{value}</Text>
                    <View style={styles.totalScoreWrapper}>
                        <Text style={styles.totalScore}>Total score</Text>
                        <Text style={styles.changeFrom}>{changeFromYesterday >= 0 && '+'}{changeFromYesterday} from yesterday</Text>
                    </View>
                </View>
                <View style={styles.averages}>
                    <View style={styles.averageItem}>
                        <Text style={styles.averageValue}>{allTimeAverage}</Text>
                        <Text style={styles.subTitleAverage}>All time average</Text>
                    </View>
                    <View style={styles.averagesDivider} />
                    <View style={styles.averageItem}>
                        <Text style={styles.averageValue}>{lastMonthAverage}</Text>
                        <Text style={styles.subTitleAverage}>Avg. last month</Text>
                    </View>
                    <View style={styles.averagesDivider} />
                    <View style={styles.averageItem}>
                        <Text style={styles.averageValue}>{lastThreeMonthAverage}</Text>
                        <Text style={styles.subTitleAverage}>Avg. last 3 months</Text>
                    </View>
                </View>
                <View style={[styles.problemsView, Style.boxShadow]}>
                    <Text style={styles.subCount}>101</Text>
                    <Text style={styles.subTitle}>Problems reported</Text>
                </View>
                <View style={[styles.surveysView, Style.boxShadow]}>
                    <Text style={styles.subCount}>101</Text>
                    <Text style={styles.subTitle}>Submitted surveys</Text>
                </View>
                <Image source={buildingImage} style={styles.buildingImageDesktop} />
                <Animated.View style={{
                  position: 'absolute', top: 24, left: 0, transform: [{ translateX: cloudOneInset }], zIndex: -1,
                }}><CloudIcon /></Animated.View>
                <Animated.View style={{
                  position: 'absolute', top: 81, left: 0, transform: [{ translateX: cloudTwoInset }], zIndex: -1,
                }}><CloudIcon /></Animated.View>
                <Animated.View style={{
                  position: 'absolute', top: 176, left: 0, transform: [{ translateX: cloudThreeInset }], zIndex: -1,
                }}><CloudIcon /></Animated.View>
                <Animated.View style={{
                  position: 'absolute', top: 324, left: 0, transform: [{ translateX: cloudFourInset }], zIndex: -1,
                }}><CloudIcon /></Animated.View>
            </View>
        </View>
  );
};

const styles = StyleSheet.create({
  scoreCard: {
    marginTop: 16,
    marginBottom: 32,
    marginLeft: 40,
    marginRight: 24,
    borderRadius: 24,
    width: 370,
    height: 408,
    overflow: 'hidden',
  },
  problemsView: {
    height: 48,
    borderRadius: 24,
    marginHorizontal: 24,
    marginBottom: 16,
    backgroundColor: 'white',
    alignItems: 'center',
    flexDirection: 'row',
  },
  surveysView: {
    height: 48,
    borderRadius: 24,
    marginHorizontal: 24,
    backgroundColor: 'white',
    alignItems: 'center',
    flexDirection: 'row',
  },
  averages: {
    height: 64,
    marginVertical: 32,
    backgroundColor: 'transparent',
    flexDirection: 'row',
    width: '100%',
  },
  happyScore: {
    marginTop: 20,
    marginHorizontal: 24,
    backgroundColor: 'transparent',
    flexDirection: 'row',
    alignItems: 'center',
  },
  buildingImageDesktop: {
    position: 'absolute',
    alignSelf: 'center',
    bottom: 0,
    zIndex: -1,
    height: 180,
    width: 194,
  },
  subTitle: {
    fontFamily: 'Poppins_400Regular',
    fontSize: 14,
    color: Colors.light.hereworksPrimaryGrey900,
  },
  subTitleAverage: {
    fontFamily: 'Poppins_400Regular',
    fontSize: 14,
    lineHeight: 16.8,
    color: Colors.light.hereworksPrimaryGrey900,
  },
  subCount: {
    fontFamily: 'Poppins_600SemiBold',
    fontSize: 24,
    color: Colors.light.hereworksDarkBlue,
    marginLeft: 20,
    marginRight: 8,
  },
  averageValue: {
    fontFamily: 'Poppins_600SemiBold',
    fontSize: 24,
    justifyContent: 'flex-start',
    textAlign: 'left',
    textAlignVertical: 'top',
    marginBottom: 6,
    color: Colors.light.hereworksDarkBlue,
    alignSelf: 'flex-start',
  },
  happyScoreValue: {
    fontFamily: 'Poppins_600SemiBold',
    fontSize: 60,
    justifyContent: 'center',
    textAlign: 'left',
    textAlignVertical: 'top',
    width: 100,
  },
  totalScore: {
    fontFamily: 'Poppins_400Regular',
    fontSize: 12,
    color: Colors.light.hereworksPrimaryGrey800,
    justifyContent: 'center',
    textAlign: 'left',
  },
  changeFrom: {
    fontFamily: 'Poppins_400Regular',
    fontSize: 16,
    color: Colors.light.hereworksExtraDarkTeal,
    justifyContent: 'center',
    textAlign: 'left',
  },
  totalScoreWrapper: {
    alignContent: 'flex-end',
    height: 46,
    marginLeft: 11,
  },
  averagesDivider: {
    width: 1.5,
    height: 64,
    backgroundColor: 'white',
  },
  averageItem: {
    marginHorizontal: 24,
    flex: 1,
    height: 64,
  },
  overviewHeading: {
    fontFamily: 'Poppins_400Regular',
    fontSize: 22,
    marginLeft: 40,
    marginTop: 32,
    color: Colors.light.hereworksPrimaryGrey,
  },
});

export default ScoreCard;
