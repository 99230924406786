import * as React from 'react';
import Svg, {
  SvgProps, G, Rect, Path, Defs, ClipPath,
} from 'react-native-svg';

const AvatarIcon = (props: SvgProps) => (
  <Svg width={30} height={30} fill="none" {...props}>
    <G clipPath="url(#a)">
      <Rect width={30} height={30} rx={15} fill="#E5ECF0" />
      <Path
        d="M25 30.938V23.75a5 5 0 0 0-5-5H10a5 5 0 0 0-5 5v7.188M15 16.563a5 5 0 1 0 0-10 5 5 0 0 0 0 10Z"
        fill="#6892C0"
      />
    </G>
    <Defs>
      <ClipPath id="a">
        <Rect width={30} height={30} rx={15} fill="#fff" />
      </ClipPath>
    </Defs>
  </Svg>
);

export default AvatarIcon;
