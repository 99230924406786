import React from 'react';
import { StyleSheet } from 'react-native';
import NotificationsCard from '../components/NotificationsCard';

import { Text, View } from '../components/Themed';
import { RootTabScreenProps } from '../types';

// eslint-disable-next-line no-unused-vars
export default function NotificationsScreen({ navigation }: RootTabScreenProps<'Notifications'>) {
  return (
    <View style={styles.container}>
      <Text style={styles.title}>Notifications</Text>
      <NotificationsCard />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'white',
    alignItems: 'center',
  },
  title: {
    fontSize: 18,
    fontFamily: 'Roboto_500Medium',
    alignItems: 'center',
    marginTop: 58,
    marginBottom: 27,
    color: 'black',
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: '80%',
  },
});
