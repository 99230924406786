/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';

// import { Card } from 'react-native-elements';

import { FontAwesome } from '@expo/vector-icons';
// eslint-disable-next-line object-curly-newline
import { Text, View, StyleSheet, TouchableOpacity } from 'react-native';

import {
  XAxis,
  YAxis,
  Tooltip,
  AreaChart,
  Area,
  CartesianAxis,
  ReferenceArea,
  ResponsiveContainer,
} from 'recharts';
import DropdownSelector from './DropdownSelector';

const ImprovedBasicGraph = () => {
  // const { width, height } = Dimensions.get("window");
  const [avg, setAvg] = useState(30);
  const [dataFormat, setDataFormat] = useState([]);
  const interval = '24h';

  const [selected, setSelected] = useState({ label: 'Week', value: '7d' });
  const dataDrop = [
    { label: 'Day', value: '1d' },
    { label: 'Week', value: '7d' },
    { label: 'Month', value: '30d' },
    { label: '3 Months', value: '90d' },
    { label: '6 Months', value: '180d' },
    { label: 'Year', value: '365d' },
  ];

  // let interval = "6h";
  const formatted = [];
  let point = {};
  const timeLabels = [];
  const happyscore = [];
  useEffect(() => {
    const getData = async () => {
      try {
        fetch(
          'https://hqzse54ic7.execute-api.eu-west-1.amazonaws.com/firstDeploy/',
          {
            headers: {
              'Content-Type': 'application/json',
            },
            method: 'POST',
            mode: 'cors',
            body: JSON.stringify({
              size: 0,
              index: 'happyscore_flat_permin',
              query: {
                bool: {
                  must: {
                    range: {
                      timestamp: {
                        gte: `now-${selected.value}`,
                        lte: 'now',
                      },
                    },
                  },
                },
              },
              aggs: {
                byhour: {
                  date_histogram: {
                    field: 'timestamp',
                    interval,
                    min_doc_count: 0,
                  },
                  aggs: { humidity: { avg: { field: 'happyscore' } } },
                },
              },
            }),
          },
        )
          .then((response) => response.json())
          .then((data) => {
            // console.log(`data: ${JSON.stringify(data)}`);

            data.aggregations.byhour.buckets.map((e) => {
              happyscore.push(e.humidity.value);
              let hourLabel = e.key_as_string;
              // console.log(happyscore);

              hourLabel = new Date(hourLabel).toLocaleDateString();
              hourLabel = hourLabel.slice(0, 5).replace(/\//g, '.');
              // console.log(hourLabel);
              timeLabels.push(hourLabel);
              point = {
                name: hourLabel,
                happyscore: Math.floor(e.humidity.value * 10) / 10,
              };
              formatted.push(point);
            });
            // console.log(`.then: ${JSON.stringify(formatted)}`);
            setDataFormat(formatted);
            const arrAvg = (arr) => arr.reduce((a, b) => a + b, 0) / arr.length;
            setAvg(Number(arrAvg(happyscore).toFixed(0)));
          });
      } catch (error) {
        // console.error(error);
      }
    };
    getData();
  }, [selected, interval]);

  return (
    <View
      style={{
        flex: 1,
        // justifyContent: "center",
        // alignItems: "center",
        // width: { width },
      }}
    >
      {/* <Card containerStyle={{ borderRadius: 24, width: 500 }}> */}
      <View style={{ flexDirection: 'row', alignSelf: 'center' }}>
        <DropdownSelector
          label="Week"
          dataDrop={dataDrop}
          onSelect={setSelected}
        />

        <TouchableOpacity style={styles.buttonBlue}>
          <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
            <Text style={styles.buttonBlueText}>Check More Details</Text>
            <FontAwesome
              size={23}
              style={{
                color: '#fff',
                marginBottom: 2,
                flex: 1,
                marginLeft: 10,
              }}
              name={'angle-right'}
            />

            {/* <RightArrowIcon style={{ alignSelf: "flex-end", margin: 4 }} /> */}
          </View>
        </TouchableOpacity>
      </View>
      <ResponsiveContainer width="99%" height={150}>
        <AreaChart
          margin={{
            top: 10,
            right: 30,
            left: 0,
            bottom: 0,
          }}
          data={dataFormat}
        >
          <defs>
            <linearGradient id="colorhappyscore" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#003A70" stopOpacity={0.2} />
              <stop offset="95%" stopColor="#003A70" stopOpacity={0} />
            </linearGradient>
          </defs>
          <Area
            type={'monotone'}
            dataKey="happyscore"
            stroke="#003A70"
            fill="url(#colorhappyscore)"
            strokeWidth={2}
            dot={{
              opacity: 1,
              stroke: '#003A70',
              strokeWidth: 2,
              r: 1,
            }}
            activeDot={{ stroke: '#003A70', r: 4 }}
          />
          <CartesianAxis axisLine={false} />
          <XAxis
            dataKey="name"
            padding={{ left: 30, right: 30 }}
            axisLine={false}
            tickLine={false}
            fontFamily={'Arial'}
            fontSize={12}
          />
          <YAxis
            axisLine={false}
            tickLine={false}
            fontFamily={'Arial'}
            fontSize={12}
            domain={[70, 90]}
          />
          <Tooltip
            wrapperStyle={{ fontSize: '17px', fontFamily: 'Arial' }}
            label={{
              stroke: '#696D70',
              fontFamily: 'Arial',
              fontSize: 12,
            }}
          />

          <ReferenceArea
            // x1={"Apr 16"}
            // x2={"Apr 19"}
            y1={avg.toString()}
            y2={(avg + 0.01).toString()}
            stroke="#696D70"
            strokeDasharray="10 9"
            strokeWidth={2}
            strokeOpacity={1}
            label={{
              value: `${avg} avg`,
              position: 'right',
              stroke: '#696D70',
              fontFamily: 'Arial',
              fontSize: 12,
            }}
          />
        </AreaChart>
      </ResponsiveContainer>
      {/* </Card> */}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonBlue: {
    backgroundColor: '#003A70',
    padding: 3,
    borderRadius: 150,
    width: 150,
    marginLeft: 30,
    justifyContent: 'center',
    height: 27,
  },
  buttonBlueText: {
    color: 'white',
    alignSelf: 'center',
    fontSize: 13,
    marginLeft: 5,
  },
});
export default ImprovedBasicGraph;
