/* eslint-disable object-curly-newline */
import React, { FC, ReactElement, useRef, useState } from 'react';
import {
  FlatList,
  StyleSheet,
  Text,
  TouchableOpacity,
  Modal,
  View,
} from 'react-native';
import { FontAwesome } from '@expo/vector-icons';

interface Props {
  label: string;
  dataDrop: Array<{ label: string; value: string }>;
  // eslint-disable-next-line no-unused-vars
  onSelect: (item: { label: string; value: string }) => void;
}

const DropdownSelector: FC<Props> = ({ label, dataDrop, onSelect }) => {
  const DropdownButton = useRef();
  const [visible, setVisible] = useState(false);
  const [selected, setSelected] = useState({ label: 'Week', value: '7d' });
  const [dropdownTop, setDropdownTop] = useState(0);
  const [dropdownLeft, setDropdownLeft] = useState(0);

  const toggleDropdown = (): void => {
    // eslint-disable-next-line no-unused-expressions
    visible ? setVisible(false) : openDropdown();
    // console.log("toggle")
  };

  const openDropdown = (): void => {
    DropdownButton.current.measure(
      (
        _fx: number,
        _fy: number,
        w: number,
        h: number,
        px: number,
        py: number,
      ) => {
        setDropdownTop(py + h + 10);
        // console.log(w);
        setDropdownLeft(px + 10);
      },
    );
    // console.log("drop")
    // setDropdownTop(40)

    setVisible(true);
  };

  const onItemPress = (item: any): void => {
    setSelected(item);
    onSelect(item);
    setVisible(false);
    // console.log(`itempress: ${JSON.stringify(item)}`);
  };

  const renderItem = ({ item }: any): ReactElement<any, any> => (
    <TouchableOpacity
      style={
        item.label === selected.label ? styles.selected : styles.unselected
      }
      onPress={() => onItemPress(item)}
    >
      <Text
        style={
          item.label === selected.label
            ? styles.selectedText
            : styles.unselectedText
        }
      >
        {item.label}
      </Text>
    </TouchableOpacity>
  );

  const FlatListItemSeparator = () => <View style={styles.line} />;

  const renderDropdown = (): ReactElement<any, any> => (
    // console.log("render")
    <Modal visible={visible} transparent animationType="none">
      <TouchableOpacity
        style={styles.overlay}
        onPress={() => setVisible(false)}
      >
        <View
          style={[styles.dropdown, { top: dropdownTop, left: dropdownLeft }]}
        >
          <FlatList
            ItemSeparatorComponent={FlatListItemSeparator}
            data={dataDrop}
            renderItem={renderItem}
            keyExtractor={(item, index) => index.toString()}
          />
        </View>
      </TouchableOpacity>
    </Modal>
  );
  return (
    <TouchableOpacity
      ref={DropdownButton}
      style={styles.button}
      onPress={toggleDropdown}
    >
      {renderDropdown()}
      <Text style={styles.buttonText}>
        {(!!selected && selected.label) || label}
      </Text>
      <FontAwesome
        size={25}
        style={{ color: '#696D70', marginBottom: 3, flex: 1 }}
        name={visible ? 'angle-down' : 'angle-up'}
      />
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  item: {
    paddingHorizontal: 10,
    paddingVertical: 10,
    textAlign: 'center',
    borderRadius: 8,
  },
  unselected: {
    backgroundColor: '#fff',
    paddingHorizontal: 10,
    paddingVertical: 10,
    textAlign: 'center',
    borderRadius: 8,
  },
  selected: {
    backgroundColor: '#003A70',
    paddingHorizontal: 10,
    paddingVertical: 10,
    textAlign: 'center',
    borderRadius: 8,
  },
  selectedText: {
    color: 'white',
    alignSelf: 'center',
    // fontSize: 15,
  },
  unselectedText: {
    color: '#696D70',
    alignSelf: 'center',
    // fontSize: 15,
  },
  line: {
    height: 2,
    width: '73%',
    backgroundColor: '#EAECEE',
    alignSelf: 'center',
  },
  button: {
    flexDirection: 'row',
    alignItems: 'center',
    // padding: 3,
    backgroundColor: '#fff',
    zIndex: 1,
    borderRadius: 150,
    marginRight: 20,
    marginLeft: 20,
    width: 100,
    height: 27,
  },
  buttonText: {
    flex: 3,
    textAlign: 'center',
    color: '#696D70',
    fontSize: 13,
  },
  dropdown: {
    position: 'relative',
    backgroundColor: '#fff',
    width: 80,
    shadowColor: '#001F311A',
    shadowRadius: 4,
    shadowOffset: { height: 4, width: 0 },
    shadowOpacity: 1,
    borderRadius: 12,
  },
  overlay: {
    // width: 60,
    height: '100%',
    borderRadius: 24,
    // alignItems: 'center',
  },
});

export default DropdownSelector;
