import React, { useState } from 'react';
import { StyleSheet, TouchableOpacity, ImageBackground } from 'react-native';
import { gestureHandlerRootHOC, PanGestureHandler, PanGestureHandlerGestureEvent } from 'react-native-gesture-handler';
import Animated, {
  useAnimatedGestureHandler, useAnimatedStyle, useSharedValue, withTiming,
} from 'react-native-reanimated';
import Svg, { Polygon } from 'react-native-svg';

import { Text, View } from '../components/Themed';
import Colors from '../constants/Colors';
import Style from '../constants/Style';
import getFloorPlan from '../requests/GetFloorPlan';
import { RootTabScreenProps } from '../types';

type ContextType = {
    translateX: number;
    translateY: number;
}

// eslint-disable-next-line no-unused-vars
export default function MapScreen({ navigation }: RootTabScreenProps<'Map'>) {
  React.useEffect(() => {
    getFloorPlan().then((floorPlans) => {
      setFloorPlans(floorPlans);
    });
  }, []);

  // eslint-disable-next-line prefer-const
  let [scaleValue, updateScaleValue] = useState(1);

  const groundFloor = 'https://hereworks.tech/floorplans/hereworks/Hereworks-Ashbourne-Floorplan-Ground-Floor-15Nov2021.png';
  const firstFloor = 'https://hereworks.tech/floorplans/hereworks/Hereworks-Ashbourne-Floorplan-First-Floor-15Nov2021.png';

  const [floorPlan, setFloorPlan] = useState(firstFloor);
  const [floorPlans, setFloorPlans] = useState(firstFloor);

  const scale = useSharedValue(scaleValue);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedDesk, setSelectedDesk] = useState('');

  const translateX = useSharedValue(0);
  const translateY = useSharedValue(0);

  const panGesture = useAnimatedGestureHandler<PanGestureHandlerGestureEvent, ContextType>(
    {
      onStart: (event, context) => {
        context.translateX = translateX.value;
        context.translateY = translateY.value;
      },
      onActive: (event, context) => {
        translateX.value = event.translationX + context.translateX;
        translateY.value = event.translationY + context.translateY;
      },
    },
  );

  const transformStyle = useAnimatedStyle(() => ({
    transform: [
      {
        scale: scaleValue,
      },
      {
        translateX: translateX.value,
      },
      {
        translateY: translateY.value,
      },
    ],
  }));

  if (floorPlans.length > 0) {
    // TODO: hook up here
  }

  const Wrapper = gestureHandlerRootHOC(() => (
    <View style={styles.container}>
            <TouchableOpacity
                onPress={() => { setModalVisible(!modalVisible); }}
                style={[styles.desktopModalContainer, Style.boxShadow, { height: modalVisible ? '100%' : 0, flex: modalVisible ? 1 : undefined }]}
            >
                <View style={styles.desktopModal}>
                    <Text style={{
                      fontFamily: 'Poppins_600SemiBold',
                      fontSize: 36,
                      color: Colors.light.hereworksDarkBlue,
                    }}>Booking Information</Text>
                    <Text style={{
                      fontFamily: 'Poppins_400Regular',
                      fontSize: 26,
                      color: Colors.light.hereworksDarkBlue,
                    }}>{selectedDesk}</Text>
                </View>
            </TouchableOpacity>
            <View style={{
              flexDirection: 'row', backgroundColor: 'transparent', justifyContent: 'space-around', marginTop: 30,
            }}>
                <TouchableOpacity style={styles.zoomButton} onPress={() => {
                  scaleValue += 0.2;
                  scale.value = withTiming(scaleValue);
                  updateScaleValue(scaleValue);
                }}>
                    <Text style={styles.buttonText}>+</Text>
                </TouchableOpacity>
                <TouchableOpacity style={styles.zoomButton} onPress={() => {
                  scaleValue -= 0.2;
                  scale.value = withTiming(scaleValue);
                  updateScaleValue(scaleValue);
                }}>
                    <Text style={styles.buttonText}>-</Text>
                </TouchableOpacity>
            </View>
            <View style={{
              backgroundColor: 'transparent', justifyContent: 'space-around', position: 'absolute', right: 10, bottom: 10,
            }}>
                <TouchableOpacity style={styles.floorButton} onPress={() => {
                  setFloorPlan(groundFloor);
                }}>
                    <Text style={styles.buttonText}>Ground</Text>
                </TouchableOpacity>
                <TouchableOpacity style={styles.floorButton} onPress={() => {
                  setFloorPlan(firstFloor);
                }}>
                    <Text style={styles.buttonText}>First</Text>
                </TouchableOpacity>
            </View>
            {/* @ts-ignore */}
            <PanGestureHandler onGestureEvent={panGesture}>
                <Animated.View
                    style={[{
                      zIndex: -1, flex: 1, height: undefined, width: '100%',
                    },
                    transformStyle,
                    ]}
                >
                    <ImageBackground
                        style={{ width: 1275, height: 1169 }}
                        resizeMode={'cover'}
                        source={{
                          uri: floorPlan,
                        }}
                    >
                        {floorPlan === firstFloor
                            && <>
                                <TouchableOpacity
                                    onPress={() => {
                                      setSelectedDesk('Curie Desk 7');
                                      setModalVisible(!modalVisible);
                                    }}
                                    style={{
                                      top: 492, left: 937, width: 39, height: 83, backgroundColor: 'green', opacity: 0.5,
                                    }}
                                />
                                <TouchableOpacity
                                    style={{ position: 'absolute' }}
                                    onPress={() => {
                                      setSelectedDesk('Pythagoras Desk 4');
                                      setModalVisible(!modalVisible);
                                    }}
                                >
                                    <Svg
                                        style={{
                                          position: 'absolute', top: 650, left: 132, width: 60, height: 83, zIndex: 2, opacity: 0.5,
                                        }}
                                    >
                                        <Polygon
                                            points={'30 0, 60 0, 60 82, 0 82, 0 53, 30 53'}
                                            stroke={'none'}
                                            fill={'green'}
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </Svg>
                                </TouchableOpacity>
                                <TouchableOpacity
                                    style={{ position: 'absolute' }}
                                    onPress={() => {
                                      setSelectedDesk('Pythagoras Desk 3');
                                      setModalVisible(!modalVisible);
                                    }}
                                >
                                    <Svg
                                        style={{
                                          position: 'absolute', top: 650, left: 192, width: 30, height: 83, zIndex: 2, opacity: 0.5,
                                        }}
                                    >
                                        <Polygon
                                            points={'0 0, 26 0, 26 82, 0 82'}
                                            stroke={'none'}
                                            fill={'green'}
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </Svg>
                                </TouchableOpacity>
                            </>
                        }
                    </ImageBackground>
                </Animated.View>
            </PanGestureHandler>
        </View>
  ));

  return (
    <>
      <Wrapper/>
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'flex-start',
    backgroundColor: Colors.light.hereworksBackground,
  },
  title: {
    fontSize: 20,
    margin: 40,
    fontWeight: 'bold',
    color: 'black',
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: '80%',
  },
  zoomButton: {
    margin: 10,
    backgroundColor: Colors.light.hereworksDarkBlue,
    width: 40,
    height: 40,
    borderRadius: 5,
    justifyContent: 'center',
    alignItems: 'center',
  },
  floorButton: {
    margin: 10,
    padding: 10,
    backgroundColor: Colors.light.hereworksDarkBlue,
    flex: 1,
    borderRadius: 5,
    justifyContent: 'center',
    alignItems: 'center',
  },
  desktopModalContainer:
    {
      position: 'absolute',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#E5E5E550',
      height: '100%',
      width: '100%',
      overflow: 'hidden',
      zIndex: 3,
      borderRadius: 24,
      flex: 1,
    },
  desktopModal:
    {
      backgroundColor: Colors.light.background,
      overflow: 'hidden',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 4,
      borderRadius: 24,
      width: '80%',
      padding: 20,
    },
  buttonText: {
    fontFamily: 'Poppins_400Regular',
    fontSize: 16,
    color: 'white',
    paddingHorizontal: 10,
  },
});
