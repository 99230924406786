import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const CustomIcon = (props: SvgProps) => (
  <Svg
    width={40}
    height={40}
    fill="none"
    {...props}
  >
    <Path
      d="M22 2a2 2 0 1 0-4 0h4Zm-4 36a2 2 0 1 0 4 0h-4Zm0-36v36h4V2h-4Z"
      fill="#003A70"
    />
    <Path
      d="M2 18a2 2 0 1 0 0 4v-4Zm36 4a2 2 0 1 0 0-4v4ZM2 22h36v-4H2v4Z"
      fill="#003A70"
    />
  </Svg>
);

export default CustomIcon;
