import React from 'react';
import { StyleSheet } from 'react-native';
import ScoreCard from '../components/ScoreCard';

import { View } from '../components/Themed';
import Colors from '../constants/Colors';
import { RootTabScreenProps } from '../types';
import SingleLineGraph from '../components/SingleLineGraph';

// eslint-disable-next-line no-unused-vars
export default function ITScreen({ navigation }: RootTabScreenProps<'IT'>) {
  return (
    <View style={styles.container}>
      <ScoreCard category={'it'} />
      <View style={styles.graphCard}>
        {' '}
        <SingleLineGraph
          metricName="happy_group_amenities_local_happyscore_percent"
          title="IT"
          interval="24h"
        />
      </View>
    </View>
    // <HappyGroupsGraph/>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 3,
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    backgroundColor: Colors.light.hereworksBackground,
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
    color: 'black',
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: '80%',
  },
  graphCard: {
    marginVertical: 40,
    marginRight: 40,
    borderRadius: 24,
    backgroundColor: 'white',
    flex: 2,
    height: 408,
  },
});
