/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';

// import { Card } from 'react-native-elements';
// eslint-disable-next-line object-curly-newline
import { View, TouchableOpacity, Text, StyleSheet } from 'react-native';
import BlueLineIcon from './BlueLineIcon';
import YellowLineIcon from './YellowLineIcon';
import PinkLineIcon from './PinkLineIcon';
import PurpleLineIcon from './PurpleLineIcon';
import LightBlueLineIcon from './LightBlueLineIcon';
import GreenLineIcon from './GreenLineIcon';
import SunIcon from './SunIcon';
import BarChartIcon from './BarChartIcon';
import CoffeeIcon from './CoffeeIcon';
import MonitorIcon from './MonitorIcon';
import SmileIcon from './SmileIcon';
import BoltIcon from './BoltIcon';
import TickedBoxIcon from './TickedBoxIcon';
import EmptyBoxIcon from './EmptyBoxIcon';

export default function DropdownMetricSelect({ selected, onSelect }) {
  // console.log(selected);
  const [externalToggle, setExternalToggle] = useState(
    selected.includes('external'),
  );
  const [internalToggle, setInternalToggle] = useState(
    selected.includes('internal'),
  );
  const [energyToggle, setEnergyToggle] = useState(selected.includes('energy'));
  const [peopleToggle, setPeopleToggle] = useState(selected.includes('people'));
  const [ITToggle, setITToggle] = useState(selected.includes('IT'));
  const [occupancyToggle, setOccupancyToggle] = useState(
    selected.includes('occupancy'),
  );

  let checkedStrings = [];

  useEffect(() => {
    const listChecked = {
      internal: internalToggle,
      external: externalToggle,
      occupancy: occupancyToggle,
      energy: energyToggle,
      people: peopleToggle,
      IT: ITToggle,
    };
    // console.log('checked effect: ' + JSON.stringify(listChecked));
    checkedStrings = Object.keys(listChecked).filter((k) => listChecked[k]);
    // console.log('strings: ' + checkedStrings);
    onSelect(checkedStrings);
  }, [
    internalToggle,
    externalToggle,
    occupancyToggle,
    energyToggle,
    peopleToggle,
    ITToggle,
  ]);

  return (
    <View style={styles.dropDown}>
      <TouchableOpacity
        style={styles.buttonStyle}
        onPress={() => {
          setInternalToggle(!internalToggle);
        }}
      >
        {internalToggle ? <TickedBoxIcon /> : <EmptyBoxIcon />}
        <CoffeeIcon style={styles.IconStyle} color="#003A70" />
        <Text style={styles.itemText}>Int. Environment</Text>
        <LightBlueLineIcon />
      </TouchableOpacity>
      <View style={styles.line}></View>
      <TouchableOpacity
        style={styles.buttonStyle}
        onPress={() => {
          setExternalToggle(!externalToggle);
        }}
      >
        {externalToggle ? <TickedBoxIcon /> : <EmptyBoxIcon />}
        <SunIcon style={styles.IconStyle} color="#003A70" />
        <Text style={styles.itemText}>Ext. Environment</Text>
        <PurpleLineIcon />
      </TouchableOpacity>

      <View style={styles.line}></View>
      <TouchableOpacity
        style={styles.buttonStyle}
        onPress={() => {
          setOccupancyToggle(!occupancyToggle);
        }}
      >
        {occupancyToggle ? <TickedBoxIcon /> : <EmptyBoxIcon />}
        <BarChartIcon style={styles.IconStyle} color="#003A70" />
        <Text style={styles.itemText}>Occupancy</Text>
        <BlueLineIcon style={{ flex: 1 }} />
      </TouchableOpacity>
      <View style={styles.line}></View>
      <TouchableOpacity
        style={styles.buttonStyle}
        onPress={() => {
          setEnergyToggle(!energyToggle);
        }}
      >
        {energyToggle ? <TickedBoxIcon /> : <EmptyBoxIcon />}
        <BoltIcon
          style={styles.IconStyle}
          color="#003A70"
          width={24}
          height={24}
        />
        <Text style={styles.itemText}>Energy</Text>
        <PinkLineIcon />
      </TouchableOpacity>
      <View style={styles.line}></View>
      <TouchableOpacity
        style={styles.buttonStyle}
        onPress={() => {
          setPeopleToggle(!peopleToggle);
        }}
      >
        {peopleToggle ? <TickedBoxIcon /> : <EmptyBoxIcon />}
        <SmileIcon style={styles.IconStyle} color="#003A70" />
        <Text style={styles.itemText}>People</Text>
        <GreenLineIcon />
      </TouchableOpacity>
      <View style={styles.line}></View>
      <TouchableOpacity
        style={styles.buttonStyle}
        onPress={() => {
          setITToggle(!ITToggle);
        }}
      >
        {ITToggle ? <TickedBoxIcon /> : <EmptyBoxIcon />}
        <MonitorIcon style={styles.IconStyle} color="#003A70" />
        <Text style={styles.itemText}>IT</Text>
        <YellowLineIcon />
      </TouchableOpacity>
    </View>
  );
}

const styles = StyleSheet.create({
  buttonStyle: {
    flexDirection: 'row',
    height: 40,
    alignItems: 'center',
    padding: 5,
  },
  line: {
    height: 2,
    width: '90%',
    backgroundColor: '#EAECEE',
    alignSelf: 'center',
  },
  dropDown: {
    flexDirection: 'column',
    borderRadius: 15,
    backgroundColor: '#fff',
    width: 250,
  },
  itemText: {
    flex: 1,
    marginLeft: 10,
  },
  IconStyle: {
    marginLeft: 8,
  },
});
