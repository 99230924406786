import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const YellowLineIcon = (props: SvgProps) => (
  <Svg width={50} height={12} fill="none" {...props}>
    <Path
      d="M1 6h48"
      stroke="#F5B335"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path d="m25 12-5.196-9h10.392L25 12Z" fill="#F5B335" />
  </Svg>
);

export default YellowLineIcon;
