const tintColorLight = '#2f95dc';
const tintColorDark = '#fff';

const Colors = {
  light: {
    text: '#000',
    background: '#E5E5E5',
    tint: tintColorLight,
    tabIconDefault: '#ccc',
    tabIconSelected: tintColorLight,
    hereworksPrimaryGrey: '#323E48',
    hereworksPrimaryGrey200: '#F5F5F6',
    hereworksPrimaryGrey300: '#EEEDE850',
    hereworksPrimaryGrey600: '#D9D8D6',
    hereworksPrimaryGrey700: '#919193',
    hereworksPrimaryGrey800: '#696D70',
    hereworksPrimaryGrey900: '#4A4F53',
    hereworksDarkBlue: '#003A70',
    hereworksDarkBlue700: '#8E8EA4',
    hereworksDarkBlue800: '#696B88',
    hereworksLightBlue: '#2173C4',
    hereworksLightBlue800: '#89A7CB',
    hereworksLightBlue900: '#6892C0',
    hereworksPink: '#D6006D',
    hereworksBackground: '#F8FAFB',
    hereworksOrange: '#C07E00',
    hereworksExtraDarkTeal: '#025157',
    hereworksExtraDarkTeal700: '#8C9A9B',
    hereworksPaleBlue: '#E5ECF0',
    hereworksPaleBlue800: '#D7E3EA',
    hereworksPaleBlue900: '#C9DBE3',
    hereworksCheckGreen: '#A1D683',
    hereworksDarkGreen: '#4D7F71',
    hereworksUrgentRed: '#E23554',
    hereworksMediumYellow: '#FFA800',
    hereworksYellow700: '#F7DBA5',
    hereworksHover: '#F3F9FC',
    hereworksSelected: '#E0EEF1',
    hereworksLoading: '#323E48',
  },
  dark: {
    text: '#fff',
    background: '#000',
    tint: tintColorDark,
    tabIconDefault: '#ccc',
    tabIconSelected: tintColorDark,
  },
};

export default Colors;
