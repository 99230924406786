import * as React from 'react';
import Svg, { SvgProps, Circle, Path } from 'react-native-svg';

interface Props extends SvgProps {
  checkcolor: string;
}

const CheckmarkIcon = (props: Props) => (
  <Svg
    width={40}
    height={40}
    fill={props.fill ?? '#fff'}
    {...props}
  >
    <Circle
      cx={20}
      cy={20}
      r={19}
      fill={props.fill ?? '#fff'}
      stroke={props.stroke ?? '#BED6DB'}
      strokeWidth={2}
    />
    <Path d="m13.335 20.713 4.5 4.5 9.5-9.5" stroke={props.checkcolor ?? '#BED6DB'} fill={props.fill ?? '#fff'} strokeWidth={2} />
  </Svg>
);

export default CheckmarkIcon;
