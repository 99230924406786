import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const DeskIcon = (props: SvgProps) => (
  <Svg
    width={60}
    height={60}
    fill="none"
    {...props}
  >
    <Path
      d="M5 15v30h5V20h25v25h5v-5h10v5h5V15H5Zm45 5v5H40v-5h10ZM40 35v-5h10v5H40Z"
      fill="#003A70"
    />
  </Svg>
);

export default DeskIcon;
