import * as React from 'react';
import Svg, { SvgProps, Circle } from 'react-native-svg';

const EllipsisIconVertical = (props: SvgProps) => (
  <Svg width={5} height={23} fill="none" {...props}>
    <Circle cx={2.5} cy={2.5} r={2.5} fill="#919193" />
    <Circle cx={2.5} cy={11.5} r={2.5} fill="#919193" />
    <Circle cx={2.5} cy={20.5} r={2.5} fill="#919193" />
  </Svg>
);

export default EllipsisIconVertical;
