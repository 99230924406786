import AsyncStorage from '@react-native-async-storage/async-storage';

const updateIssue = async (
  issueid,
  assignee,
  completed = false,
  paused = false,
  unpause = false,
) => {
  try {
    const clientName = await AsyncStorage.getItem('clientName');

    let newStatus = completed ? 'completed' : null;

    if (paused) {
      newStatus = 'paused';
    }

    if (unpause) {
      newStatus = 'inprogress';
    }

    // eslint-disable-next-line no-undef
    const requestHeaders: HeadersInit = new Headers();
    requestHeaders.set('clientid', clientName ?? 'hereworks');
    requestHeaders.set('accesskey', 'b7d3137e8e7cfb37e4607962fb7b153a' ?? '');
    requestHeaders.set('Content-Type', 'application/json');

    const response = await fetch(
      `https://api.dev.hereworks.tech/button/issue/${issueid}`,
      {
        method: 'POST',
        headers: requestHeaders,
        body: JSON.stringify({
          assignee: assignee ?? 'No name',
          status: newStatus,
        }),
      },
    );
    const json = await response.json();
    return json;
  } catch (error) {
    return null;
  }
};

export default updateIssue;
