import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';
/* SVGR has dropped some elements not supported by react-native-svg: title */

const BackArrow = (props: SvgProps) => (
  <Svg width={13} height={22} fill="none" {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.184 11 13 2.11 10.908 0 0 11l10.908 11L13 19.89 4.184 11Z"
      fill="#2173C4"
    />
  </Svg>
);

export default BackArrow;
