import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const InternetIcon = (props: SvgProps) => (
  <Svg
    width={60}
    height={60}
    fill="none"
    {...props}
  >
    <Path
      d="M55 30C55 16.192 43.807 5 30 5 16.192 5 5 16.192 5 30c0 13.807 11.192 25 25 25"
      stroke="#003A70"
      strokeWidth={3.75}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M6.575 21.25h46.85M32.5 5.125S40 15 40 30L32.5 5.125Zm-5 49.75S20 45 20 30s7.5-24.875 7.5-24.875v49.75ZM6.575 38.75H30 6.575Z"
      stroke="#003A70"
      strokeWidth={3.75}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M54.697 44.792c1.235.76 1.158 2.608-.112 2.753l-6.418.727-2.877 5.78c-.57 1.148-2.333.586-2.625-.834l-3.138-15.29c-.247-1.2.833-1.956 1.875-1.313l13.295 8.177Z"
      stroke="#003A70"
      strokeWidth={3.75}
    />
  </Svg>
);

export default InternetIcon;
