import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const LeftArrowSmall = (props: SvgProps) => (
    <Svg
        width={8}
        height={14}
        fill="none"
        {...props}
    >
        <Path
            d="M7.707 1.707A1 1 0 0 0 6.293.293l1.414 1.414ZM1 7l-.707-.707a1 1 0 0 0 0 1.414L1 7Zm5.293 6.707a1 1 0 0 0 1.414-1.414l-1.414 1.414Zm0-13.414-6 6 1.414 1.414 6-6L6.293.293Zm-6 7.414 6 6 1.414-1.414-6-6L.293 7.707Z"
            fill="#696D70"
        />
    </Svg>
);

export default LeftArrowSmall;
