import { Auth } from 'aws-amplify';
import React from 'react';
import { Button, StyleSheet } from 'react-native';
import ScoreCard from '../components/ScoreCard';

import { Text, View } from '../components/Themed';
import { RootTabScreenProps } from '../types';

// eslint-disable-next-line no-unused-vars
export default function AnalyticsScreen({ navigation }: RootTabScreenProps<'Analytics'>) {
  return (
    <View style={styles.container}>
      <ScoreCard category={'building-occupancy'} />
      <Button onPress={() => Auth.signOut()} title="Logout" />
      <Text>{
        // eslint-disable-next-line no-undef
        window?.location?.toString()
      }</Text>
    </View >
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'flex-start',
    backgroundColor: 'white',
    paddingTop: 50,
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
    color: 'black',
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: '80%',
  },
});
