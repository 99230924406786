import AsyncStorage from '@react-native-async-storage/async-storage';

const getButtonInstances = async (prototypeId) => {
  try {
    // const jwtToken = await AsyncStorage.getItem('token');
    const clientName = await AsyncStorage.getItem('clientName');

    // eslint-disable-next-line no-undef
    const requestHeaders: HeadersInit = new Headers();
    requestHeaders.set('clientid', clientName ?? 'hereworks');
    requestHeaders.set('accesskey', 'b7d3137e8e7cfb37e4607962fb7b153a' ?? '');
    requestHeaders.set('Content-Type', 'application/json');

    const response = await fetch(
      `https://api.dev.hereworks.tech/button/buttoninstances?buttonPrototypeId=${prototypeId}`,
      {
        method: 'GET',
        headers: requestHeaders,
      },
    );
    const json = await response.json();
    return json;
  } catch (error) {
    return null;
  }
};

export default getButtonInstances;
